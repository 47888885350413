import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Icon from '../../Icon/Icon';

const getIcon = (ascending) => {
  if (ascending) {
    return 'fas fa-sort-up';
  }
  if (null === ascending) {
    return 'fas fa-sort';
  }
  return 'fas fa-sort-down';
};

const changeSort = (ascending) => {
  if (ascending) {
    return false;
  }
  if (null === ascending) {
    return true;
  }
  return null;
};

const TableHeader = ({
  activeColor,
  currentSort,
  filter,
  headerClassName,
  onChangeFilter,
  onChangeSort,
  sort,
  text,
  width,
}) => {
  const [ascending, setAscending] = useState(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted && ascending !== currentSort) {
      onChangeSort(ascending);
    } else if (!mounted) {
      setMounted(true);
    }
  }, [ascending]);

  useEffect(() => {
    setAscending(currentSort);
  }, [currentSort]);

  return (
    <th style={width && 0 < width ? { width: `${width}%` } : null} className={headerClassName}>
      <div className={sort && (ascending || false === ascending) ? `${activeColor}-bg` : ''}>
        <button type="button" disabled={!sort} onClick={() => setAscending(changeSort(ascending))}>
          <p className={`white-color${sort ? ' with-sort' : ''}`}>{text}</p>
          {!!sort && <Icon icon={getIcon(ascending)} className="white-color" />}
        </button>
        {!!filter && <input placeholder={text} onChange={({ target: { value } }) => onChangeFilter(value)} />}
      </div>
    </th>
  );
};

TableHeader.defaultProps = {
  activeColor: null,
  currentSort: null,
  filter: false,
  headerClassName: null,
  sort: false,
  text: null,
  width: null,
};

TableHeader.propTypes = {
  activeColor: PropTypes.string,
  currentSort: PropTypes.bool,
  filter: PropTypes.bool,
  headerClassName: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  sort: PropTypes.bool,
  text: PropTypes.string,
  width: PropTypes.number,
};

export default TableHeader;
