import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';

import {
  getDefaultCategoryById, createDefaultCategory, updateDefaultCategory,
} from '../../../actions/defaultCategory';

import Page from '../../../components/base/Page';
import Loading from '../../../components/base/Loading';
import OwnCropper from '../../../components/ownCropper';
import {
  Button, BUTTON_TYPES, Icon, Input,
} from '../../../components/base-comps';

import { translate } from '../../../utils/translate/translator';
import { NEW } from '../../../utils/constants';
import { DEFAULT_CATEGORY } from '../../../utils/dispatchTypes';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../utils/toasts';

import './index.scss';

const initialState = {
  name: '',
  image: '',
  translation: {
    en: '',
    es: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case DEFAULT_CATEGORY.SET_NAME:
      return { ...state, name: action.value };
      case DEFAULT_CATEGORY.SET_IMAGE:
        return { ...state, image: action.value };
    case DEFAULT_CATEGORY.SET_TRANSLATION_ES:
      return { ...state, translation: { ...state.translation, es: action.value } };
    case DEFAULT_CATEGORY.SET_TRANSLATION_EN:
      return { ...state, translation: { ...state.translation, en: action.value } };
    case DEFAULT_CATEGORY.SET_DEFAULT_CATEGORY:
      return action.defaultCategory;
    default:
      return initialState;
  }
};

const DefaultCategory = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageToCrop, setimageToCrop] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id && id !== NEW) {
      getDefaultCategoryById(id)
        .then((defaultCategory) => {
          dispatch({ type: DEFAULT_CATEGORY.SET_DEFAULT_CATEGORY, defaultCategory });
        })
        .catch(() => {
          showErrorToast('common.serverError');
        });
    }
  }, [id]);

  const onChange = (type, value) => {
    dispatch({ type, value });
  };

  const onDrop = useCallback((files) => {
    if (files && files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setimageToCrop({
          ...files[0],
          previewUrl: reader.result,
        });
      };

      reader.readAsDataURL(files[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const deleteLogo = () => {
    onChange(DEFAULT_CATEGORY.SET_IMAGE, null);
    setimageToCrop(null);
  };

  const onCrop = (img) => onChange(DEFAULT_CATEGORY.SET_IMAGE, img);

  const checkForm = () => {
    const newErrors = {};

    if (!state.name) {
      newErrors.name = true;
    }

    if (!state.translation.en) {
      newErrors.trastationEN = true;
    }

    if (!state.translation.es) {
      newErrors.trastationES = true;
    }

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
    } else if (id && id !== NEW) {
      setLoading(true);
      updateDefaultCategory(state)
        .then(() => {
          setLoading(false);
          showSuccessToast(translate('defaultCategory.saved'));
          history.goBack();
        })
        .catch(() => {
          showErrorToast(translate('common.serverError'));
          setLoading(false);
        });
    } else {
      setLoading(true);
      createDefaultCategory(state)
        .then(() => {
          setLoading(false);
          showSuccessToast(translate('defaultCategory.saved'));
          history.goBack();
        })
        .catch(() => {
          showErrorToast(translate('common.serverError'));
          setLoading(false);
        });
    }
  };

  return (
    <Page id="backoffice-defaultCategory" grayBackground>
      <div className="defaultCategory-card">
        {
          state.image ? (
            <div className="image-preview">
              <Button iconLeft="fas fa-trash" disabled={loading} onClick={deleteLogo} type={BUTTON_TYPES.transparent} />
              <img src={state.image.url || state.image} alt={state.name} />
            </div>
          ) : (
            <div {...getRootProps()} className="image-dropzone">
              <input {...getInputProps()} disabled={loading} />
              <Icon icon="fas fa-camera" />
            </div>
          )
        }
        <Input
          value={state.name}
          placeholder={translate('defaultCategory.name')}
          onChange={(value) => onChange(DEFAULT_CATEGORY.SET_NAME, value)}
          error={errors?.name}
          className="defaultCategory-name"
          disabled={loading}
        />
        <Input
          value={state.translation.es}
          placeholder={translate('defaultCategory.translationES')}
          onChange={(value) => onChange(DEFAULT_CATEGORY.SET_TRANSLATION_ES, value)}
          error={errors?.trastationES}
          className="defaultCategory-name"
          disabled={loading}
        />
        <Input
          value={state.translation.en}
          placeholder={translate('defaultCategory.translationEN')}
          onChange={(value) => onChange(DEFAULT_CATEGORY.SET_TRANSLATION_EN, value)}
          error={errors?.trastationEN}
          className="defaultCategory-name"
          disabled={loading}
        />
        {
          loading ? (
            <Loading />
          ) : (
            <Button
              text={translate('common.save')}
              onClick={checkForm}
              className="save-defaultCategory"
              disabled={loading}
            />
          )
        }
      </div>
      <OwnCropper
        showModal={!!imageToCrop && !state.image}
        image={imageToCrop}
        onCrop={onCrop}
        width={720}
        height={1280}
        aspectRatio={9 / 16}
      />
    </Page>
  );
};

export default DefaultCategory;
