/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

import './index.scss';

export const TOOLTIP_POSITION = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
};

const getStyle = (position, triggerRef, messageRef) => {
  const styles = {};
  const triggerRects = triggerRef && triggerRef.current && triggerRef.current.getClientRects();
  const messageRects = messageRef && messageRef.current && messageRef.current.getClientRects();

  if (triggerRects && triggerRects.length && messageRects && messageRects.length) {
    const {
      left, right, top, bottom,
    } = triggerRects[0];
    const { width: messageWidth, height: messageHeight } = messageRects[0];

    if (TOOLTIP_POSITION.right === position) {
      styles.left = right + 8;
    } else if (TOOLTIP_POSITION.left === position) {
      styles.left = left - messageWidth - 8;
    } else if (TOOLTIP_POSITION.top === position) {
      styles.top = top - messageHeight - 8;
    } else if (TOOLTIP_POSITION.bottom === position) {
      styles.top = bottom + 8;
    }
  }
  return styles;
};

const Tooltip = ({
  children, className, content, icon, message, position, visible,
}) => {
  const [fixed, setFixed] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [styles, setStyles] = useState({});
  const [messageRef] = useState(createRef());
  const [triggerRef] = useState(createRef());

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    } else if (mounted) {
      setStyles(getStyle(position));
    }
  }, [mounted]);

  useEffect(() => {
    if (fixed) {
      setStyles(getStyle(position, triggerRef, messageRef));
    }
  }, [fixed]);

  return (
    <div className="tooltip-container" onMouseOver={() => setFixed(true)} onMouseLeave={() => setFixed(false)}>
      {visible && (content || message || icon) && (
        <div className={`tooltip fadein tooltip-${position} ${className || ''}`} style={{ ...styles, fixed }}>
          <div className="tooltip-message" ref={messageRef}>
            {icon ? (
              <div className="tooltip-icon">
                <Icon icon={icon} />
              </div>
            ) : null}
            <span>{message}</span>
            {content}
          </div>
        </div>
      )}
      {visible ? (
        <div className="tooltip-trigger" ref={triggerRef}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  className: null,
  content: null,
  icon: null,
  message: null,
  position: TOOLTIP_POSITION.top,
  visible: true,
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  message: PropTypes.string,
  position: PropTypes.oneOf(Object.values(TOOLTIP_POSITION).map((type) => type)),
  visible: PropTypes.bool,
};

export default Tooltip;
