import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import ToastContext from './context';
import Toast from './Toast';

// Create a random ID
const generateUEID = () => {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = `000${first.toString(36)}`.slice(-3);
  second = `000${second.toString(36)}`.slice(-3);

  return first + second;
};

export const TOAST_POSITION = {
  bottomCenter: 'bottom-center',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
  centerLeft: 'center-left',
  centerRight: 'center-right',
  topCenter: 'top-center',
  topLeft: 'top-left',
  topRight: 'top-right',
};

const ToastProvider = ({ children, clearOnClick, position }) => {
  const [toasts, setToasts] = useState([]);

  const add = (content, type, duration) => {
    const id = generateUEID();

    if ('function' === typeof content) {
      setToasts([...toasts, {
        id, formatter: content, duration, type,
      }]);
    } else if ('string' === typeof content) {
      setToasts([...toasts, {
        id, text: content, duration, type,
      }]);
    }
  };
  const remove = (id) => {
    setToasts(toasts.filter((t) => t.id !== id));
  };

  const providerValue = useMemo(() => ({ add, remove }), [toasts]);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      {createPortal(
        <div className={`wiset-toasts-wrapper ${position}`}>
          {toasts.map(({
            id, duration, formatter, text, type,
          }) => (
            <Toast
              key={id}
              clearOnClick={clearOnClick}
              duration={duration}
              text={text}
              type={type}
              remove={() => remove(id)}
            >
              {formatter && formatter()}
            </Toast>
          ))}
        </div>,
        document.body,
      )}
    </ToastContext.Provider>
  );
};

ToastProvider.defaultProps = {
  children: null,
  clearOnClick: true,
  position: TOAST_POSITION.topRight,
};

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  clearOnClick: PropTypes.bool,
  position: PropTypes.string,
};

export default ToastProvider;
