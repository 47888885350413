import React from 'react';
import PropTypes from 'prop-types';

const Page = ({
  children, className, grayBackground, id, innerPadding, withLargeSidebar, withNavbar, withSidebar,
}) => (
  <div
    id={id}
    className={`page fadein${className ? ` ${className}` : ''}${
      !withLargeSidebar && withSidebar ? ' page-sidebar' : ''
    }${withLargeSidebar && !withSidebar ? ' page-large-sidebar' : ''}${withNavbar ? ' page-navbar' : ''}`}
  >
    {innerPadding ? <div className={grayBackground ? 'page-background' : ''}>{children}</div> : children}
  </div>
);

Page.defaultProps = {
  children: null,
  className: null,
  id: null,
  grayBackground: false,
  innerPadding: true,
  withLargeSidebar: false,
  withNavbar: false,
  withSidebar: false,
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  id: PropTypes.string,
  grayBackground: PropTypes.bool,
  innerPadding: PropTypes.bool,
  withLargeSidebar: PropTypes.bool,
  withNavbar: PropTypes.bool,
  withSidebar: PropTypes.bool,
};

export default Page;
