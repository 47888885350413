import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './index.scss';
import Button from '../Button/Button';

export const INPUT_TYPES = {
  email: 'email',
  number: 'number',
  password: 'password',
  text: 'text',
};

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      prevCalc: false,
      rows: 1,
      value: null,
      visiblePassword: false,
    };
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    const {
      isFocused, maxRows, minRows, textarea, value,
    } = this.props;

    // If is textarea and has a default value we calculate the height
    if (textarea) {
      // We need to reset to minRows before calculating height
      this.textareaRef.current.rows = minRows;

      const res = this.calculateRows();

      this.textareaRef.current.rows = res.rows;
      if (res.scrollTop) {
        this.textareaRef.current.scrollTop = res.scrollTop;
      }

      this.setState({ focused: isFocused, value, rows: res.rows < maxRows ? res.rows : maxRows });
    } else {
      this.setState({ focused: isFocused, value, rows: minRows });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFocused, maxRows, minRows, textarea, value,
    } = this.props;
    const { prevCalc, value: stVal } = this.state;

    // If is textarea and has a default value we calculate the height
    if (textarea && value && !prevCalc && !prevState.value && stVal) {
      // We need to reset to minRows before calculating height
      this.textareaRef.current.rows = minRows;

      const res = this.calculateRows();

      this.textareaRef.current.rows = res.rows;
      if (res.scrollTop) {
        this.textareaRef.current.scrollTop = res.scrollTop;
      }

      this.setState({ value, rows: res.rows < maxRows ? res.rows : maxRows });
    } else if (prevProps.value !== value || (value && prevState.value !== value)) {
      this.setState({ value });
    }
    if (isFocused !== prevProps.isFocused) {
      this.setState({ focused: isFocused });
    }
  }

  calculateRows = () => {
    const { textareaLineHeight, maxRows } = this.props;
    const { scrollHeight } = this.textareaRef.current;

    const result = {
      rows: null,
      scrollTop: null,
    };

    result.rows = ~~((scrollHeight - textareaLineHeight) / textareaLineHeight);

    if (result.rows >= maxRows) {
      result.rows = maxRows;
      result.scrollTop = scrollHeight;
    }

    return result;
  };

  onChangeTextarea = (event) => {
    const {
      maxLength, maxRows, minRows, onChange,
    } = this.props;
    // We need to reset to minRows before calculating height
    event.target.rows = minRows;

    const { value: val } = event.target;
    const res = this.calculateRows();

    event.target.rows = res.rows;
    if (res.scrollTop) {
      event.target.scrollTop = res.scrollTop;
    }
    let value = val;

    if (maxLength && value > maxLength) {
      value = value.substring(0, maxLength);
    }

    this.setState({ value, rows: res.rows < maxRows ? res.rows : maxRows }, () => (onChange ? onChange(value) : null));
  };

  onChange = (val) => {
    const { maxLength, onChange } = this.props;
    let value = val;

    if (maxLength && value > maxLength) {
      value = value.substring(0, maxLength);
    }

    this.setState({ value }, () => (onChange ? onChange(value) : null));
  };

  keyPress = (event) => {
    const { clearOnEnter, onEnter, withOnEnterAndSpace } = this.props;
    const { value } = this.state;
    // charCode 32 is spacebar
    if ('Enter' === event.key || (withOnEnterAndSpace && 32 === event.charCode)) {
      onEnter(value);
      if (clearOnEnter) {
        this.setState({ value: null });
      }
    }
  };

  getValue = () => {
    const { value } = this.state;

    if (0 === value) {
      return 0;
    }
    if (value) {
      return value;
    }
    return '';
  };

  render() {
    const { rows, focused, visiblePassword } = this.state;
    const {
      autocomplete,
      className,
      clearable,
      disabled,
      error,
      errorText,
      icon,
      iconRight,
      id,
      infoText,
      max,
      maxLength,
      min,
      minLength,
      onFocus,
      placeholder,
      readOnly,
      showCounter,
      showPassword,
      textarea,
      text,
      textRight,
      type,
    } = this.props;

    const value = this.getValue();

    return (
      <div
        className={`wiset-input${(icon && !iconRight) || (text && !textRight) ? ' with-icon' : ''}${
          error ? ' error' : ''
        }${disabled ? ' disabled' : ''}${className ? ` ${className}` : ''}${textarea ? ' wiset-textarea' : ''}`}
      >
        <div className={`${focused ? ' primary-border' : ''}`}>
          {placeholder ? (
            <p
              className={`placeholder${focused ? ' primary-color' : ''}${
                '' !== value || focused ? ' with-content' : ''
              }`}
            >
              {placeholder}
            </p>
          ) : null}
          {icon && !iconRight && <Icon className={`${focused ? ' primary-color' : ''}`} icon={icon} />}
          {text && !textRight && <span className={`${focused ? ' primary-color' : ''}`}>{text}</span>}
          {textarea ? (
            <textarea
              id={id}
              disabled={disabled}
              maxLength={maxLength}
              minLength={minLength}
              onBlur={() => this.setState({ focused: false })}
              onChange={(event) => this.onChangeTextarea(event)}
              onKeyPress={this.keyPress}
              onFocus={() => this.setState({ focused: true }, onFocus)}
              placeholder=""
              ref={this.textareaRef}
              rows={rows}
              value={value}
            />
          ) : (
            <input
              autoComplete={autocomplete ? null : 'new-password'}
              id={id}
              disabled={disabled}
              max={max}
              maxLength={maxLength}
              min={min}
              minLength={minLength}
              onBlur={() => this.setState({ focused: false })}
              onChange={(event) => this.onChange(event.target.value)}
              onKeyPress={this.keyPress}
              onFocus={() => this.setState({ focused: true }, onFocus)}
              placeholder=""
              readOnly={readOnly}
              type={visiblePassword ? INPUT_TYPES.text : type}
              value={value}
            />
          )}
          {INPUT_TYPES.password === type && showPassword && (
            <Button
              className="right-elem input-show-pass-btn"
              iconLeft={visiblePassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
              onClick={() => this.setState({ visiblePassword: !visiblePassword })}
            />
          )}
          {clearable && value && (
            <Button className="right-elem input-clear-btn" iconLeft="fas fa-times" onClick={() => this.onChange()} />
          )}
          {icon && iconRight && <Icon className={`right-elem${focused ? ' primary-color' : ''}`} icon={icon} />}
          {text && textRight && <span className={`right-elem${focused ? ' primary-color' : ''}`}>{text}</span>}
        </div>
        {infoText && <p className="wiset-input-info">{infoText}</p>}
        {error && errorText && <p className="wiset-input-error">{errorText}</p>}
        {showCounter && maxLength && (
          <p className="wiset-input-counter">
            {(value && value.length) || 0}
            /
            {maxLength}
          </p>
        )}
      </div>
    );
  }
}

Input.defaultProps = {
  autocomplete: true,
  className: null,
  clearable: false,
  clearOnEnter: false,
  disabled: false,
  error: false,
  errorText: null,
  icon: null,
  iconRight: false,
  id: null,
  infoText: null,
  isFocused: false,
  max: null,
  maxLength: null,
  maxRows: 5,
  min: null,
  minLength: null,
  minRows: 1,
  onChange: () => true,
  onEnter: () => true,
  onFocus: null,
  placeholder: null,
  readOnly: false,
  showCounter: false,
  showPassword: false,
  text: null,
  textarea: false,
  textareaLineHeight: 20,
  textRight: false,
  type: INPUT_TYPES.text,
  value: null,
  withOnEnterAndSpace: false,
};

Input.propTypes = {
  autocomplete: PropTypes.bool,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  clearOnEnter: PropTypes.bool,
  disabled: PropTypes.any,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  id: PropTypes.string,
  infoText: PropTypes.string,
  isFocused: PropTypes.bool,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  maxRows: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  minRows: PropTypes.number,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  showCounter: PropTypes.bool,
  showPassword: PropTypes.bool,
  text: PropTypes.string,
  textarea: PropTypes.bool,
  textareaLineHeight: PropTypes.number,
  textRight: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES).map((type) => type)),
  value: PropTypes.any,
  withOnEnterAndSpace: PropTypes.bool,
};
