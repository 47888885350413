import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Icon from '../Icon/Icon';
import './index.scss';

export const BUTTON_TYPES = {
  primary: 'primary',
  primaryDark: 'primary-dark',
  primaryDarker: 'primary-darker',
  primaryLight: 'primary-light',
  primaryLighter: 'primary-lighter',
  secondary: 'secondary',
  secondaryDark: 'secondary-dark',
  secondaryDarker: 'secondary-darker',
  secondaryLight: 'secondary-light',
  secondaryLighter: 'secondary-lighter',
  accent: 'accent',
  gray: 'gray',
  black: 'black',
  white: 'white',
  transparent: 'transparent',
};

export const getTypeClassName = (type, inverted) => {
  if (BUTTON_TYPES.white === type && inverted) {
    return ' primary-bg white-color white-border';
  }
  if (BUTTON_TYPES.white === type && !inverted) {
    return ' white-bg primary-color primary-border';
  }
  if (BUTTON_TYPES.transparent === type && inverted) {
    return ' primary-bg transparent-color transparent-border';
  }
  if (!type) {
    return '';
  }
  if (!inverted) {
    return ` ${type}-bg ${type}-border white-color`;
  }
  return ` white-bg ${type}-border ${type}-color`;
};

const Button = ({
  children,
  className,
  disableType,
  iconLeft,
  iconRight,
  inverted,
  text,
  type,
  disabled,
  external,
  onClick,
  to,
  loading,
  ...rest
}) => {
  if (loading) {
    return (
      <button
        type="button"
        className={`btn${disableType || !type ? '' : getTypeClassName(type, inverted)}${
          className ? ` ${className}` : ''
        }${disabled ? ' disabled' : ''}`}
        disabled
        onClick={onClick}
        {...rest}
      >
        <Icon icon="fas fa-circle-notch" className="fa-spin" />
      </button>
    );
  }

  if (to) {
    return external ? (
      <a
        {...rest}
        className={`btn btn-link${type && getTypeClassName(type, inverted)}${className ? ` ${className}` : ''}${
          disabled ? ' disabled' : ''
        }`}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled && 'function' === typeof onClick) {
            onClick(e);
          } else if (disabled) {
            e.preventDefault();
          }
        }}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {iconLeft && <Icon icon={`${iconLeft} left`} className={text ? 'with-margin' : ''} />}
        {text && <span>{text}</span>}
        {iconRight && <Icon icon={`${iconRight} right`} className={text ? 'with-margin' : ''} />}
        {children}
      </a>
    ) : (
      <NavLink
        {...rest}
        className={`btn btn-link${type && getTypeClassName(type, inverted)}${className ? ` ${className}` : ''}${
          disabled ? ' disabled' : ''
        }`}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled && 'function' === typeof onClick) {
            onClick(e);
          } else if (disabled) {
            e.preventDefault();
          }
        }}
        to={to}
      >
        {iconLeft && <Icon icon={`${iconLeft} left`} className={text ? 'with-margin' : ''} />}
        {text && <span>{text}</span>}
        {iconRight && <Icon icon={`${iconRight} right`} className={text ? 'with-margin' : ''} />}
        {children}
      </NavLink>
    );
  }

  return (
    <button
      {...rest}
      type="button"
      className={`btn${disableType || !type ? '' : getTypeClassName(type, inverted)}${
        className ? ` ${className}` : ''
      }${disabled ? ' disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {iconLeft && <Icon icon={`${iconLeft} left`} className={text ? 'with-margin' : ''} />}
      {text && <span>{text}</span>}
      {children}
      {iconRight && <Icon icon={`${iconRight} right`} className={text ? 'with-margin' : ''} />}
    </button>
  );
};

Button.defaultProps = {
  children: null,
  className: null,
  disableType: false,
  disabled: false,
  external: false,
  iconLeft: null,
  iconRight: null,
  inverted: false,
  loading: false,
  onClick: () => true,
  text: null,
  to: null,
  type: BUTTON_TYPES.primary,
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disableType: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  loading: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};

export default Button;
