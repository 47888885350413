import { useContext } from 'react';
import ToastContext from './context';

const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('No ToastProvider found. Please, make sure that you`ve added withToastProvider to your App');
  }

  return { add: context.add, remove: context.remove };
};

export default useToast;
