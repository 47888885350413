import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../Button/Button';
import './index.scss';

export default class CardSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardStyle: {
        WebkitTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
      cardWidth: 0,
      currentCard: 0,
      interval: null,
      lastCard: props.cardsToShow - 1,
      position: 0,
      startX: null,
    };
    this.cardsRef = createRef();
  }

  componentDidMount() {
    const { auto, seconds, allowKeyboard } = this.props;

    let interval = null;
    if (auto) {
      interval = setInterval(this.autoIncrement, seconds * 1000);
    }

    this.setState({ interval });
    this.resetCarousel();
    window.addEventListener('resize', this.resetCarousel);

    if (allowKeyboard) {
      window.addEventListener('keydown', this.handleKeyPress);
    }
  }

  componentDidUpdate(prevProps) {
    const { elements } = this.props;

    if (prevProps.elements?.length !== elements?.length) {
      this.resetCarousel();
    }
  }

  componentWillUnmount() {
    const { allowKeyboard } = this.props;

    this.stopAuto();
    window.removeEventListener('resize', this.resetCarousel);

    if (allowKeyboard) {
      window.addEventListener('keydown', this.handleKeyPress);
    }
  }

  /**
   * This method will be called on resize
   * to avoid problems with cards width.
   */
  resetCarousel = () => {
    const { elements, cardsToShow, startIndex } = this.props;
    let cardWidth = 0;

    if (this.cardsRef.current) {
      cardWidth = this.cardsRef.current.clientWidth / cardsToShow;
    }

    this.setState(
      {
        cardWidth,
        position: 0,
        currentCard: 0,
        lastCard: cardsToShow - 1,
        cardStyle: {
          WebkitTransform: 'translate3d(0, 0, 0)',
          transform: 'translate3d(0, 0, 0)',
        },
      },
      () => {
        if (startIndex && elements?.length && startIndex - 1 <= elements.length - cardsToShow) {
          this.changeToPosition(startIndex - 1);
        }
      },
    );
  };

  stopAuto = () => {
    const { interval } = this.state;

    if (interval) {
      clearInterval(interval);
      this.setState({ interval: null });
    }
  };

  autoIncrement = () => {
    const { currentCard, lastCard } = this.state;
    const { elements } = this.props;

    if (elements?.length && lastCard + 1 === elements.length) {
      this.changeToPosition(0);
    } else {
      this.changeToPosition(currentCard + 1);
    }
  };

  handleClick = (type) => {
    const { elements, infinite, cardsToShow } = this.props;

    const { currentCard } = this.state;
    const totalCards = elements?.length || 0;
    this.stopAuto();

    if ('next' === type && currentCard < totalCards - cardsToShow) {
      // next card
      this.changeToPosition(currentCard + 1);
    } else if (infinite && 'next' === type && currentCard >= totalCards - cardsToShow) {
      // next card when it's the last one
      this.changeToPosition(0);
    } else if ('prev' === type && 0 < currentCard) {
      // previous card
      this.changeToPosition(currentCard - 1);
    } else if (infinite && 'prev' === type && 0 >= currentCard) {
      // previous card when it's the first one
      this.changeToPosition(totalCards - cardsToShow);
    }
  };

  changeToPosition = (idx) => {
    let { currentCard, lastCard, position } = this.state;
    const { cardWidth } = this.state;

    let difference = 0;
    if (idx > lastCard) {
      // Select one of the next cards
      difference = idx - lastCard;
      position = position - difference * cardWidth;
      currentCard = currentCard + difference;
      lastCard = lastCard + difference;
    } else {
      // Select one of the previous cards
      difference = currentCard - idx;
      position = position + difference * cardWidth;
      currentCard = currentCard - difference;
      lastCard = lastCard - difference;
    }

    this.setCard(currentCard, position, lastCard);
  };

  getCardsWidth = () => {
    const { cardsToShow } = this.props;

    if (cardsToShow && 0 < cardsToShow) {
      return 100 / cardsToShow;
    }

    return 100;
  };

  setCard = (currentCard, position, lastCard) => {
    this.setState({
      currentCard,
      cardStyle: {
        WebkitTransform: `translate3d(${position}px, 0, 0)`,
        transform: `translate3d(${position}px, 0, 0)`,
      },
      position,
      lastCard,
    });
  };

  handleMovementStart = (event) => {
    const [{ clientX }] = event.touches;

    this.setState({ startX: clientX });
  };

  handleMovement = (event) => {
    const { currentCard, lastCard, startX } = this.state;
    const { elements, cardsToShow } = this.props;
    const [{ clientX }] = event.changedTouches;

    if (clientX < startX) {
      // swipe right
      this.setState({ startX: null }, () => {
        if (elements?.length && lastCard + 1 === elements.length) {
          this.changeToPosition(0);
        } else {
          this.changeToPosition(currentCard + 1);
        }
      });
    } else if (clientX > startX) {
      // swipe left
      this.setState({ startX: null }, () => {
        if (0 === currentCard) {
          this.changeToPosition(elements.length - cardsToShow);
        } else {
          this.changeToPosition(currentCard - 1);
        }
      });
    }
    this.stopAuto();
  };

  handleKeyPress = (event) => {
    const { allowKeyboard } = this.props;

    if (allowKeyboard) {
      if ('ArrowLeft' === event.key) {
        this.handleClick('prev');
      } else if ('ArrowRight' === event.key) {
        this.handleClick('next');
      }
    }
  };

  renderButtons = () => {
    const { currentCard } = this.state;
    const {
      buttonsBottom, elements, infinite, cardsToShow,
    } = this.props;
    return (
      <div className={`slider-btns${buttonsBottom ? ' bottom-btns' : ''}`}>
        <Button
          className="slider-btn btn-l"
          disabled={!infinite && 0 === currentCard}
          iconLeft="fas fa-chevron-left"
          onClick={() => this.handleClick('prev')}
          type={BUTTON_TYPES.transparent}
        />
        <Button
          className="slider-btn btn-r"
          disabled={!infinite && elements.length - cardsToShow === currentCard}
          iconRight="fas fa-chevron-right"
          onClick={() => this.handleClick('next')}
          type={BUTTON_TYPES.transparent}
        />
      </div>
    );
  };

  /**
   * return specific styles if cardsToShow is 1,
   * the current card is selected
   * and fade is active to show correctly the fade animation,
   * else return inline-block
   */
  getCardStyle = (idx, selected) => {
    const { cardsToShow, fade, animationDuration } = this.props;
    const styles = {
      display: 'inline-block',
    };

    if (1 === cardsToShow && !selected && fade) {
      styles.display = 'none';
    } else if (1 === cardsToShow && selected && fade) {
      styles.WebkitTransform = 'translate3d(0, 0, 0)';
      styles.transform = 'translate3d(0, 0, 0)';
      styles.animationDuration = `${animationDuration}s`;
      styles.WebkitAnimationDuration = `${animationDuration}s`;
    }

    return styles;
  };

  /**
   * return fade animation if cardsToShow is 1,
   * the current card is selected and fade is active,
   * else return card-in-slide simple className
   */
  getCardClass = (idx, selected) => {
    const { cardsToShow, fade } = this.props;

    if (1 === cardsToShow && selected && fade) {
      return 'card-in-slider fade-in';
    }

    return 'card-in-slider';
  };

  render() {
    const {
      cardStyle, cardWidth, currentCard, lastCard,
    } = this.state;
    const {
      buttonsBottom, cardFormatter, elements, id, showDots, showButtons, showPrevNext, allowMobile,
    } = this.props;

    return (
      <div className={`wiset-cards-slider${showPrevNext ? ' show-prev-next' : ''}`} id={id}>
        {showButtons && !buttonsBottom && this.renderButtons()}
        <div
          className="cards"
          ref={this.cardsRef}
          onTouchEnd={allowMobile ? this.handleMovement : null}
          onTouchStart={allowMobile ? this.handleMovementStart : null}
        >
          {0 < elements?.length &&
            elements.map((elem, idx) => {
              const selected = currentCard <= idx && idx <= lastCard;
              return (
                <div
                  className={this.getCardClass(idx, selected)}
                  key={idx}
                  style={{
                    ...cardStyle,
                    ...this.getCardStyle(idx, selected),
                    flex: `0 0 ${this.getCardsWidth()}%`,
                    width: cardWidth,
                  }}
                >
                  {cardFormatter(elem, idx)}
                </div>
              );
            })}
        </div>
        {showDots && (
          <div className="dots">
            {0 < elements?.length &&
              elements.map((elem, idx) => {
                const selected = currentCard <= idx && idx <= lastCard;
                return (
                  <Button
                    className={`element-dot${selected ? ' selected' : ''}`}
                    disabled={selected}
                    key={idx}
                    onClick={() => this.changeToPosition(idx)}
                    type={selected ? BUTTON_TYPES.primary : BUTTON_TYPES.transparent}
                  />
                );
              })}
          </div>
        )}
        {showButtons && buttonsBottom && this.renderButtons()}
      </div>
    );
  }
}

CardSlider.defaultProps = {
  allowKeyboard: true,
  allowMobile: true,
  animationDuration: 1.5,
  auto: true,
  buttonsBottom: false,
  cardsToShow: 4,
  elements: [],
  fade: false,
  infinite: true,
  seconds: 5,
  showButtons: true,
  showDots: true,
  showPrevNext: false,
  startIndex: 0,
};

CardSlider.propTypes = {
  allowKeyboard: PropTypes.bool,
  allowMobile: PropTypes.bool,
  animationDuration: PropTypes.number,
  auto: PropTypes.bool,
  buttonsBottom: PropTypes.bool,
  cardFormatter: PropTypes.func.isRequired,
  cardsToShow: PropTypes.number,
  elements: PropTypes.array,
  fade: PropTypes.bool,
  id: PropTypes.string.isRequired,
  infinite: PropTypes.bool,
  seconds: PropTypes.number,
  showButtons: PropTypes.bool,
  showDots: PropTypes.bool,
  showPrevNext: PropTypes.bool,
  startIndex: PropTypes.number,
};
