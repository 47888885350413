import { USER, COMMON } from '../../utils/dispatchTypes';

const initialState = {
  loading: false,
  loggedUser: null,
  loggedUserRole: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER.LOADING:
      return { ...state, loading: true };
    case USER.LOADING_END:
      return { ...state, loading: false };
    case USER.SET_USER:
      return { ...state, loggedUser: action.user, loggedUserRole: action.userRole };
    case COMMON.LOGOUT:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default user;
