/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Button, { BUTTON_TYPES } from '../Button/Button';

export const TOAST_TYPES = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

const Toast = ({
  children, clearOnClick, duration, remove, text, type,
}) => {
  const [hidding, setHidding] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(duration);
  const [timeoutId, setTimeoutId] = useState(false);
  const removeRef = useRef();
  removeRef.current = remove;

  const onRemove = () => {
    setHidding(true);

    setTimeout(() => {
      removeRef.current();
      setHidding(false);
    }, 600);
  };

  const onHover = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeRemaining(timeRemaining - (new Date().getTime() - startTime));
    }
  };

  const onLeave = () => {
    setStartTime(new Date().getTime());
    const id = setTimeout(onRemove, timeRemaining);
    setTimeoutId(id);
  };

  useEffect(() => {
    if (timeRemaining) {
      setStartTime(new Date().getTime());
      const id = setTimeout(onRemove, timeRemaining);
      setTimeoutId(id);

      return () => clearTimeout(timeoutId || id);
    }
    return () => true;
  }, []);

  return (
    <div
      className={`wiset-toast ${type ? `toast-${type}` : 'primary-bg'}${hidding ? ' hidding-toast' : ''}${
        clearOnClick ? ' clickable-toast' : ''
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={clearOnClick ? onRemove : null}
    >
      <div>
        {!clearOnClick && <Button type={BUTTON_TYPES.transparent} iconLeft="fas fa-times" onClick={onRemove} />}
        <div className="wiset-toast-content">
          {text && <p className="wiset-toast-text">{text}</p>}
          {children}
        </div>
      </div>
      {duration && (
        <div
          className="wiset-toast-duration"
          style={{
            transitionDuration: `${duration}ms`,
            animationDuration: `${duration}ms`,
          }}
        />
      )}
    </div>
  );
};

Toast.defaultProps = {
  children: null,
  clearOnClick: true,
  duration: 3000,
  text: null,
  type: null,
};

Toast.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  clearOnClick: PropTypes.bool,
  duration: PropTypes.number,
  remove: PropTypes.func.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TOAST_TYPES).map((type) => type)),
};

export default Toast;
