import { autologin } from '../user';
import { COMMON } from '../../utils/dispatchTypes';

const initialize = () => (dispatch) => {
  dispatch({ type: COMMON.LOADING });
  Promise.all([
    dispatch(autologin()),
  ])
    .then(() => dispatch({ type: COMMON.LOADING_END }))
    .catch(() => dispatch({ type: COMMON.LOADING_END }));
};

export default initialize;
