import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBox from '../ErrorBox/ErrorBox';
import './index.scss';

export const MAP_TYPES = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain',
};

export const MAP_GESTURE_TYPES = {
  AUTO: 'auto',
  COOPERATIVE: 'cooperative',
  GREEDY: 'greedy',
  NONE: 'none',
};

export const MAP_CONTROL_POSITION = {
  TOP_LEFT: 1,
  TOP_CENTER: 2,
  TOP: 2,
  TOP_RIGHT: 3,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  RIGHT_TOP: 7,
  RIGHT: 7,
  RIGHT_CENTER: 8,
  RIGHT_BOTTOM: 9,
  BOTTOM_LEFT: 10,
  BOTTOM_CENTER: 11,
  BOTTOM: 11,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
};

const centerMap = (mapDiv, position) => mapDiv.panTo(position);

const makeMarkerBounce = (mapMarker) => mapMarker.setAnimation(window.google.maps.Animation.BOUNCE);
const stopMarkerBounce = (mapMarker) => mapMarker.setAnimation(null);

const createMarker = (mapDiv, marker, bounceOnHover, bounceStopOnLeave, onMarkerHover) => {
  if (marker && marker.lat && marker.lng && mapDiv) {
    const {
      _id, icon, lat, lng, name,
    } = marker;

    const mapIcon = {
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
    };

    if (icon) {
      mapIcon.url = icon;
    }

    const mapMarker = new window.google.maps.Marker({
      map: mapDiv,
      icon: mapIcon,
      title: name,
      position: { lat, lng },
    });

    if (bounceOnHover) {
      mapMarker.addListener('mouseover', () => {
        makeMarkerBounce(mapMarker);
        if (onMarkerHover && 'function' === typeof onMarkerHover) {
          onMarkerHover(_id);
        }
      });
      if (bounceStopOnLeave) {
        mapMarker.addListener('mouseout', () => {
          stopMarkerBounce(mapMarker);
        });
      }
    }

    return mapMarker;
  }
  return null;
};

/**
 * This map needs the library to be added on application the first load.
 *
 * The property GOOGLE_API_KEY needs to be setted in the .env file, and this key needs to have permissions for
 * Google Maps and for Geocoder (if we use the address field)
 *
  const initializeMapsApi = (callback) => {
    if (!window.google) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`;
      script.id = 'googleMaps';
      document.body.appendChild(script);
      return script.addEventListener('load', callback);
    }
    return callback();
  };
 */
export default class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiError: false,
      mapDiv: null,
      stateMarkers: [],
    };
  }

  componentDidMount() {
    const { address } = this.props;

    if (address) {
      this.getAddressLatitudeLongitude();
    } else {
      this.createMap();
    }
  }

  componentDidUpdate(prevProps) {
    const { address, markers, selectedMarker } = this.props;
    const { apiError, mapDiv, stateMarkers } = this.state;

    if (!apiError) {
      if (address && prevProps.address !== address) {
        this.getAddressLatitudeLongitude();
      }

      if (stateMarkers && stateMarkers.length && selectedMarker !== prevProps.selectedMarker) {
        // If it had a previous marker, remove animation
        if (prevProps.selectedMarker) {
          const prevMarker = stateMarkers.find((mrkr) => mrkr.id === prevProps.selectedMarker);
          stopMarkerBounce(prevMarker.marker);
        }
        // If has a new marker, add animation
        if (selectedMarker) {
          const currMarker = stateMarkers.find((mrkr) => mrkr.id === selectedMarker);
          setTimeout(() => makeMarkerBounce(currMarker.marker), 500);
          centerMap(mapDiv, currMarker.marker.position);
        }
      }
      if ((!stateMarkers || !stateMarkers.length) && markers && markers.length) {
        this.createMap();
      }
    }
  }

  getMapOptions = () => {
    const {
      backgroundColor,
      center,
      clickableIcons,
      disableDefaultUI,
      disableDoubleClickZoom,
      fullscreenControl,
      fullscreenControlPosition,
      gestureHandling,
      keyboardShortcuts,
      mapTypeControl,
      mapTypeControlPosition,
      mapTypeId,
      maxZoom,
      minZoom,
      noClear,
      rotateControl,
      rotateControlPosition,
      scaleControl,
      streetViewControl,
      streetViewControlPosition,
      tilt,
      zoom,
      zoomControl,
      zoomControlPosition,
    } = this.props;

    return {
      backgroundColor,
      center,
      clickableIcons,
      disableDefaultUI,
      disableDoubleClickZoom,
      draggable: false,
      fullscreenControl,
      fullscreenControlOptions:
        fullscreenControl && fullscreenControlPosition ?
          {
            position: fullscreenControlPosition,
          } :
          null,
      gestureHandling,
      keyboardShortcuts,
      mapTypeControl,
      mapTypeControlOptions:
        mapTypeControl && mapTypeControlPosition ?
          {
            position: mapTypeControlPosition,
          } :
          null,
      mapTypeId,
      maxZoom,
      minZoom,
      noClear,
      rotateControl,
      rotateControlOptions:
        rotateControl && rotateControlPosition ?
          {
            position: rotateControlPosition,
          } :
          null,
      scaleControl,
      streetViewControl,
      streetViewControlOptions:
        streetViewControl && streetViewControlPosition ?
          {
            position: streetViewControlPosition,
          } :
          null,
      tilt,
      zoom,
      zoomControl,
      zoomControlOptions:
        zoomControl && zoomControlPosition ?
          {
            position: zoomControlPosition,
          } :
          null,
    };
  };

  getAddressLatitudeLongitude = () => {
    if (window.google && window.google.maps && window.google.maps.Map) {
      const { address } = this.props;
      const mapOptions = this.getMapOptions();

      // Initialize the Geocoder api, to find the address and get the location
      const geocoder = new window.google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({ address }, (results, status) => {
          if ('OK' === status && 1 <= results.length) {
            mapOptions.center.lat = results[0].geometry.location.lat();
            mapOptions.center.lng = results[0].geometry.location.lng();
          }
          this.createMap(mapOptions);
        });
      }
    } else {
      this.setState({ apiError: true });
    }
  };

  createMap = (mapOpts) => {
    const {
      centerMarker,
      centerMarkerIcon,
      mapId,
      markers,
      bounceOnHover,
      bounceStopOnLeave,
      onMarkerHover,
    } = this.props;
    const mapOptions = mapOpts || this.getMapOptions();

    if (window.google && window.google.maps && window.google.maps.Map) {
      const mapDiv = new window.google.maps.Map(document.getElementById(mapId), mapOptions);

      let stateMarkers = [];
      if (markers && markers.length) {
        stateMarkers = markers.map((marker) => ({
          id: marker._id,
          marker: createMarker(mapDiv, marker, bounceOnHover, bounceStopOnLeave, onMarkerHover),
        }));
      }
      if (centerMarker) {
        stateMarkers.push(
          createMarker(
            mapDiv,
            {
              _id: 'wiset-center-marker',
              lat: mapOptions.center.lat,
              lng: mapOptions.center.lng,
              icon: centerMarkerIcon,
            },
            bounceOnHover,
            bounceStopOnLeave,
            onMarkerHover,
          ),
        );
      }

      this.setState({ stateMarkers, mapDiv });
    } else {
      this.setState({ apiError: true });
    }
  };

  render() {
    const {
      apiErrorText, mapId, minWidth, minHeight,
    } = this.props;
    const { apiError } = this.state;

    if (apiError) {
      return <ErrorBox text={apiErrorText} />;
    }

    return <div id={mapId} style={{ minWidth, minHeight }} />;
  }
}

GoogleMap.defaultProps = {
  address: 'Error loading Google Maps API.',
  apiErrorText: null,
  backgroundColor: null,
  bounceOnHover: true,
  bounceStopOnLeave: true,
  center: { lat: 40.416775, lng: -3.70379 },
  centerMarker: false,
  centerMarkerIcon: null,
  clickableIcons: false,
  disableDefaultUI: false,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
  fullscreenControlPosition: MAP_CONTROL_POSITION.RIGHT_TOP,
  gestureHandling: MAP_GESTURE_TYPES.AUTO,
  keyboardShortcuts: false,
  mapId: 'map',
  mapTypeControl: false,
  mapTypeControlPosition: MAP_CONTROL_POSITION.TOP_RIGHT,
  mapTypeId: MAP_TYPES.ROADMAP,
  markers: [],
  maxZoom: 18,
  minZoom: 0,
  minHeight: '500px',
  minWidth: '100%',
  noClear: false,
  onMarkerHover: null,
  rotateControl: false,
  rotateControlPosition: MAP_CONTROL_POSITION.TOP_LEFT,
  scaleControl: true,
  selectedMarker: null,
  streetViewControl: false,
  streetViewControlPosition: MAP_CONTROL_POSITION.BOTTOM_RIGHT,
  tilt: 0,
  zoom: 13,
  zoomControl: true,
  zoomControlPosition: MAP_CONTROL_POSITION.TOP_LEFT,
};

GoogleMap.propTypes = {
  address: PropTypes.string,
  apiErrorText: PropTypes.string,
  backgroundColor: PropTypes.string,
  bounceOnHover: PropTypes.bool,
  bounceStopOnLeave: PropTypes.bool,
  center: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  centerMarker: PropTypes.bool,
  centerMarkerIcon: PropTypes.string,
  clickableIcons: PropTypes.bool,
  disableDefaultUI: PropTypes.bool,
  disableDoubleClickZoom: PropTypes.bool,
  fullscreenControl: PropTypes.bool,
  fullscreenControlPosition: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION).map((type) => type)),
  gestureHandling: PropTypes.oneOf(Object.values(MAP_GESTURE_TYPES).map((type) => type)),
  keyboardShortcuts: PropTypes.bool,
  mapId: PropTypes.string,
  mapTypeControl: PropTypes.bool,
  mapTypeControlPosition: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION).map((type) => type)),
  mapTypeId: PropTypes.oneOf(Object.values(MAP_TYPES).map((type) => type)),
  // markers: Array of objects like: { _id, icon, lat, lng, name }
  markers: PropTypes.array,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  noClear: PropTypes.bool,
  onMarkerHover: PropTypes.func,
  rotateControl: PropTypes.bool,
  rotateControlPosition: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION).map((type) => type)),
  scaleControl: PropTypes.bool,
  selectedMarker: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  streetViewControl: PropTypes.bool,
  streetViewControlPosition: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION).map((type) => type)),
  tilt: PropTypes.number,
  zoom: PropTypes.number,
  zoomControl: PropTypes.bool,
  zoomControlPosition: PropTypes.oneOf(Object.values(MAP_CONTROL_POSITION).map((type) => type)),
};
