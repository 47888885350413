import { BUTTON_TYPES } from '../../components/base-comps';

import { translate } from '../translate/translator';

const add = {
  iconLeft: 'add-outline',
  text: translate('common.add'),
};

const addDropdown = {
  ...add,
  icon: add.iconLeft,
};

const back = {
  className: 'back-btn',
  iconLeft: 'arrow-back-outline',
  text: translate('common.back'),
};

const cancel = {
  iconLeft: 'close-outline',
  inverted: true,
  text: translate('common.cancel'),
  type: BUTTON_TYPES.gray,
};

const done = {
  iconLeft: 'checkmark-outline',
  text: translate('common.done'),
};

const edit = {
  iconLeft: 'create-outline',
  text: translate('common.edit'),
};

const editDropdown = {
  ...edit,
  icon: edit.iconLeft,
};

const remove = {
  iconLeft: 'trash-outline',
  text: translate('common.remove'),
};

const removeDropdown = {
  ...remove,
  icon: remove.iconLeft,
};

const save = {
  iconLeft: 'save-outline',
  text: translate('common.save'),
};

const seeMore = {
  iconLeft: 'eye-outline',
  text: translate('common.seeMore'),
  type: BUTTON_TYPES.secondary,
};

const seeMoreDropdown = {
  ...seeMore,
  icon: seeMore.iconLeft,
};

export default {
  add,
  addDropdown,
  back,
  cancel,
  done,
  edit,
  editDropdown,
  remove,
  removeDropdown,
  save,
  seeMore,
  seeMoreDropdown,
};
