import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, INPUT_TYPES } from '../../components/base-comps';
import Page from '../../components/base/Page';

import { validateEmail } from '../../utils/validator';
import { translate } from '../../utils/translate/translator';

import logo from '../../assets/images/logo.png';

import './index.scss';

const Login = ({ login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const loginUser = () => {
    setLoading(true);

    login(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const checkForm = () => {
    if (!email || !validateEmail(email)) {
      setError({ ...error, email: true });
    }

    if (!password) {
      setError({ ...error, password: true });
    }

    if (email && password) {
      setError({});
      loginUser();
    }
  };

  return (
    <Page id="login-page" innerPadding={false}>
      <div className="login-container">
        <div className="logo-container">
          <img src={logo} alt={translate('common.brandName')} />
          <h1>{translate('common.brandName')}</h1>
        </div>
        <div className="login-form">
          <Input
            disabled={loading}
            placeholder="Email"
            onChange={setEmail}
            type={INPUT_TYPES.email}
          />
          <Input
            disabled={loading}
            onChange={setPassword}
            placeholder="Contraseña"
            type={INPUT_TYPES.password}
          />
          <Button
            loading={loading}
            disabled={loading}
            text="Entrar"
            onClick={checkForm}
          />
        </div>
      </div>
    </Page>
  );
};

Login.defaultProps = {
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
