import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../Input/Input';
import AlertBox from '../AlertBox/AlertBox';
import SelectorOption from './selectorOption';
import SelectedOption from './selectedOption';
import Button, { BUTTON_TYPES } from '../Button/Button';

import './index.scss';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      filteredOptions: [],
      selectedOptions: [],
      showResults: false,
      value: '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    this.filterOptions();
  }

  componentDidUpdate({ results: prevRes, selectedOptions: prevOpt }) {
    const { results, selectedOptions } = this.props;

    if (
      (prevOpt && !selectedOptions) ||
      (!prevOpt && selectedOptions) ||
      (prevRes && !results) ||
      (!prevRes && results) ||
      (prevRes &&
        prevOpt &&
        results &&
        selectedOptions &&
        (prevOpt !== selectedOptions ||
          prevOpt.length !== selectedOptions.length ||
          prevRes !== results ||
          prevRes.length !== results.length))
    ) {
      // selected options or results has changed
      this.filterOptions();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  filterOptions = () => {
    const { optionKey, results, selectedOptions } = this.props;

    let filteredOptions = [];
    if (results && results.length) {
      filteredOptions = [...results];

      if (selectedOptions && selectedOptions.length) {
        filteredOptions = results.filter((res) => !selectedOptions.find((opt) => opt[optionKey] === res[optionKey]));
      }
    }

    this.setState({ filteredOptions, selectedOptions });
  };

  handleClick = (event) => {
    const { searchText, resultsResults } = this.state;
    /**
     * check if click is outside search reference
     * if the click is outside then hide results
     */
    if (this.search && !this.search.contains(event.target)) {
      this.setState({ showResults: false });
    } else if (searchText && resultsResults && 0 < resultsResults.length) {
      this.setState({ showResults: true });
    }
  };

  onSearch = () => {
    const { value } = this.state;
    const { allowEmptySearch, onSearch } = this.props;

    if (value || allowEmptySearch) {
      onSearch(value);
      this.setState({ showResults: true });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    const {
      addOptionTooltip,
      allowEmptySearch,
      buttonIcon,
      buttonType,
      className,
      customResultOption,
      customSelectedOption,
      disabled,
      inputIcon,
      inputInfo,
      inputMaxLength,
      loading,
      loadingFormat,
      noResultsText,
      onAddOption,
      onRemoveSelected,
      optionButtonIcon,
      optionButtonType,
      optionImageIcon,
      optionImageKey,
      optionKey,
      optionShowButton,
      optionShowImage,
      optionShowText,
      optionTextKey,
      placeholder,
      removePlayerTooltip,
      selectedOptionButtonIcon,
      selectedOptionButtonType,
      selectedOptionImageIcon,
      selectedOptionShowButton,
      selectedOptionShowImage,
      selectedOptionShowText,
      showSelectedOnly,
      showSelected,
    } = this.props;
    const {
      error, filteredOptions, selectedOptions, showResults, value,
    } = this.state;

    return (
      <div className={`wiset-search${className ? ` ${className}` : ''}`} ref={(search) => (this.search = search)}>
        {!showSelectedOnly && (
          <div className="wiset-search-container">
            <div className="wiset-search-elems">
              <Input
                disabled={disabled || loading}
                error={error}
                icon={inputIcon}
                infoText={inputInfo}
                maxLength={inputMaxLength}
                onChange={(val) => this.setState({ value: val, error: false, showResults: false })}
                onFocus={() => {
                  if (filteredOptions && filteredOptions.length) {
                    this.setState({ showResults: true });
                  }
                }}
                onEnter={this.onSearch}
                placeholder={placeholder}
                value={value}
              />
              {showResults && (
                <div className={`wiset-search-results${inputInfo ? ' with-info' : ''}`}>
                  <div className="wiset-search-results-container">
                    {loading && loadingFormat}
                    {!loading && (!filteredOptions || 0 >= filteredOptions.length) && <AlertBox text={noResultsText} />}
                    {!loading &&
                      filteredOptions &&
                      0 < filteredOptions.length &&
                      filteredOptions.map((opt, idx) =>
                        (customResultOption && 'function' === typeof customResultOption ? (
                          customResultOption(opt, idx)
                        ) : (
                          <SelectorOption
                            key={opt[optionKey] || idx}
                            onAdd={() => {
                              const newOptions = selectedOptions ? [...selectedOptions, opt] : [opt];
                              onAddOption(opt, idx, newOptions);
                              this.setState({ showResults: false, selectedOptions: newOptions });
                            }}
                            text={opt[optionTextKey]}
                            tooltipText={addOptionTooltip}
                            buttonIcon={optionButtonIcon}
                            buttonType={optionButtonType}
                            image={optionImageKey ? opt[optionImageKey] : null}
                            imageIcon={optionImageIcon}
                            showButton={optionShowButton}
                            showImage={optionShowImage}
                            showText={optionShowText}
                          />
                        )))}
                  </div>
                </div>
              )}
            </div>
            <Button
              className="search-btn"
              disabled={((!value || '' === value) && !allowEmptySearch) || loading || disabled}
              onClick={this.onSearch}
              iconRight={buttonIcon}
              type={buttonType}
            />
          </div>
        )}
        {showSelected && (
          <div className="wiset-search-selected">
            {selectedOptions &&
              0 < selectedOptions.length &&
              selectedOptions.map((opt, idx) =>
                (customSelectedOption && 'function' === typeof customSelectedOption ? (
                  customSelectedOption(opt, idx)
                ) : (
                  <SelectedOption
                    key={opt[optionKey] || idx}
                    onRemove={() => {
                      const newOptions = selectedOptions.filter((newOpt) => newOpt[optionKey] !== opt[optionKey]);
                      onRemoveSelected(opt, idx, newOptions);
                      this.setState({ selectedOptions: newOptions });
                    }}
                    text={opt[optionTextKey]}
                    tooltipText={removePlayerTooltip}
                    buttonIcon={selectedOptionButtonIcon}
                    buttonType={selectedOptionButtonType}
                    image={optionImageKey ? opt[optionImageKey] : null}
                    imageIcon={selectedOptionImageIcon}
                    showButton={selectedOptionShowButton}
                    showImage={selectedOptionShowImage}
                    showText={selectedOptionShowText}
                  />
                )))}
          </div>
        )}
      </div>
    );
  }
}

Search.defaultProps = {
  addOptionTooltip: 'Add',
  allowEmptySearch: false,
  buttonIcon: 'fas fa-search',
  buttonType: BUTTON_TYPES.primary,
  className: null,
  customResultOption: null,
  customSelectedOption: null,
  disabled: false,
  inputIcon: 'fas fa-search',
  inputInfo: null,
  inputMaxLength: 100,
  loading: false,
  loadingFormat: null,
  noResultsText: 'No results found',
  onAddOption: () => true,
  onRemoveSelected: () => true,
  optionButtonIcon: 'fas fa-plus',
  optionButtonType: BUTTON_TYPES.primaryDark,
  optionImageIcon: 'fas fa-user-circle',
  optionImageKey: null,
  optionShowButton: true,
  optionShowImage: true,
  optionShowText: true,
  optionTextKey: 'text',
  placeholder: null,
  removePlayerTooltip: 'Add',
  results: [],
  selectedOptionButtonIcon: 'fas fa-trash',
  selectedOptionButtonType: BUTTON_TYPES.primary,
  selectedOptionImageIcon: 'fas fa-user-circle',
  selectedOptions: [],
  selectedOptionShowButton: true,
  selectedOptionShowImage: true,
  selectedOptionShowText: true,
  showSelected: true,
  showSelectedOnly: false,
};

Search.propTypes = {
  addOptionTooltip: PropTypes.string,
  allowEmptySearch: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  className: PropTypes.string,
  customResultOption: PropTypes.func,
  customSelectedOption: PropTypes.func,
  disabled: PropTypes.bool,
  inputIcon: PropTypes.string,
  inputInfo: PropTypes.string,
  inputMaxLength: PropTypes.number,
  loading: PropTypes.bool,
  loadingFormat: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noResultsText: PropTypes.string,
  onAddOption: PropTypes.func,
  onRemoveSelected: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  optionButtonIcon: PropTypes.string,
  optionButtonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  optionImageIcon: PropTypes.string,
  optionImageKey: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  optionShowButton: PropTypes.bool,
  optionShowImage: PropTypes.bool,
  optionShowText: PropTypes.bool,
  optionTextKey: PropTypes.string,
  placeholder: PropTypes.string,
  removePlayerTooltip: PropTypes.string,
  results: PropTypes.array,
  selectedOptionButtonIcon: PropTypes.string,
  selectedOptionButtonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  selectedOptionImageIcon: PropTypes.string,
  selectedOptions: PropTypes.array,
  selectedOptionShowButton: PropTypes.bool,
  selectedOptionShowImage: PropTypes.bool,
  selectedOptionShowText: PropTypes.bool,
  showSelected: PropTypes.bool,
  showSelectedOnly: PropTypes.bool,
};

export default Search;
