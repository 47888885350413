import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../../Button/Button';

const TabOption = ({
  className, error, icon, label, normalType, onClick, selected, selectedType, value,
}) => (
  <Button
    className={`wiset-tab${className ? ` ${className}` : ''}${selected ? ' wiset-tab-selected' : ''}${
      error ? ' wiset-tab-error' : ''
    }`}
    iconLeft={icon}
    onClick={() => onClick(value)}
    text={label}
    type={selected ? selectedType : normalType}
  />
);

TabOption.defaultProps = {
  className: null,
  error: false,
  icon: null,
  label: null,
  normalType: BUTTON_TYPES.primaryDark,
  onClick: () => true,
  selected: false,
  selectedType: BUTTON_TYPES.primary,
  value: null,
};

TabOption.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  normalType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  selectedType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  value: PropTypes.string,
};

export default TabOption;
