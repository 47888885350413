import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';
import Button, { BUTTON_TYPES } from '../../Button/Button';
import Tooltip, { TOOLTIP_POSITION } from '../../Tooltip/Tooltip';

const SelectorOption = ({
  buttonIcon,
  buttonType,
  imageIcon,
  image,
  onAdd,
  showButton,
  showImage,
  showText,
  text,
  tooltipText,
}) => (
  <div className="wiset-search-option">
    <div className="option-image-text">
      {showImage && (
        <div className="option-image">
          {image ? <img src={image} alt="option" /> : <Icon icon={imageIcon} className={`${buttonType}-color`} />}
        </div>
      )}
      {showText && <p>{text}</p>}
    </div>
    {showButton && (
      <Tooltip visible={!!tooltipText} position={TOOLTIP_POSITION.left} message={tooltipText}>
        <Button className="add-btn" iconLeft={buttonIcon} onClick={onAdd} type={buttonType} />
      </Tooltip>
    )}
  </div>
);

SelectorOption.defaultProps = {
  buttonIcon: 'fas fa-plus',
  buttonType: BUTTON_TYPES.primary,
  image: null,
  imageIcon: 'fas fa-user-circle',
  onAdd: () => true,
  showButton: true,
  showImage: true,
  showText: true,
  text: null,
  tooltipText: 'Add',
};

SelectorOption.propTypes = {
  buttonIcon: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  image: PropTypes.string,
  imageIcon: PropTypes.string,
  onAdd: PropTypes.func,
  showButton: PropTypes.bool,
  showImage: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default SelectorOption;
