import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import LoginPage from '../../pages/login';
import { login } from '../../actions/user';

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      login,
    },
    dispatch,
  ),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(LoginPage),
);
