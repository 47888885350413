import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TabOption from './tabOption';
import { BUTTON_TYPES } from '../Button/Button';

import './index.scss';

const Tabs = ({
  buttonType,
  className,
  errors,
  iconKey,
  labelKey,
  onTabChange,
  options,
  selectedButtonType,
  selectedTab,
  showLine,
  tabFormatter,
  valueKey,
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!selected && options && options.length) {
      setSelected(options[0] && options[0][valueKey]);
    }
  }, [options, selected]);
  useEffect(() => {
    setSelected(selectedTab);
  }, [selectedTab]);

  return (
    <div className={`wiset-tabs${className ? ` ${className}` : ''}`}>
      {options &&
        0 < options.length &&
        options.map((option, idx) =>
          (tabFormatter ? (
            <div key={idx}>{tabFormatter(option)}</div>
          ) : (
            <TabOption
              key={idx}
              className={showLine ? ` ${selectedButtonType}-border` : null}
              error={errors && errors[option[valueKey]]}
              normalType={buttonType}
              label={option[labelKey]}
              icon={options[iconKey]}
              onClick={onTabChange}
              selected={selected === option[valueKey]}
              selectedType={selectedButtonType}
              value={option[valueKey]}
            />
          )))}
    </div>
  );
};

Tabs.defaultProps = {
  buttonType: BUTTON_TYPES.primaryDark,
  className: null,
  errors: null,
  iconKey: 'icon',
  labelKey: 'label',
  onTabChange: () => true,
  options: [],
  selectedButtonType: BUTTON_TYPES.primary,
  selectedTab: null,
  showLine: true,
  tabFormatter: null,
  valueKey: 'value',
};

Tabs.propTypes = {
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  className: PropTypes.string,
  errors: PropTypes.object,
  iconKey: PropTypes.string,
  labelKey: PropTypes.string,
  onTabChange: PropTypes.func,
  options: PropTypes.array, // Array of objects like { className, error, icon, label*, value* }
  selectedButtonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  selectedTab: PropTypes.any,
  showLine: PropTypes.bool,
  tabFormatter: PropTypes.func,
  valueKey: PropTypes.string,
};

export default Tabs;
