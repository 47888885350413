import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './Paginator.scss';
import Button, { BUTTON_TYPES } from '../Button/Button';

const calculatePages = ({ maxPages, pagesToShow, actualPage }) => {
  const pagesToRender = [actualPage];

  for (let i = 1; i <= pagesToShow; i = i + 1) {
    if (pagesToRender.length === pagesToShow) {
      break;
    }

    if (actualPage + i <= maxPages && pagesToShow > pagesToRender.length) {
      pagesToRender.push(actualPage + i);
    }

    if (0 < actualPage - i && pagesToShow > pagesToRender.length) {
      pagesToRender.push(actualPage - i);
    }
  }

  return pagesToRender.sort((a, b) => a - b);
};

const Paginator = ({
  className,
  currentPage,
  disabled,
  iconFirst,
  iconLast,
  iconNext,
  iconPrev,
  inverted,
  maxPages,
  onChange,
  onNext,
  pagesToShow,
  showFirstAndLast,
  textFirst,
  textLast,
  textNext,
  textPrev,
  type,
}) => {
  const [pagesToRender, setPagesToRender] = useState([1]);
  const [actualPage, setActualPage] = useState(1);

  useEffect(() => {
    if (maxPages && pagesToShow && actualPage) {
      setPagesToRender(calculatePages({ maxPages, pagesToShow, actualPage }));
    }
  }, [maxPages, pagesToShow, actualPage]);
  useEffect(() => {
    setActualPage(parseFloat(currentPage));
  }, [currentPage]);

  return (
    <div className={`wiset-paginator${className ? ` ${className}` : ''}`}>
      {showFirstAndLast && (
        <Button
          className={1 === actualPage ? 'selected' : null}
          disabled={disabled || 1 === actualPage}
          iconLeft={iconFirst}
          onClick={() => {
            if (onChange && 'function' === typeof onChange) {
              onChange(1);
            }
            if (onNext && 'function' === typeof onNext) {
              onNext();
            }
            setActualPage(1);
          }}
          inverted={inverted}
          text={textFirst}
          type={type}
        />
      )}
      <Button
        className={1 === actualPage ? 'selected' : null}
        disabled={disabled || 1 === actualPage}
        iconLeft={iconPrev}
        onClick={() => {
          if (onChange && 'function' === typeof onChange) {
            onChange(parseFloat(actualPage) - 1);
          }
          if (onNext && 'function' === typeof onNext) {
            onNext();
          }
          setActualPage(parseFloat(actualPage) - 1);
        }}
        inverted={inverted}
        text={textPrev}
        type={type}
      />
      {pagesToRender.map((page) => (
        <Button
          key={page}
          className={actualPage === page ? 'selected' : null}
          disabled={disabled || actualPage === page}
          onClick={() => {
            if (onChange && 'function' === typeof onChange) {
              onChange(parseFloat(page));
            }
            if (onNext && 'function' === typeof onNext) {
              onNext();
            }
            setActualPage(parseFloat(page));
          }}
          inverted={inverted}
          text={`${page}`}
          type={type}
        />
      ))}
      <Button
        className={maxPages === actualPage ? 'selected' : null}
        disabled={disabled || maxPages === actualPage}
        iconRight={iconNext}
        onClick={() => {
          if (onChange && 'function' === typeof onChange) {
            onChange(parseFloat(actualPage) + 1);
          }
          if (onNext && 'function' === typeof onNext) {
            onNext();
          }
          setActualPage(parseFloat(actualPage) + 1);
        }}
        inverted={inverted}
        text={textNext}
        type={type}
      />
      {showFirstAndLast && (
        <Button
          className={maxPages === actualPage ? 'selected' : null}
          disabled={disabled || maxPages === actualPage}
          iconRight={iconLast}
          onClick={() => {
            if (onChange && 'function' === typeof onChange) {
              onChange(maxPages);
            }
            if (onNext && 'function' === typeof onNext) {
              onNext();
            }
            setActualPage(maxPages);
          }}
          inverted={inverted}
          text={textLast}
          type={type}
        />
      )}
    </div>
  );
};

Paginator.defaultProps = {
  className: null,
  currentPage: 1,
  disabled: false,
  iconFirst: 'fas fa-chevron-double-left',
  iconLast: 'fas fa-chevron-double-right',
  iconNext: 'fas fa-chevron-right',
  iconPrev: 'fas fa-chevron-left',
  inverted: false,
  maxPages: 1,
  onChange: () => true,
  onNext: () => true,
  pagesToShow: 5,
  showFirstAndLast: true,
  textFirst: undefined,
  textLast: undefined,
  textNext: undefined,
  textPrev: undefined,
  type: BUTTON_TYPES.primary,
};

Paginator.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  disabled: PropTypes.bool,
  iconFirst: PropTypes.string,
  iconLast: PropTypes.string,
  iconNext: PropTypes.string,
  iconPrev: PropTypes.string,
  inverted: PropTypes.bool,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  pagesToShow: PropTypes.number,
  showFirstAndLast: PropTypes.bool,
  textFirst: PropTypes.string,
  textLast: PropTypes.string,
  textNext: PropTypes.string,
  textPrev: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};

export default Paginator;
