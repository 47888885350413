const listProducts = /* GraphQL */ `
  query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        images {
          url
          isMain
        }
        name
        url
        public
        categories {
          items {
            category {
              name
              id
            }
          }
        }
        creator {
          name
        }
        validator {
          name
        }
      }
      nextToken
    }
  }
`;

const listProductDefaultCategorys = /* GraphQL */ `
  query ListProductDefaultCategorys(
    $filter: ModelProductDefaultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductDefaultCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      images {
        url
        isMain
      }
      name
      url
      public
      createdAt
      categories {
        items {
          category {
            name
            id
          }
        }
      }
      creator {
        name
      }
    }
  }
`;

export default {
  listProducts,
  getProduct,
  listProductDefaultCategorys,
};
