import React, { useState, useEffect } from 'react';

import { BACKOFFICE_SHOP } from '../../../utils/pagesUrls';

import Page from '../../../components/base/Page';
import {
  GenericBackoffice, GenericBackofficeElement, Input, SureModal, Icon,
} from '../../../components/base-comps';

import { getAllShops, deleteShop, restoreShop } from '../../../actions/shop';

import { showSuccessToast, showErrorToast } from '../../../utils/toasts';
import forms from '../../../utils/forms';
import { translate } from '../../../utils/translate/translator';

import { SURE_MODES } from '../../../utils/constants';

import './index.scss';

const Shops = () => {
  const [loading, setLoading] = useState(true);
  const [allShops, setAllShops] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [sureMode, setSureMode] = useState(null);
  const [showSure, setShowSure] = useState(false);

  const getBackofficeShops = () => {
    setLoading(true);
    getAllShops()
      .then(({ items }) => {
        setAllShops(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBackofficeShops();
  }, []);

  const onDelete = (item, callback) => {
    setLoadingModal(true);

    deleteShop(item.id)
      .then(() => {
        showSuccessToast(translate('shop.deletedOk'));
        getBackofficeShops();
        setLoadingModal(false);
        callback();
      })
      .catch(() => {
        showErrorToast(translate('shop.deletedKo'));
        setLoadingModal(false);
      });
  };

  const hideSureModal = () => {
    setSelectedShop(null);
    setShowSure(false);
    setSureMode(null);
  };

  const getExtraActions = (shop) => {
    const res = [
      {
        text: translate('common.seeMore'),
        icon: 'fas fa-eye',
        url: `${BACKOFFICE_SHOP}/${shop.id}`,
      },
    ];

    if (shop.disabled) {
      res.push({
        text: translate('shop.restore'),
        icon: 'fas fa-check',
        onClick: () => {
          setSelectedShop(shop);
          setShowSure(true);
          setSureMode(SURE_MODES.RESTORE);
        },
      });
    }

    return res;
  };

  const modalContent = (shop) => (
    <>
      <p>
        {
          sureMode ?
            `${translate(`shop.${sureMode}Sure`)}: ` :
            `${translate('shop.deleteSure')} :`
        }
      </p>
      <Input
        disabled
        value={shop.id}
        placeholder="Id"
      />
      <Input
        disabled
        value={shop.name}
        placeholder={translate('shop.name')}
      />
    </>
  );

  return (
    <Page id="backoffice-shops-container" grayBackground withSidebar>
      <GenericBackoffice
        {...forms.backoffice.table}
        previousLoad
        tableProps={{ ...forms.backoffice.table.tableProps, csvFileName: translate('backoffice.shops') }}
        translations={{ ...forms.backoffice.table.translations, noItems: translate('common.noItems') }}
        addButtonProps={{ ...forms.backoffice.table.addButtonProps, to: `${BACKOFFICE_SHOP}/new` }}
        entity={translate('backoffice.shop')}
        extraActions={getExtraActions}
        loading={loading}
        objects={allShops}
        onDelete={onDelete}
        loadingModal={loadingModal}
        showFilters={false}
        showDelete
        deleteElementFormatter={modalContent}
        showEdit={false}
        title={translate('backoffice.shops')}
      >
        <GenericBackofficeElement
          field="id"
          hideOnFilter
          hideOnModal
          isKey
          label="Id"
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="logo"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('shop.logo')}
          tableProps={{
            sort: true,
            formatter: (val) => val && <img className="shop-logo-table" src={val} alt={val} />,
          }}
        />
        <GenericBackofficeElement
          field="name"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('shop.name')}
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="disabled"
          required
          showOnEdit={false}
          label={translate('shop.disabled')}
          icon="trash-outline"
          tableProps={{
            formatter: (val) => <Icon icon={`fas fa-${val ? 'check' : 'times'}`} />,
            sort: true,
          }}
        />
      </GenericBackoffice>
      {selectedShop?.id && showSure && (
        <SureModal
          {...forms.modals.sure}
          header={translate(`shop.${sureMode}`)}
          loading={loading || loadingModal}
          onAccept={() => {
            let func = null;
            if (SURE_MODES.RESTORE === sureMode) {
              func = restoreShop;
            }

            if (func) {
              setLoadingModal(true);

              func(selectedShop.id)
                .then(() => {
                  setLoadingModal(false);
                  setShowSure(false);
                  getBackofficeShops();
                  showSuccessToast(translate(`shop.${sureMode}Ok`));
                })
                .catch(() => {
                  setLoadingModal(false);
                  showErrorToast(translate(`shop.${sureMode}Ko`));
                });
            }
          }}
          onHide={hideSureModal}
          show={showSure}
          sureText={translate('common.sureText')}
        >
          {modalContent(selectedShop)}
        </SureModal>
      )}
    </Page>
  );
};

export default Shops;
