import React, { useState, useEffect } from 'react';

import Page from '../../../components/base/Page';
import {
  GenericBackoffice, GenericBackofficeElement, Input
} from '../../../components/base-comps';

import { getAllDefaultCategories, deleteDefaultCategory } from '../../../actions/defaultCategory';

import forms from '../../../utils/forms';
import { BACKOFFICE_DEFAULT_CATEGORIES } from '../../../utils/pagesUrls';
import { translate } from '../../../utils/translate/translator';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../utils/toasts';

import './index.scss';

const DefaultCategories = () => {
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allDefaultCategories, setAllDefaultCategories] = useState();

  const getBackofficeDefaultCategories = () => {
    setLoading(true);
    getAllDefaultCategories()
      .then(({ items }) => {
        setAllDefaultCategories(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBackofficeDefaultCategories();
  }, []);

  const onDelete = (item, callback) => {
    setLoadingModal(true);

    deleteDefaultCategory(item.id)
      .then(() => {
        showSuccessToast(translate('defaultCategory.deletedOk'));
        getBackofficeDefaultCategories();
        setLoadingModal(false);
        callback();
      })
      .catch(() => {
        showErrorToast(translate('defaultCategory.deletedKo'));
        setLoadingModal(false);
      });
  };

  const modalContent = (defaultCategory) => (
    <>
      <p>
        {`${translate('defaultCategory.deleteSure')} :`}
      </p>
      <Input
        disabled
        value={defaultCategory.id}
        placeholder="Id"
      />
      <Input
        disabled
        value={defaultCategory.name}
        placeholder={translate('defaultCategory.name')}
      />
    </>
  );

  return (
    <Page id="backoffice-defaultCategory-container" grayBackground withSidebar>
      <GenericBackoffice
        {...forms.backoffice.table}
        previousLoad
        tableProps={{ ...forms.backoffice.table.tableProps, csvFileName: translate('backoffice.defaultCategories') }}
        translations={{ ...forms.backoffice.table.translations, noItems: translate('common.noItems') }}
        addButtonProps={{ ...forms.backoffice.table.addButtonProps, to: `${BACKOFFICE_DEFAULT_CATEGORIES}/new` }}
        entity={translate('backoffice.defaultCategory')}
        loading={loading}
        loadingModal={loadingModal}
        onDelete={onDelete}
        objects={allDefaultCategories}
        showFilters={false}
        deleteElementFormatter={modalContent}
        showDelete
        showEdit={false}
        title={translate('backoffice.defaultCategories')}
      >
        <GenericBackofficeElement
          field="id"
          hideOnFilter
          hideOnModal
          isKey
          label="Id"
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="image"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('defaultCategory.image')}
          tableProps={{
            sort: true,
            formatter: (val) => val && <img className="shop-logo-table" src={val} alt={val} />,
          }}
        />
        <GenericBackofficeElement
          field="name"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('defaultCategory.name')}
          tableProps={{
            sort: true,
          }}
        />
      </GenericBackoffice>
    </Page>
  );
};

export default DefaultCategories;
