import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input, { INPUT_TYPES } from '../Input/Input';

/**
 * This selector needs the Input component
 * Also needs to add the library on the html file
 *
 * <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key={YOUR_API_KEY}&libraries=places"></script>
 */
const Address = ({
  className, disabled, error, errorText, icon, id, maxLength, onChange, placeholder, value,
}) => {
  const [stateValue, setStateValue] = useState(value);

  const filterArray = (array, comparable) => 0 < array.filter((elem) => elem === comparable).length;

  const setComponents = (components) => {
    if (0 < components.length) {
      let number = components.filter((comp) => filterArray(comp.types, 'street_number'));
      let street = components.filter((comp) => filterArray(comp.types, 'route'));
      let city = components.filter((comp) => filterArray(comp.types, 'locality'));
      let country = components.filter((comp) => filterArray(comp.types, 'country'));
      let postalCode = components.filter((comp) => filterArray(comp.types, 'postal_code'));

      if (number[0]) {
        number = number[0].long_name;
      } else {
        number = 'S/N';
      }
      if (street[0]) {
        street = street[0].long_name;
      } else {
        street = 'N/A';
      }
      if (city[0]) {
        city = city[0].long_name;
      } else {
        let newLoc = components.filter((comp) => filterArray(comp.types, 'postal_town'));
        if (newLoc[0]) {
          city = newLoc[0].long_name;
        } else {
          newLoc = components.filter((comp) => filterArray(comp.types, 'administrative_area_level_1'));
          if (newLoc[0]) {
            city = newLoc[0].long_name;
          }
        }
      }
      if (country[0]) {
        country = country[0].short_name;
      } else {
        country = null;
      }
      if (postalCode[0]) {
        postalCode = postalCode[0].long_name;
      } else {
        postalCode = 'N/A';
      }
      return {
        number,
        street,
        city,
        country,
        postalCode,
      };
    }
    return null;
  };

  const onChangeFunc = (val) => {
    setStateValue(val);
  };

  useEffect(() => {
    if ('function' === typeof onChange && value !== stateValue) {
      onChange(stateValue);
    }
  }, [stateValue]);

  const createMap = () => {
    // initialize the autocomplete functionality using the #addressForm input box
    const inputNode = document.getElementById(id);
    const autoComplete = new window.google.maps.places.Autocomplete(inputNode);

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      const { location } = place.geometry;
      if ('function' === typeof onChange) {
        onChangeFunc({
          components: setComponents(place.address_components),
          location: [location.lng(), location.lat()],
          fullAddress: place.formatted_address,
        });
      }
    });
  };

  const getLatitudeLongitude = () => {
    // Initialize the Geocoder
    const geocoder = new window.google.maps.Geocoder();

    if (geocoder) {
      geocoder.geocode({ address: value || 'a' }, () => createMap());
    }
  };

  useEffect(() => {
    getLatitudeLongitude();
  }, []);

  useEffect(() => {
    const comparable = value && value.fullAddress ? value.fullAddress : value;
    if (comparable !== stateValue) {
      setStateValue(value);
    }
  }, [value]);

  return (
    <Input
      autocomplete={false}
      id={id}
      className={className}
      disabled={disabled}
      error={error}
      errorText={errorText}
      icon={icon}
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={onChangeFunc}
      type={INPUT_TYPES.text}
      value={(stateValue && stateValue.fullAddress) || stateValue || ''}
    />
  );
};

Address.defaultProps = {
  className: null,
  disabled: false,
  error: false,
  errorText: null,
  icon: 'fas fa-map-marker-alt',
  id: 'addressForm',
  maxLength: null,
  onChange: () => true,
  placeholder: null,
  value: null,
};

Address.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.any,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

export default Address;
