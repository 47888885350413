import React from 'react';

import Loading from '../../components/base/Loading';

import { translate } from '../translate/translator';

const sure = {
  sureText: translate('common.sureText'),
  yesText: translate('common.yes'),
  noText: translate('common.no'),
  loadingFormatter: () => <Loading />,
};

export default {
  sure,
};
