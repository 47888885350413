import PropTypes from 'prop-types';

const TableColumn = (props) => props;

TableColumn.defaultProps = {
  cellClassName: null,
  csvFormatter: null,
  csvHeader: null,
  exportCsv: true,
  filterFormatted: false,
  filter: false,
  formatter: null,
  headerClassName: null,
  hidden: false,
  isKey: false,
  sort: false,
  width: null,
};

TableColumn.propTypes = {
  cellClassName: PropTypes.string,
  csvFormatter: PropTypes.func,
  csvHeader: PropTypes.string,
  exportCsv: PropTypes.bool,
  filterFormatted: PropTypes.bool,
  filter: PropTypes.bool,
  field: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  hidden: PropTypes.bool,
  isKey: PropTypes.bool,
  formatter: PropTypes.func,
  sort: PropTypes.bool,
  width: PropTypes.number,
};

export default TableColumn;
