import backoffice from './es/backoffice.json';
import common from './es/common.json';
import shop from './es/shop.json';
import defaultCategory from './es/defaultCategory.json';
import days from './es/days.json';
import navbar from './es/navbar.json';
import validateProduct from './es/validateProduct.json';

const translations = {
  backoffice,
  common,
  defaultCategory,
  days,
  shop,
  navbar,
  validateProduct,
};

export default translations;
