import { translate } from './translate/translator';

export const LOCAL_STORAGE_CONST = {
  EXPIRY_DATE: 'expiryDate',
  LANGUAGE: 'currentLang',
  REFRESH_TOKEN: 'refreshToken',
  SPORT: 'sport',
  TOKEN: 'token',
  USER_ID: 'userId',
};

export const NEW = 'new';

export const ROLES = {
  SUPERADMIN: 'Superadmin',
  ADMIN: 'Admin',
  USER: 'User',
};

export const SESSION_STORAGE_CONST = {
  EXPIRY_DATE: 'expiryDate',
  REFRESH_TOKEN: 'refreshToken',
  TOKEN: 'token',
  USER_ID: 'userId',
};

export const SURE_MODES = {
  RESTORE: 'restore',
  VALIDATE: 'validate',
};

export const WRONG_PARAMS = new Error('Wrong parameters');

// ------------------------------------
// DATES
// ------------------------------------
export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_TO_SHOW = 'MM/DD/YYYY';
export const HOUR_FORMAT = 'HH:mm';
export const DATE_FORMAT_FULL = `${DATE_FORMAT} ${HOUR_FORMAT}`;
export const WEEKDAY_FORMAT = 'dddd';

// ------------------------------------
// SCREEN
// ------------------------------------
export const SCREEN_SIZE_SM = 576;
export const SCREEN_SIZE_MD = 768;
export const SCREEN_SIZE_LG = 992;
export const SCREEN_SIZE_XL = 1200;

// Days
export const DAY_MONDAY = 'monday';
export const DAY_TUESDAY = 'tuesday';
export const DAY_WEDNESDAY = 'wednesday';
export const DAY_THURSDAY = 'thursday';
export const DAY_FRIDAY = 'friday';
export const DAY_SATURDAY = 'saturday';
export const DAY_SUNDAY = 'sunday';
export const DAYS_ARRAY = [DAY_MONDAY, DAY_TUESDAY, DAY_WEDNESDAY, DAY_THURSDAY, DAY_FRIDAY, DAY_SATURDAY, DAY_SUNDAY];
export const DAYS_OPTIONS_ARRAY = [
  { label: translate('days.name.monday'), value: 'monday' },
  { label: translate('days.name.tuesday'), value: 'tuesday' },
  { label: translate('days.name.wednesday'), value: 'wednesday' },
  { label: translate('days.name.thursday'), value: 'thursday' },
  { label: translate('days.name.friday'), value: 'friday' },
  { label: translate('days.name.saturday'), value: 'saturday' },
  { label: translate('days.name.sunday'), value: 'sunday' },
];

export const weekdayFromNumber = {
  0: DAY_SUNDAY,
  1: DAY_MONDAY,
  2: DAY_TUESDAY,
  3: DAY_WEDNESDAY,
  4: DAY_THURSDAY,
  5: DAY_FRIDAY,
  6: DAY_SATURDAY,
};
