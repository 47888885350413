import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Input from '../Input/Input';

import './index.scss';

const getHexColor = (color) => {
  if (color && color.startsWith('#')) {
    return color;
  }
  return `#${color}`;
};

const Colorpicker = ({
  className, disabled, error, icon, onChange, placeholder, value, withInput,
}) => {
  const [stValue, setStValue] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setStValue(value);
  }, [value]);

  return (
    <div
      className={`wiset-colorpicker${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}${
        withInput ? ' with-input' : ''
      }${error ? ' error' : ''}${icon ? ' with-icon' : ''}`}
    >
      <div className={`${focused ? ' primary-border' : ''}`}>
        {!withInput && icon && <Icon className={`${focused ? ' primary-color' : ''}`} icon={icon} />}
        {!withInput && placeholder ? <p className="wiset-colorpicker-placeholder">{placeholder}</p> : null}
        {withInput ? (
          <Input
            disabled={disabled}
            error={error}
            icon={icon}
            maxLength={7}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={(col) => {
              const val = getHexColor(col);
              setStValue(val);
              if (onChange) {
                onChange(val);
              }
            }}
            placeholder={placeholder}
            value={stValue}
          />
        ) : null}
        <input
          className="color-input"
          disabled={disabled}
          type="color"
          onChange={(e) => {
            const val = getHexColor(e.target.value);
            setStValue(val);
            if (onChange) {
              onChange(val);
            }
          }}
          value={stValue}
        />
      </div>
    </div>
  );
};

Colorpicker.defaultProps = {
  className: null,
  disabled: false,
  error: false,
  icon: 'fas fa-palette',
  onChange: null,
  placeholder: null,
  value: null,
  withInput: true,
};

Colorpicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.any,
  error: PropTypes.bool,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  withInput: PropTypes.bool,
};

export default Colorpicker;
