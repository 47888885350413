import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GENERIC_TYPES from '../GenericTypes';
import Modal from '../../Modal/Modal';
import Button, { BUTTON_TYPES } from '../../Button/Button';
import Address from '../../Address/Address';
import Checkbox from '../../Checkbox/Checkbox';
import Datepicker from '../../Datepicker/Datepicker';
import Input from '../../Input/Input';
import SelectButton from '../../SelectButton/SelectButton';
import Selector from '../../Selector/Selector';
import Dropzone from '../../Dropzone/Dropzone';

import './index.scss';

const GenericModal = ({
  customValidator,
  editModalProps,
  elements,
  header,
  isEditing,
  loading,
  loadingFormatter,
  onHide,
  onSave,
  selectedItem,
  show,
  translations,
}) => {
  const [errors, setErrors] = useState({});
  const [newElement, setNewElement] = useState({});

  const onSaveElement = () => {
    let err = { hasErrors: false };

    if ('function' === typeof customValidator) {
      err = customValidator(newElement);
    } else {
      elements.forEach(({ props: { field, modalField, required } }) => {
        const fieldUsed = modalField || field;
        if (required && !newElement[fieldUsed]) {
          err.hasErrors = true;
          err[fieldUsed] = true;
        }
      });
    }

    setErrors(err);

    if (!err.hasErrors) {
      onSave(newElement);
    }
  };

  const closeModal = () => {
    onHide();
    setNewElement({});
  };

  useEffect(() => {
    if (selectedItem !== newElement) {
      setNewElement(selectedItem || {});
    }
  }, [selectedItem]);

  return (
    <Modal
      className="generic-backoffice-modal"
      header={header}
      footer={
        loading ? (
          <>{loadingFormatter ? loadingFormatter() : <div>Loading...</div>}</>
        ) : (
          <>
            <Button iconLeft="fas fa-check" onClick={onSaveElement} text={translations && translations.accept} />
            <Button
              iconLeft="fas fa-times"
              inverted
              onClick={closeModal}
              text={translations && translations.cancel}
              type={BUTTON_TYPES.gray}
            />
          </>
        )
      }
      {...editModalProps}
      onHide={closeModal}
      show={show}
    >
      {elements &&
        0 < elements.length &&
        elements.map(({ props: elem }, idx) => {
          const {
            disableOnCreate,
            disableOnEdit,
            field,
            modalField,
            modalFormatter,
            modalProps,
            modalType,
            label,
            icon,
            required,
            showOnCreate,
            showOnEdit,
          } = elem;
          const fieldUsed = modalField || field;
          if ((!showOnCreate && !isEditing) || (!showOnEdit && isEditing)) {
            return null;
          }

          const elementValue = newElement[fieldUsed];
          if (modalFormatter && 'function' === typeof modalFormatter) {
            return modalFormatter(elementValue, newElement);
          }
          if (modalType === GENERIC_TYPES.address) {
            return (
              <Address
                {...modalProps}
                icon={icon}
                key={fieldUsed || idx}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue}
                placeholder={`${label}${required ? '*' : ''}`}
              />
            );
          }
          if (modalType === GENERIC_TYPES.checkbox) {
            return (
              <Checkbox
                {...modalProps}
                key={fieldUsed || idx}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                text={label}
                value={elementValue}
              />
            );
          }
          if (modalType === GENERIC_TYPES.datepicker) {
            return (
              <Datepicker
                {...modalProps}
                icon={icon}
                key={fieldUsed || idx}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue && !(elementValue instanceof Date) ? new Date(elementValue) : elementValue}
                placeholder={`${label}${required ? '*' : ''}`}
              />
            );
          }
          if (modalType === GENERIC_TYPES.input) {
            return (
              <Input
                {...modalProps}
                icon={icon}
                key={fieldUsed || idx}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue}
                placeholder={`${label}${required ? '*' : ''}`}
              />
            );
          }
          if (modalType === GENERIC_TYPES.json) {
            return (
              <div className="json-field" key={fieldUsed}>
                <div className="json-text">
                  {icon && <i className={icon} aria-hidden="true" />}
                  <p>{`${label}${required ? '*' : ''}`}</p>
                </div>
                <div className="json">
                  <pre>{JSON.stringify(selectedItem && selectedItem[fieldUsed], null, 2)}</pre>
                </div>
              </div>
            );
          }
          if (modalType === GENERIC_TYPES.selectButton) {
            return (
              <SelectButton
                {...modalProps}
                key={fieldUsed || idx}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue}
                placeholder={`${label}${required ? '*' : ''}`}
              />
            );
          }
          if (modalType === GENERIC_TYPES.selector) {
            return (
              <Selector
                {...modalProps}
                key={fieldUsed || idx}
                icon={icon}
                error={errors[fieldUsed]}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue}
                placeholder={`${label}${required ? '*' : ''}`}
              />
            );
          }
          if (modalType === GENERIC_TYPES.dropzone) {
            return (
              <Dropzone
                {...modalProps}
                key={fieldUsed || idx}
                icon={icon}
                disabled={(disableOnCreate && !isEditing) || (disableOnEdit && isEditing) || loading}
                onFilesChange={(val) => setNewElement({ ...newElement, [fieldUsed]: val })}
                value={elementValue}
                text={`${label}${required ? '*' : ''}`}
              />
            );
          }
          return fieldUsed;
        })}
    </Modal>
  );
};

GenericModal.defaultProps = {
  customValidator: null,
  editModalProps: {},
  elements: [],
  header: null,
  isEditing: false,
  loading: false,
  loadingFormatter: null,
  selectedItem: {},
  show: false,
  translations: {},
};

GenericModal.propTypes = {
  customValidator: PropTypes.func,
  editModalProps: PropTypes.object,
  elements: PropTypes.array,
  header: PropTypes.string,
  isEditing: PropTypes.bool,
  loading: PropTypes.bool,
  loadingFormatter: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  show: PropTypes.bool,
  translations: PropTypes.object,
};

export default GenericModal;
