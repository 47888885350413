import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './index.scss';
import { BUTTON_TYPES } from '../Button/Button';

const Checkbox = ({
  backgroundButtonColor,
  className,
  disabled,
  enableHalfChecked,
  onClick,
  switchMode,
  text,
  value,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <button
      disabled={disabled}
      type="button"
      className={`wiset-checkbox-container${className ? ` ${className}` : ''}${
        switchMode ? ' wiset-switch-container' : ''
      }`}
      onClick={() => {
        if (!disabled) {
          let newVal = !checked;

          if (enableHalfChecked) {
            if (false === checked) {
              newVal = null;
            } else if (null === checked || undefined === checked) {
              newVal = true;
            } else {
              newVal = false;
            }
          }

          if (onClick) {
            onClick(newVal);
          }
          setChecked(newVal);
        }
      }}
    >
      <div
        className={`wiset-checkbox ${
          checked || null === checked || undefined === checked ? `${backgroundButtonColor}-bg` : 'inactive'
        }${switchMode ? ' wiset-switch' : ''}`}
      >
        {!switchMode && checked && <Icon icon="fas fa-check fadein" />}
        {!switchMode && enableHalfChecked && (null === checked || undefined === checked) && <Icon icon="fa fa-minus" />}
        {switchMode && <div className={`wiset-switch-inner ${checked ? `${backgroundButtonColor}-bg` : 'inactive'}`} />}
      </div>
      {text && <span className={switchMode && checked ? 'switch-text' : ''}>{text}</span>}
    </button>
  );
};

Checkbox.defaultProps = {
  backgroundButtonColor: BUTTON_TYPES.primary,
  className: null,
  disabled: false,
  enableHalfChecked: false,
  onClick: null,
  switchMode: false,
  text: null,
  value: null,
};

Checkbox.propTypes = {
  backgroundButtonColor: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  className: PropTypes.string,
  disabled: PropTypes.any,
  enableHalfChecked: PropTypes.any,
  onClick: PropTypes.func,
  switchMode: PropTypes.any,
  text: PropTypes.string,
  value: PropTypes.any,
};

export default Checkbox;
