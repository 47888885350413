import React, { useState, useEffect, useCallback } from 'react';

import Page from '../../../components/base/Page';
import {
  GenericBackoffice, GenericBackofficeElement
} from '../../../components/base-comps';

import { getProducts } from '../../../actions/product';

import forms from '../../../utils/forms';
import { translate } from '../../../utils/translate/translator';
import { BACKOFFICE_PRODUCT } from '../../../utils/pagesUrls';

import './index.scss';

const ProductsPage = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(null);

  const getBackofficePendingProducts = (next) => {
    getProducts(next)
      .then(({ products: dbProducts, nextToken }) => {
        setProducts([...products, ...dbProducts]);
        setPagination(nextToken);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBackofficePendingProducts();
  }, []);

  const onPageChange = useCallback(() => {
    if (pagination) {
      setLoading(true);
      getBackofficePendingProducts(pagination);
    }
  }, [pagination]);

  const getExtraActions = (product) => {
    const res = [
      {
        text: translate('common.seeMore'),
        icon: 'fas fa-eye',
        url: `${BACKOFFICE_PRODUCT}/${product.id}`
      },
    ];

    return res;
  };

  return (
    <Page id="products-page" grayBackground withSidebar>
      <GenericBackoffice
        {...forms.backoffice.table}
        previousLoad
        tableProps={{ ...forms.backoffice.table.tableProps, csvFileName: translate('backoffice.products'), paginatorProps: { onNext: onPageChange } }}
        translations={{ ...forms.backoffice.table.translations, noItems: translate('common.noItems') }}
        entity={translate('backoffice.products')}
        extraActions={getExtraActions}
        loading={loading}
        objects={products}
        showFilters={false}
        showDelete={false}
        showAdd={false}
        showEdit={false}
        title={translate('backoffice.products')}
      >
        <GenericBackofficeElement
          field="id"
          hideOnFilter
          hideOnModal
          isKey
          label="Id"
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="mainImage"
          hideOnFilter
          hideOnModal
          label={translate('common.image')}
          tableProps={{
            formatter: (val) => val && <img className="product-logo-table" src={val} alt={val} />,
          }}
        />
        <GenericBackofficeElement
          field="name"
          hideOnFilter
          hideOnModal
          label={translate('common.name')}
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="categories"
          hideOnFilter
          hideOnModal
          label={translate('common.categories')}
          tableFormatter={(categories) => categories ? `${categories.items.length || 0}` : '0'}
          tableProps={{
            sort: true,
          }}
        />
      </GenericBackoffice>
    </Page>
  );
};

export default ProductsPage;
