const format = (element) =>
  element
    ? {
        ...element,
        mainImage: element?.images?.length
          ? element.images.find((imgs) => imgs.isMain)?.url
          : null,
      }
    : {};

const formatArray = (array) => (array?.length ? array.map(format) : []);

export default {
  format,
  formatArray,
};
