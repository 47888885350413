import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Page from '../../../components/base/Page';
import Card from '../../../components/backoffice/Card';

import { sortArray } from '../../../utils/functions';
import { userIsSuperadmin } from '../../../utils/roles';
import { BACKOFFICE_SHOPS, BACKOFFICE_VALIDATE, BACKOFFICE_DEFAULT_CATEGORIES, BACKOFFICE_PRODUCTS } from '../../../utils/pagesUrls';
import { translate } from '../../../utils/translate/translator';

import Logo from '../../../assets/images/logo.png';

import './index.scss';

const FULL_CARDS = [];

const SUPERADMIN_CARDS = [
  {
    title: translate('backoffice.validateProducts'),
    image: Logo,
    url: BACKOFFICE_VALIDATE,
  },
  {
    title: translate('backoffice.defaultCategories'),
    image: Logo,
    url: BACKOFFICE_DEFAULT_CATEGORIES,
  },
  {
    title: translate('backoffice.products'),
    image: Logo,
    url: BACKOFFICE_PRODUCTS,
  },
];

const CARDS = [
  {
    title: translate('backoffice.shops'),
    image: Logo,
    url: BACKOFFICE_SHOPS,
  },
];

const Menu = () => {
  const user = useSelector((state) => state.user);
  const [cards] = useState(sortArray(userIsSuperadmin(user?.loggedUserRole) ? [...CARDS, ...SUPERADMIN_CARDS] : CARDS, 'title'));

  return (
    <Page id="backoffice-container" grayBackground innerPadding={false} withSidebar>
      <h1>Backoffice</h1>
      {
      !!FULL_CARDS?.length && (
        <div className="cards-full">
          {
            FULL_CARDS.map((card) => (
              <Card
                key={card.url}
                title={card.title}
                image={card.image}
                url={card.url}
              />
            ))
          }
        </div>
      )
    }
      <div className="cards-container">
        {
        cards.map((page) => (
          <Card
            key={page.url}
            title={page.title}
            image={page.image}
            url={page.url}
          />
        ))
      }
      </div>
    </Page>
  );
};

export default Menu;
