import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import './index.scss';

const TimeSelector = ({
  hour, onHourChange, showSeconds, stepHour, stepMinute, stepSecond,
}) => {
  const [mounted, setMounted] = useState(false);
  const [time, setTime] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const changeTime = (param, add) => {
    const newTime = { ...time };
    const maxTime = 'hours' === param ? 23 : 59;
    let elem = Number(newTime[param]);
    let step = stepHour || 1;

    if ('minutes' === param) {
      step = stepMinute || 1;
    }
    if ('seconds' === param) {
      step = stepSecond || 1;
    }

    elem = add ? elem + step : elem - step;

    if (maxTime < elem) {
      elem = maxTime + 1 - elem;
    } else if (0 > elem) {
      elem = maxTime + 1 + elem;
    }

    newTime[param] = `${10 > elem ? '0' : ''}${elem}`;

    setTime(newTime);
  };

  useEffect(() => {
    if (mounted) {
      const currentHour = `${time.hours}:${time.minutes}${showSeconds ? `:${time.seconds}` : ''}`;

      if (currentHour !== hour) {
        onHourChange(currentHour);
      }
    } else {
      setMounted(true);
    }
  }, [time]);

  useEffect(() => {
    const currentHour = `${time.hours}:${time.minutes}${showSeconds ? `:${time.seconds}` : ''}`;

    if (hour && currentHour !== hour) {
      const [hours, minutes, seconds] = hour.split(':');
      setTime({ hours, minutes, seconds: seconds || '00' });
    }
  }, [hour]);

  return (
    <div className="wiset-time-selector">
      <div>
        <Button disableType iconLeft="fas fa-chevron-up" onClick={() => changeTime('hours', true)} />
        <span>{time.hours}</span>
        <Button disableType iconLeft="fas fa-chevron-down" onClick={() => changeTime('hours', false)} />
      </div>
      <span className="hour-separator">:</span>
      <div>
        <Button disableType iconLeft="fas fa-chevron-up" onClick={() => changeTime('minutes', true)} />
        <span>{time.minutes}</span>
        <Button disableType iconLeft="fas fa-chevron-down" onClick={() => changeTime('minutes', false)} />
      </div>
      {showSeconds && <span className="hour-separator">:</span>}
      {showSeconds && (
        <div>
          <Button disableType iconLeft="fas fa-chevron-up" onClick={() => changeTime('seconds', true)} />
          <span>{time.seconds}</span>
          <Button disableType iconLeft="fas fa-chevron-down" onClick={() => changeTime('seconds', false)} />
        </div>
      )}
    </div>
  );
};

TimeSelector.defaultProps = {
  hour: null,
  showSeconds: false,
  stepHour: 1,
  stepMinute: 30,
  stepSecond: 1,
};

TimeSelector.propTypes = {
  hour: PropTypes.string,
  onHourChange: PropTypes.func.isRequired,
  showSeconds: PropTypes.bool,
  stepHour: PropTypes.number,
  stepMinute: PropTypes.number,
  stepSecond: PropTypes.number,
};

export default TimeSelector;
