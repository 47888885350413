const listDefaultCategorys = /* GraphQL */ `
  query ListDefaultCategorys(
    $filter: ModelDefaultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefaultCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        translation {
          es
          en
        }
        products {
          nextToken
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

const getDefaultCategory = /* GraphQL */ `
  query GetDefaultCategory($id: ID!) {
    getDefaultCategory(id: $id) {
      id
      name
      image
      translation {
        es
        en
      }
      products {
        items {
          id
          defaultCategoryID
          ProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      createdAt
    }
  }
`;

export default {
  listDefaultCategorys,
  getDefaultCategory,
};
