import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import GENERIC_TYPES from '../GenericTypes';
import Button, { BUTTON_TYPES } from '../../Button/Button';
import Address from '../../Address/Address';
import Checkbox from '../../Checkbox/Checkbox';
import Datepicker from '../../Datepicker/Datepicker';
import Input from '../../Input/Input';
import SelectButton from '../../SelectButton/SelectButton';
import Selector from '../../Selector/Selector';

import './index.scss';

const getDatesValue = (value) => {
  if (value && value.length) {
    return value.map((date) => new Date(date));
  }
  if (value && !(value instanceof Date)) {
    return new Date(value);
  }
  return value;
};

const GenericFilters = ({
  clearButtonProps,
  color,
  filters,
  filterButtonProps,
  onClear,
  onSearch,
  secondaryColor,
  translations,
  withShadow,
}) => {
  const [collapsableFilters, setCollapsableFilters] = useState(1200 >= window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [mountedValues, setMountedValues] = useState(false);
  const [formVals, setFormVals] = useState({});

  const handleResize = () => {
    const { innerWidth } = window;

    if (1200 < innerWidth) {
      setCollapsableFilters(false);
    } else if (1200 >= innerWidth) {
      setCollapsableFilters(true);
    }
  };

  useEffect(() => {
    if (filters && filters.length && !mountedValues) {
      const newVals = {};
      filters.forEach(
        ({ props: { filterField, field, filterDefault } }) => (newVals[filterField || field] = filterDefault),
      );
      setFormVals(newVals);
      setMountedValues(true);
    }
  }, [filters]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      window.addEventListener('resize', () => handleResize());
    }

    return window.removeEventListener('resize', () => handleResize());
  }, []);

  return (
    <div className="generic-backoffice-filters">
      <form className={`white-bg${withShadow ? ' backoffice-shadow' : ''}${collapsed ? ' filters-collapsed' : ''}`}>
        {collapsableFilters ? (
          <Button
            className="expand-btn"
            iconRight="fas fa-chevron-up"
            onClick={() => setCollapsed(!collapsed)}
            text={translations && translations.filter}
            type={color}
          />
        ) : (
          <h3 className={`white-color ${color}-bg`}>{translations && translations.filters}</h3>
        )}
        {filters &&
          0 < filters.length &&
          filters.map(({ props: elem }, idx) => {
            const {
              filterField, field, filterFormatter, filterProps, filterType, label, icon,
            } = elem;
            const fieldUsed = filterField || field;
            const filterValue = formVals[fieldUsed];
            if (filterFormatter && 'function' === typeof filterFormatter) {
              return <div key={fieldUsed || idx}>{filterFormatter(elem, idx)}</div>;
            }
            if (filterType === GENERIC_TYPES.address) {
              return (
                <Address
                  {...filterProps}
                  icon={icon}
                  key={fieldUsed || idx}
                  onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  value={filterValue}
                  placeholder={label}
                />
              );
            }
            if (filterType === GENERIC_TYPES.checkbox) {
              return (
                <Checkbox
                  {...filterProps}
                  key={fieldUsed || idx}
                  onClick={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  text={label}
                  value={filterValue}
                />
              );
            }
            if (filterType === GENERIC_TYPES.datepicker) {
              return (
                <Datepicker
                  {...filterProps}
                  icon={icon}
                  key={fieldUsed || idx}
                  onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  value={getDatesValue(filterValue)}
                  placeholder={label}
                />
              );
            }
            if (filterType === GENERIC_TYPES.input) {
              return (
                <Input
                  {...filterProps}
                  icon={icon}
                  key={fieldUsed || idx}
                  onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  value={filterValue}
                  placeholder={label}
                />
              );
            }
            if (filterType === GENERIC_TYPES.minMax) {
              return (
                <div className="generic-min-max" key={fieldUsed || idx}>
                  <Input
                    {...filterProps}
                    icon={icon}
                    onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: { ...formVals[fieldUsed], min: val } })}
                    value={filterValue && filterValue.min}
                    placeholder={`Min. ${label}`}
                  />
                  <Input
                    {...filterProps}
                    icon={icon}
                    onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: { ...formVals[fieldUsed], max: val } })}
                    value={filterValue && filterValue.max}
                    placeholder={`Max. ${label}`}
                  />
                </div>
              );
            }
            if (filterType === GENERIC_TYPES.selectButton) {
              return (
                <SelectButton
                  {...filterProps}
                  key={fieldUsed || idx}
                  onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  value={filterValue}
                  placeholder={label}
                />
              );
            }
            if (filterType === GENERIC_TYPES.selector) {
              return (
                <Selector
                  {...filterProps}
                  key={fieldUsed || idx}
                  icon={icon}
                  onChange={(val) => setFormVals({ ...formVals, [fieldUsed]: val })}
                  value={filterValue}
                  placeholder={label}
                />
              );
            }
            return null;
          })}
        <div className={`generic-backoffice-filter-buttons ${secondaryColor}-bg`}>
          <Button
            text={translations && translations.clear}
            {...clearButtonProps}
            onClick={() => {
              setFormVals({});
              if (onClear && 'function' === typeof onClear) {
                onClear();
              }
            }}
          />
          <Button
            text={translations && translations.filter}
            type={color}
            {...filterButtonProps}
            onClick={() => onSearch(formVals)}
          />
        </div>
      </form>
    </div>
  );
};

GenericFilters.defaultProps = {
  clearButtonProps: {
    inverted: true,
    iconLeft: 'fas fa-times',
    type: BUTTON_TYPES.gray,
  },
  color: BUTTON_TYPES.primary,
  filterButtonProps: {
    iconLeft: 'fas fa-search',
  },
  onClear: false,
  secondaryColor: BUTTON_TYPES.secondaryLighter,
  translations: {},
  withShadow: true,
};

GenericFilters.propTypes = {
  clearButtonProps: PropTypes.object,
  color: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  filters: PropTypes.array.isRequired,
  filterButtonProps: PropTypes.object,
  onClear: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  secondaryColor: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  translations: PropTypes.object,
  withShadow: PropTypes.bool,
};

export default GenericFilters;
