import React, { useState, useEffect, useCallback } from 'react';

import Page from '../../../components/base/Page';
import {
  GenericBackoffice, GenericBackofficeElement
} from '../../../components/base-comps';

import { getPendingProducts } from '../../../actions/product';

import forms from '../../../utils/forms';
import { translate } from '../../../utils/translate/translator';
import { BACKOFFICE_VALIDATE } from '../../../utils/pagesUrls';

import './index.scss';

const ValidateProductsPage = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(null);

  const getBackofficePendingProducts = (next) => {
    getPendingProducts(next)
      .then(({ products: dbProducts, nextToken }) => {
        setProducts([...products, ...dbProducts]);
        setPagination(nextToken);

        if (!dbProducts.length && nextToken) {
          getBackofficePendingProducts(nextToken);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBackofficePendingProducts();
  }, []);

  const onPageChange = useCallback(() => {
    if (pagination) {
      setLoading(true);
      getBackofficePendingProducts(pagination);
    }
  }, [pagination]);

  const getExtraActions = (product) => {
    const res = [
      {
        text: translate('common.validate'),
        icon: 'fas fa-eye',
        url: `${BACKOFFICE_VALIDATE}/${product.id}`
      },
    ];

    return res;
  };

  return (
    <Page id="validate-products-page" grayBackground withSidebar>
      <GenericBackoffice
        {...forms.backoffice.table}
        previousLoad
        tableProps={{ ...forms.backoffice.table.tableProps, csvFileName: translate('backoffice.validateProduct'), paginatorProps: { onNext: onPageChange } }}
        translations={{ ...forms.backoffice.table.translations, noItems: translate('common.noItems') }}
        entity={translate('backoffice.validateProduct')}
        extraActions={getExtraActions}
        loading={loading}
        objects={products}
        showFilters={false}
        showDelete={false}
        showAdd={false}
        showEdit={false}
        title={translate('backoffice.validateProducts')}
      >
        <GenericBackofficeElement
          field="id"
          hideOnFilter
          hideOnModal
          isKey
          label="Id"
          tableProps={{
            sort: true,
          }}
        />
        <GenericBackofficeElement
          field="mainImage"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('common.image')}
          tableProps={{
            formatter: (val) => val && <img className="product-logo-table" src={val} alt={val} />,
          }}
        />
        <GenericBackofficeElement
          field="name"
          hideOnFilter
          hideOnModal
          isKey
          label={translate('common.name')}
          tableProps={{
            sort: true,
          }}
        />
      </GenericBackoffice>
    </Page>
  );
};

export default ValidateProductsPage;
