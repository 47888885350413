import PropTypes from 'prop-types';
import GENERIC_TYPES from '../GenericTypes';

const GenericBackofficeElement = (props) => props;

GenericBackofficeElement.defaultProps = {
  disableOnCreate: false,
  disableOnEdit: false,
  filterDefault: null,
  filterField: null,
  filterFormatter: null,
  filterProps: {},
  filterType: null,
  hideOnFilter: false,
  hideOnModal: false,
  hideOnTable: false,
  icon: null,
  isKey: false,
  label: null,
  modalField: null,
  modalFormatter: null,
  modalProps: {},
  modalType: null,
  required: false,
  showOnCreate: true,
  showOnEdit: true,
  tableField: null,
  tableFormatter: null,
  tableProps: {},
};

GenericBackofficeElement.propTypes = {
  disableOnCreate: PropTypes.bool,
  disableOnEdit: PropTypes.bool,
  field: PropTypes.string.isRequired,
  filterDefault: PropTypes.any,
  filterField: PropTypes.string,
  filterFormatter: PropTypes.func,
  filterProps: PropTypes.object,
  filterType: PropTypes.oneOf(Object.values(GENERIC_TYPES)),
  hideOnFilter: PropTypes.bool,
  hideOnModal: PropTypes.bool,
  hideOnTable: PropTypes.bool,
  icon: PropTypes.string,
  isKey: PropTypes.bool,
  label: PropTypes.string,
  modalField: PropTypes.string,
  modalFormatter: PropTypes.func,
  modalProps: PropTypes.object,
  modalType: PropTypes.oneOf(Object.values(GENERIC_TYPES)),
  required: PropTypes.bool,
  showOnCreate: PropTypes.bool,
  showOnEdit: PropTypes.bool,
  tableField: PropTypes.string,
  tableFormatter: PropTypes.func,
  tableProps: PropTypes.object,
};

export default GenericBackofficeElement;
