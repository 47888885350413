import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../../Button/Button';

const isRound = (buttonType) => 'round' === buttonType;

const Option = ({
  buttonClassName,
  buttonType,
  disabled,
  iconLeft,
  iconRight,
  inverted,
  label,
  setSelected,
  type,
  selected,
}) => (
  <>
    <Button
      disabled={disabled}
      className={`${selected ? 'selected' : 'not-selected'}${buttonClassName ? ` ${buttonClassName}` : ''}${
        isRound(buttonType) && selected ? ` ${type}-bg` : ''
      }`}
      iconLeft={iconLeft}
      iconRight={iconRight}
      inverted={(!selected && !inverted) || (selected && inverted)}
      onClick={setSelected}
      text={isRound(buttonType) ? null : label}
      type={type}
    />
    {isRound(buttonType) && <span>{label}</span>}
  </>
);

Option.defaultProps = {
  buttonClassName: null,
  buttonType: null,
  disabled: false,
  iconLeft: null,
  iconRight: null,
  inverted: false,
  label: null,
  selected: false,
  type: BUTTON_TYPES.primary,
};

Option.propTypes = {
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.string,
  disabled: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inverted: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};

export default Option;
