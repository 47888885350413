import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import helpers, {
  getPreviousMonth, getNextMonth, DATEPICKER_MODES, DATE_FORMATTER,
} from '../helpers';
import Button from '../../Button/Button';
import Selector from '../../Selector/Selector';
import './index.scss';
import TimeSelector from '../TimeSelector/TimeSelector';

const Calendar = ({
  className,
  date,
  disabledDates,
  disabledDays,
  hour,
  locale,
  maxDate,
  minDate,
  monthNavigator,
  onDayClick,
  onHourChange,
  selectionMode,
  selectOtherMonths,
  showSeconds,
  showTime,
  stepMinute,
  stepSecond,
  style,
  timeOnly,
  yearNavigator,
  yearRange,
}) => {
  const [days, setDays] = useState([]);
  const [currentMoment, setCurrentMoment] = useState(moment());
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [monthSelectorVal, setMonthSelectorVal] = useState(null);
  const [yearSelectorVal, setYearSelectorVal] = useState(null);
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const yearsArr = yearRange ? yearRange.split(':') : null;

    if (currentYear && !currentYear.label && yearsArr && 2 === yearsArr.length && yearsArr[0] < yearsArr[1]) {
      const newYears = [];
      let current = Number(yearsArr[0]);
      const end = Number(yearsArr[1]);

      while (current <= end) {
        newYears.push({ label: current, value: current });
        current = current + 1;
      }
      setYearSelectorVal({ label: currentYear, value: currentYear });
      setYears(newYears);
    }
  }, [yearRange, currentYear]);

  const today = moment();
  const getDays = (newMonth, newYear) => helpers(newMonth, newYear, locale.firstDayOfWeek);

  const updateCalendar = (newDate) => {
    const momentDate = newDate?.isValid && newDate.isValid() ? newDate : moment(newDate, DATE_FORMATTER);
    let newDays = days || [];
    let newMonth = null;
    let newYear = null;

    if (newDate && momentDate.isValid()) {
      newMonth = momentDate.get('month') + 1;
      newYear = momentDate.get('year');
    } else {
      newMonth = today.get('month') + 1;
      newYear = today.get('year');
    }
    newDays = getDays(newMonth, newYear);
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
    setCurrentMoment(`${newYear}/${newMonth}/01`);
    setMonthSelectorVal({ label: locale.monthNames[newMonth - 1], value: newMonth });
    setYearSelectorVal({ label: newYear, value: newYear });
    setDays(newDays);
  };

  useEffect(() => {
    if (selectionMode === DATEPICKER_MODES.range && date?.startValue?.moment) {
      const month = date.startValue.moment.get('month') + 1;
      const year = date.startValue.moment.get('year');

      if (month !== currentMonth || year !== currentMonth) {
        updateCalendar(moment(`${year}/${month}/01`));
      }
    } else if (selectionMode === DATEPICKER_MODES.multiple && date?.momentValue?.length) {
      const month = date.momentValue[0].get('month') + 1;
      const year = date.momentValue[0].get('year');

      if (month !== currentMonth || year !== currentMonth) {
        updateCalendar(moment(`${year}/${month}/01`));
      }
    } else if (selectionMode === DATEPICKER_MODES.single) {
      updateCalendar(date);
    } else {
      updateCalendar(today);
    }
  }, [date]);

  useEffect(() => {
    let newMax = null;
    let newMin = null;
    if ('object' === typeof maxDate && maxDate?.isValid()) {
      newMax = maxDate;
    } else if (maxDate) {
      const momentMax = maxDate && moment(maxDate, DATE_FORMATTER);
      if (momentMax.isValid()) {
        newMax = momentMax;
      }
    }

    if ('object' === typeof minDate && minDate?.isValid()) {
      newMin = minDate;
    } else if (minDate) {
      const momentMin = minDate && moment(minDate, DATE_FORMATTER);
      if (momentMin.isValid()) {
        newMin = momentMin;
      }
    }

    if (newMax?.isBefore(currentMoment)) {
      updateCalendar(newMax);
    } else if (newMin?.isAfter(currentMoment)) {
      updateCalendar(newMin);
    }

    setMax(newMax);
    setMin(newMin);
  }, [maxDate, minDate]);

  const isSelected = (day, month, year) => {
    const val = `${year}/${month}/${day}`;

    if (selectionMode === DATEPICKER_MODES.range && date?.startValue?.moment) {
      if (date.endValue?.moment) {
        const momentVal = moment(val, DATE_FORMATTER);
        return (
          date.startValue.moment.isSameOrBefore(momentVal, 'day') &&
          (!date.endValue || date.endValue.moment.isSameOrAfter(momentVal, 'day'))
        );
      }
      const { moment: dt } = date.startValue;
      return dt.format(DATE_FORMATTER) === val;
    }
    if (selectionMode === DATEPICKER_MODES.multiple && date?.stringValue?.length) {
      return -1 < date.stringValue.indexOf(val);
    }

    return date?.isValid && date.isValid() && date.format(DATE_FORMATTER) === val;
  };

  const getDayClass = (day, month, year) => {
    let classname = 'day';
    if (Number(month) < Number(currentMonth)) {
      classname = `${classname} prev-month-day`;
    } else if (Number(month) > Number(currentMonth)) {
      classname = `${classname} next-month-day`;
    }
    if (Number(day) === today.get('D') && Number(month) === today.get('month') + 1 && year === today.get('year')) {
      classname = `${classname} today primary-color`;
    }
    if (isSelected(day, month, year)) {
      classname = `${classname} selected primary-bg white-color`;
    }
    return classname;
  };

  const getIsDisabled = (momentDate, stringDate) =>
    // Is before min date or after max date
    min?.isAfter(momentDate, 'day') ||
    max?.isBefore(momentDate, 'day') ||
    // Is in disabled days
    (disabledDays && -1 !== disabledDays.indexOf(momentDate.weekday())) ||
    // Is in disabled dates
    (disabledDates &&
      (-1 !== disabledDates.indexOf(stringDate) ||
        -1 !== disabledDates.findIndex((dt) => dt?.format?.(DATE_FORMATTER) === stringDate)));

  const prevMonth = () => {
    const { month, year } = getPreviousMonth(currentMonth, currentYear);
    setDays(getDays(month, year));
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const nextMonth = () => {
    const { month, year } = getNextMonth(currentMonth, currentYear);
    setDays(getDays(month, year));
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const changeMonth = (newMonth) => {
    if (newMonth?.value && newMonth.value !== currentMonth) {
      setDays(getDays(newMonth.value, currentYear));
      setCurrentMonth(newMonth.value);
      setMonthSelectorVal(newMonth);
    }
  };

  const changeYear = (newYear) => {
    if (newYear?.value && newYear.value !== currentYear) {
      setDays(getDays(currentMonth, newYear.value));
      setCurrentYear(newYear.value);
      setYearSelectorVal(newYear);
    }
  };

  const renderMonth = monthNavigator ? (
    <Selector
      className="month-selector"
      onChange={changeMonth}
      options={locale.monthSelector}
      searchable={false}
      value={monthSelectorVal}
    />
  ) : (
    <span className="month-selector">{locale.monthNames[currentMonth - 1]}</span>
  );

  const renderYear = yearNavigator && years?.length ? (
    <Selector
      className="year-selector"
      onChange={changeYear}
      options={years}
      searchable={false}
      value={yearSelectorVal}
    />
  ) : (
    <span className="year-selector">{currentYear}</span>
  );

  return (
    <div className={`wiset-calendar${className ? ` ${className}` : ''}`}>
      <div style={style}>
        {!timeOnly && (
          <div className="wiset-calendar-btns primary-bg white-color">
            {selectOtherMonths && (
              <Button
                disabled={min && min.isSameOrAfter(currentMoment, 'month')}
                iconLeft="fas fa-chevron-left"
                onClick={prevMonth}
              />
            )}
            <div className="header-title">
              {renderMonth}
              {renderYear}
            </div>
            {selectOtherMonths && (
              <Button
                disabled={max?.isSameOrBefore(currentMoment, 'month')}
                iconLeft="fas fa-chevron-right"
                onClick={nextMonth}
              />
            )}
          </div>
        )}
        {!timeOnly && (
          <div className="days-header primary-color">
            {locale.dayNamesShort.map((day) => (
              <div key={day}>{day}</div>
            ))}
          </div>
        )}
        {!timeOnly && (
          <div className="days-container">
            {days &&
              0 < days.length &&
              days.map(([year, month, day, momentDate]) => {
                const stringDate = momentDate.format(DATE_FORMATTER);
                const disabled = getIsDisabled(momentDate, stringDate);

                return (
                  <Button
                    key={stringDate}
                    className={getDayClass(day, month, year)}
                    disabled={disabled}
                    disableType
                    onClick={() => onDayClick(momentDate, stringDate)}
                    inverted
                    text={`${day}`}
                  />
                );
              })}
          </div>
        )}
        {(timeOnly || showTime) && selectionMode === DATEPICKER_MODES.single && (
          <TimeSelector
            hour={hour}
            onHourChange={onHourChange}
            showSeconds={showSeconds}
            stepMinute={stepMinute}
            stepSecond={stepSecond}
          />
        )}
      </div>
    </div>
  );
};

Calendar.defaultProps = {
  className: null,
  date: null,
  disabledDates: [], // Array with disabled dates.
  disabledDays: null, // Array with disabled weekday numbers.
  hour: null,
  locale: undefined,
  maxDate: null,
  minDate: null,
  monthNavigator: false,
  selectionMode: DATEPICKER_MODES.single,
  selectOtherMonths: true,
  showSeconds: false,
  showTime: false,
  stepMinute: 30,
  stepSecond: 1,
  style: undefined,
  timeOnly: false,
  yearNavigator: false,
  yearRange: null, // F.E.: "2019:2029"
};

Calendar.propTypes = {
  className: PropTypes.string,
  date: PropTypes.any,
  disabledDates: PropTypes.array,
  disabledDays: PropTypes.array,
  hour: PropTypes.string,
  locale: PropTypes.object,
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  monthNavigator: PropTypes.bool,
  onDayClick: PropTypes.func.isRequired,
  onHourChange: PropTypes.func.isRequired,
  selectionMode: PropTypes.oneOf(Object.values(DATEPICKER_MODES)),
  selectOtherMonths: PropTypes.bool,
  showSeconds: PropTypes.bool,
  showTime: PropTypes.bool,
  stepMinute: PropTypes.number,
  stepSecond: PropTypes.number,
  style: PropTypes.object,
  timeOnly: PropTypes.bool,
  yearNavigator: PropTypes.bool,
  yearRange: PropTypes.string,
};

export default Calendar;
