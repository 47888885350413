import es from './es';

const translations = { es };

export const LANG_IDS = {
  spanish: 'es',
};

export const setLocale = (lang) => {
  localStorage.setItem('language', lang);
};

export const getLocale = () => {
  if (!localStorage.language) {
    return LANG_IDS.spanish;
  }
  return localStorage.language;
};

export const isSpanish = () => LANG_IDS.spanish === getLocale();

/**
 * Resolves a string 'index' for the JavaScript Object
 */
const resolveIndex = (str) => {
  let formattedString = str;

  formattedString = formattedString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  formattedString = formattedString.replace(/^\./, ''); // strip a leading dot
  const array = formattedString.split('.');

  let translationsLang = translations[getLocale()];

  for (let i = 0, { length } = array; i < length; i = i + 1) {
    const elem = array[i];
    if ('object' === typeof translationsLang && elem in translationsLang) {
      translationsLang = translationsLang[elem];
    } else {
      return null;
    }
  }
  return translationsLang;
};

export const translate = (key) => resolveIndex(key);

export const spanishLocale = {
  firstDayOfWeek: 1,
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
};
