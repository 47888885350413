/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Button, { BUTTON_TYPES } from '../Button/Button';

const Modal = ({
  children,
  className,
  footer,
  footerBackground,
  header,
  headerBackground,
  headerTextColor,
  hideOnClickOut,
  onHide,
  show,
  showClose,
  showFooter,
  showHeader,
  showShadow,
}) => show && (
<div className="wiset-modal-container">
  {showShadow && (
  <div
    className="wiset-modal-shadow fadein"
    onClick={hideOnClickOut && 'function' === typeof onHide ? () => onHide() : null}
  />
  )}
  <div className={`fadein wiset-modal${className ? ` ${className}` : ''}`}>
    {showHeader && (
    <div
      className={`wiset-modal-header${headerBackground ? ` ${headerBackground}-bg` : ''}${
        headerTextColor ? ` ${headerTextColor}-color` : ''
      }`}
    >
      {header}
      {showClose && (
      <Button
        className="wiset-modal-close"
        iconLeft="fas fa-times"
        onClick={onHide}
        type={BUTTON_TYPES.transparent}
      />
      )}
    </div>
    )}
    <div className={`wiset-modal-body${showHeader ? ' with-header' : ''}${showFooter ? ' with-footer' : ''}`}>
      {children}
    </div>
    {showFooter && (
    <div className={`wiset-modal-footer${footerBackground ? ` ${footerBackground}-bg` : ''}`}>{footer}</div>
    )}
  </div>
</div>
);

Modal.defaultProps = {
  className: null,
  footer: null,
  footerBackground: BUTTON_TYPES.white,
  header: null,
  headerBackground: BUTTON_TYPES.primary,
  headerTextColor: BUTTON_TYPES.white,
  hideOnClickOut: true,
  onHide: () => true,
  show: false,
  showClose: false,
  showFooter: true,
  showHeader: true,
  showShadow: true,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  footerBackground: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  headerBackground: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  headerTextColor: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  hideOnClickOut: PropTypes.bool,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  showClose: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showShadow: PropTypes.bool,
};

export default Modal;
