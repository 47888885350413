import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { ScrollToTop, useToast } from '../components/base-comps';
import Loading from '../components/base/Loading';
import Sidebar from '../components/Sidebar';

// Pages
import LoginPage from '../containers/login';
import BackofficeMenu from './backoffice/menu';
import DefaultCategoriesPage from './backoffice/defaultCategories';
import DefaultCategoryPage from './backoffice/defaultCategory';
import ValidateProductsPage from './backoffice/validateProducts';
import ValidateProductPage from './backoffice/validateProduct';
import NotFoundPage from './notFound';
import ShopsPage from './backoffice/shops';
import ShopPage from './backoffice/shop';
import ProductsPage from './backoffice/products';
import ProductPage from './backoffice/product';

import {
  INDEX_URL,
  BACKOFFICE_INDEX_URL,
  BACKOFFICE_SHOP,
  BACKOFFICE_SHOPS,
  BACKOFFICE_VALIDATE,
  BACKOFFICE_PRODUCTS,
  BACKOFFICE_PRODUCT,
  BACKOFFICE_DEFAULT_CATEGORIES
} from '../utils/pagesUrls';
import { userIsSuperadmin, userIsAdminOrMore } from '../utils/roles';
import { setToast } from '../utils/toasts';

const redirectBackoffice = (userRole, component) => {
  if (!userRole) {
    return <Route component={LoginPage} />;
  }

  if (userIsSuperadmin(userRole)) {
    return <Route component={component} />;
  }

  return <Route component={() => <Route component={NotFoundPage} />} />;
};

const Main = ({
  initialize,
  loading,
  loggedUserRole,
}) => {
  const toast = useToast();
  setToast(toast);
  const isAdmin = userIsAdminOrMore(loggedUserRole);

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (loading) {
    return (
      <main>
        <div id="main-loading">
          <Loading />
        </div>
      </main>
    );
  }

  return (
    <main>
      {isAdmin && <Sidebar userRole={loggedUserRole} />}
      <div id="main-container" className={!loggedUserRole ? 'without-navbar' : ''}>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path={INDEX_URL}
              render={() => {
                if (userIsSuperadmin(loggedUserRole)) {
                  return <Redirect to={BACKOFFICE_INDEX_URL} />;
                }
                return <Route component={LoginPage} />;
              }}
            />
            <Route
              exact
              path={BACKOFFICE_INDEX_URL}
              render={() => redirectBackoffice(loggedUserRole, BackofficeMenu)}
            />
            <Route
              exact
              path={`${BACKOFFICE_VALIDATE}/:id`}
              render={() => redirectBackoffice(loggedUserRole, ValidateProductPage)}
            />
            <Route
              exact
              path={BACKOFFICE_PRODUCTS}
              render={() => redirectBackoffice(loggedUserRole, ProductsPage)}
            />
            <Route
              exact
              path={`${BACKOFFICE_PRODUCT}/:id`}
              render={() => redirectBackoffice(loggedUserRole, ProductPage)}
            />
            <Route
              exact
              path={BACKOFFICE_VALIDATE}
              render={() => redirectBackoffice(loggedUserRole, ValidateProductsPage)}
            />
            <Route
              exact
              path={`${BACKOFFICE_DEFAULT_CATEGORIES}/:id`}
              render={() => redirectBackoffice(loggedUserRole, DefaultCategoryPage)}
            />
            <Route
              exact
              path={BACKOFFICE_DEFAULT_CATEGORIES}
              render={() => redirectBackoffice(loggedUserRole, DefaultCategoriesPage)}
            />
            <Route
              exact
              path={BACKOFFICE_SHOPS}
              render={() => redirectBackoffice(loggedUserRole, ShopsPage)}
            />
            <Route
              exact
              path={`${BACKOFFICE_SHOP}/:id`}
              render={() => redirectBackoffice(loggedUserRole, ShopPage)}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </ScrollToTop>
      </div>
    </main>
  );
};

Main.defaultProps = {
  loading: true,
  loggedUserRole: null,
};

Main.propTypes = {
  loading: PropTypes.bool,
  loggedUserRole: PropTypes.string,
  initialize: PropTypes.func.isRequired,
};

export default Main;
