import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './index.scss';

export const COLLAPSABLE_BUTTON_POSITION = {
  full: 'full',
  center: 'center',
  left: 'left',
  right: 'right',
};

const CollapsableBox = ({
  buttonProps, children, className, icon, inverted, position,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={`collapsable-box${expanded ? ' expanded' : ''}${inverted ? ' inverted' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <Button
        {...buttonProps}
        className={`expand-button ${position}`}
        iconLeft={icon}
        onClick={() => setExpanded(!expanded)}
      />
      <div className="collapsable-box-container">{children}</div>
    </div>
  );
};

CollapsableBox.defaultProps = {
  buttonProps: null,
  children: null,
  className: null,
  icon: 'fas fa-chevron-down',
  inverted: false,
  position: COLLAPSABLE_BUTTON_POSITION.full,
};

CollapsableBox.propTypes = {
  buttonProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.string,
  inverted: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(COLLAPSABLE_BUTTON_POSITION)),
};

export default CollapsableBox;
