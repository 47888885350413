import React, { useReducer, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import Page from '../../../components/base/Page';
import Loading from '../../../components/base/Loading';
import AlertBox from '../../../components/base-comps/AlertBox/AlertBox';
import { Input, Icon, DropdownButton, Button, BUTTON_TYPES, MultiSearch, SureModal } from '../../../components/base-comps';

import { getProduct, searchProducts, validateProduct, deleteProduct } from '../../../actions/product';
import { getAllDefaultCategories } from '../../../actions/defaultCategory';

import { PRODUCT } from '../../../utils/dispatchTypes';
import { translate } from '../../../utils/translate/translator';
import { showSuccessToast, showErrorToast } from '../../../utils/toasts';
import forms from '../../../utils/forms';

import './index.scss';

const initialState = {
  name: '',
  images: [],
  url: '',
  categories: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case PRODUCT.SET_NAME:
      return { ...state, name: action.value };
    case PRODUCT.SET_CATEGORIES:
      return { ...state, categories: action.value };
    case PRODUCT.SET_IMAGES:
      return { ...state, images: action.value };
    case PRODUCT.SET_URL:
      return { ...state, url: action.value };
    case PRODUCT.SET_PRODUCT:
      return action.product;
    default:
      return initialState;
  }
};

const renderResult = (opt) => {
  const mainImage = opt.images.length && opt.images.find((img) => img.isMain);

  return (
    <div className="result-item" key={opt.id}>
      <span className={`result-status-${opt.public ? 'public' : 'pending'}`}>
        {opt.public ? 'Public' : 'Pending'}
      </span>
      <div className="result-item-row">
        {
        mainImage && (
          <img src={mainImage.url} alt={mainImage.url} />
        )
      }
        <p>
          {opt.name}
        </p>
      </div>
      <span className="result-id">{opt.id}</span>
    </div>
  );
};

const ValidateProduct = () => {
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [errors, setErrors] = useState(null);
  const [showSureModal, setShowSureModal] = useState(false);
  const [products, productsToShow] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatchAction = useDispatch();

  const onSearch = (searchText, next) => {
    // get products filtered
    setLoadingSearch(true);
    searchProducts({ searchText, next, currentProduct: id })
      .then(({ items: dbProducts, nextToken }) => {
        if (dbProducts?.length) {
          productsToShow((prevProducts) => [...prevProducts, ...dbProducts]);
        }

        if (nextToken) {
          onSearch(searchText, nextToken);
        } else {
          setLoadingSearch(false);
        }
      })
      .catch(() => {
        setLoadingSearch(false);
      });
  };

  useEffect(() => {
    getAllDefaultCategories()
      .then(({ items }) => {
        setOptions(items);
      });

    if (id) {
      getProduct(id)
        .then((product) => {
          setLoading(false);
          const cleanProduct = {
            ...product,
            categories: product?.categories?.items.length ? product?.categories?.items?.map((item) => item.category) : [],
          };
          dispatch({ type: PRODUCT.SET_PRODUCT, product: cleanProduct });

          onSearch('tesf');
        })
        .catch(() => {
          setLoading(false);
          // error al cargar el producto, volver a la lista de productos
        });
    }
  }, [id]);

  function handleSearch() {
    onSearch(search);
  }

  const onChange = (param, value) => {
    if (errors) {
      setErrors(null);
    }

    dispatch({ type: param, value });
  };

  const onDeleteImage = (index) => {
    const images = [...state.images];

    images.splice(index, 1);

    onChange(PRODUCT.SET_IMAGES, images);
  };

  const hideSureModal = () => setShowSureModal(false);

  const onConfirmDelete = () => {
    deleteProduct(state.id)
      .then(() => {
        setLoadingDelete(false);
        setShowSureModal(false);
        showSuccessToast(translate('validateProduct.deleteOk'));
        history.goBack();
      })
      .catch(() => {
        setLoadingDelete(false);
        showErrorToast(translate('validateProduct.deleteKo'));
      });
  };

  const onSetMain = (index) => {
    const images = [...state.images];

    images.splice(index, 1);

    onChange(PRODUCT.SET_IMAGES, [{ ...state.images[index], isMain: true }, ...images]);
  };

  const onDrop = useCallback((files) => {
    if (files && files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(PRODUCT.SET_IMAGES, state?.images?.length ? [...state.images, { path: reader.result }] : [{ path: reader.result }]);
      };

      reader.readAsDataURL(files[0]);
    }
  }, [state]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const onValidate = () => {
    if (state.name) {
      setLoadingUpload(true);
      dispatchAction(validateProduct(state))
        .then(() => {
          setLoadingUpload(false);
          showSuccessToast(translate('validateProduct.validateOk'));
          history.goBack();
        })
        .catch((error) => {
          setLoadingUpload(false);
          console.warn(error);
          showErrorToast(translate('validateProduct.validateKo'));
        });
    }
  };

  const onDelete = () => setShowSureModal(true);

  return (
    <Page id="backoffice-validateProduct" grayBackground withLargeSidebar>
      <h2>{translate('backoffice.validateProduct')}</h2>
      {
        loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <div className="page-container">
            <div className="product-card">
              <div className="product-images">
                {
                  !!state?.images?.length && state.images.map((image, index) => {
                    const actions = [
                      {
                        text: translate('common.delete'),
                        icon: 'fas fa-times',
                        onClick: () => onDeleteImage(index),
                      },
                    ];

                    if (0 !== index) {
                      actions.push({
                        text: translate('common.setMain'),
                        icon: 'fas fa-home',
                        onClick: () => onSetMain(index),
                      });
                    }

                    return (
                      <div className="image-container" key={image.url || image.path}>
                        {
                          0 === index && (
                            <Icon icon="fa fa-home" className="main-image" />
                          )
                        }
                        <div className="image-options">
                          <DropdownButton
                            elements={actions}
                            type={BUTTON_TYPES.primary}
                            iconLeft="fas fa-ellipsis-v white-color"
                          />
                        </div>
                        <img src={image.url || image.path} alt={image.url || image.path} />
                      </div>
                    );
                  })
                }
                {
                  (!state?.images || 5 > state?.images?.length) && (
                    <div {...getRootProps()} className="image-dropzone">
                      <input {...getInputProps()} disabled={loading} />
                      <Icon icon="fas fa-camera" />
                    </div>
                  )
                }
              </div>

              <div className="form-container">
                <Input
                  autocomplete={false}
                  disabled={loading}
                  placeholder={translate('common.name')}
                  onChange={(value) => onChange(PRODUCT.SET_NAME, value)}
                  value={state.name}
                />
                <Input
                  disabled
                  placeholder={translate('common.url')}
                  value={state?.url || ''}
                />
                <Input
                  disabled
                  placeholder={translate('common.creator')}
                  value={state?.creator?.name}
                />
                <Input
                  disabled
                  placeholder={translate('common.createdAt')}
                  value={moment(state?.createdAt).format('DD/MM/YYYY - HH:mm')}
                />
                <MultiSearch
                  options={options}
                  selectedValues={state.categories}
                  valueKey="id"
                  onSearch={() => {}}
                  emptyText={translate('common.noItems')}
                  infoText={translate('common.enterToSearch')}
                  onChange={(value, categories) => onChange(PRODUCT.SET_CATEGORIES, categories)}
                />
              </div>
              <Button
                text={translate('backoffice.validateProduct')}
                loading={loadingUpload}
                disabled={loadingUpload}
                onClick={onValidate}
                className="validateProductButton"
              />
              <Button
                text={translate('backoffice.removeProduct')}
                disabled={loadingUpload}
                onClick={onDelete}
                type={BUTTON_TYPES.secondaryDarker}
                className="validateProductButton"
              />
            </div>
            <div className="search-container">
              <h3>{translate('common.similarProducts')}</h3>
              {loadingSearch && <Loading />}
              {
                !!products?.length && !loadingSearch && products.map((similar) => (
                  renderResult(similar)
                ))
              }
              {!products?.length && !loadingSearch && (
                <>
                  <div className="search-wrapper">
                    <Input
                      placeholder={translate('common.retrySearch')}
                      onChange={(value) => setSearch(value)}
                      value={search}
                      className="retrySearch"
                    />
                    <Button
                      text={translate('common.search')}
                      onClick={handleSearch}
                      type={BUTTON_TYPES.secondaryDarker}
                    />
                  </div>
                  <AlertBox text={translate('common.noItems')} />
                </>
              )}
            </div>
          </div>
        )
      }
      <SureModal
        {...forms.modals.sure}
        header={translate('validateProduct.delete')}
        loading={loadingDelete}
        onAccept={onConfirmDelete}
        onHide={hideSureModal}
        show={showSureModal}
        sureText={translate('common.sureText')}
        >
        <p>
          {translate('validateProduct.deleteSure')}
        </p>
        <Input
          disabled
          value={state.id}
          placeholder="Id"
      />
        <Input
          disabled
          value={state.name}
          placeholder={translate('common.name')}
      />

      </SureModal>
    </Page>
  );
};

export default ValidateProduct;
