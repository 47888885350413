import { Storage } from 'aws-amplify';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import Resizer from 'react-image-file-resizer';
import { v4 } from 'uuid';

export const getImageType = (url) => {
  if (url.toLowerCase().includes('.png')) {
    return 'png';
  }
  if (url.toLowerCase().includes('.jpeg')) {
    return 'jpeg';
  }

  return 'jpg';
};

export const getCompressFormat = (url) => {
  if (url.toLowerCase().includes('.png')) {
    return 'PNG';
  }

  return 'JPEG';
};

export const uploadImage = (path, name, type, width = 1080, height = 1920) =>
  new Promise((resolve, reject) => {
    /**
     * COMPRESS IMAGES BEFORE UPLOAD
     */

    const compressFormat = getCompressFormat(name);

    Resizer.imageFileResizer(
      path,
      width,
      height,
      compressFormat,
      70,
      0,
      (uri) => {
        const blob = dataURLtoBlob(uri);
        Storage.put(name, blob, { contentType: `image/${type}` })
          .then(({ key }) => {
            resolve(`${process.env.REACT_APP_S3_UPLOAD_URL}/${key}`);
          })
          .catch((putError) => {
            reject(putError);
          });
      },
      'base64',
    );
  });

export const uploadImages = (images) =>
  new Promise((resolve, reject) => {
    const uploadedImages = [];
    let index = 0;

    if (!images) {
      reject();
    }

    images.forEach((image, idx) => {
      if (image?.path) {
        const uuid = v4();
        const type = image.mime || getImageType(image.path);
        const name = `${uuid}.${type}`;
        const blob = dataURLtoBlob(image.path);

        uploadImage(blob, name, type)
          .then((imageURL) => {
            index = index + 1;
            uploadedImages.push({
              url: imageURL,
              isMain: 0 === idx,
            });

            if (index === images.length) {
              resolve(uploadedImages);
            }
          })
          .catch(() => {
            index = index + 1;
            if (index === images.length) {
              resolve(uploadedImages);
            }
          });
      } else if (image?.url) {
        index = index + 1;
        uploadedImages.push({
          ...image,
          isMain: 0 === idx,
        });

        if (index === images.length) {
          resolve(uploadedImages);
        }
      } else {
        index = index + 1;

        if (index === images.length) {
          resolve(uploadedImages);
        }
      }
    });
  });
