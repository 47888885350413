import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import MainPage from '../../pages/main';
import { logout } from '../../actions/user';
import initialize from '../../actions/general';

const mapStateToProps = ({ general, user }) => ({
  loading: general.loading || user.loading,
  loggedUserRole: user.loggedUserRole,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      initialize,
      logout,
    },
    dispatch,
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage));
