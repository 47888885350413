import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Selector from '../Selector/Selector';
import Button, { BUTTON_TYPES } from '../Button/Button';
import Icon from '../Icon/Icon';

import './index.scss';

const MultiSearch = ({
  className,
  disabled,
  elementIcon,
  elementType,
  emptyText,
  icon,
  infoText,
  labelKey,
  noSearchText,
  onChange,
  onRemoveValue,
  onSearch,
  options,
  selectedValues,
  toUppercase,
  valueKey,
  ...rest
}) => {
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchDone, setSearchDone] = useState(false);

  /**
   * This method is called on change selected values or options
   * to remove selected values from options on selector
   */
  const purgeOptions = (newOptions) => {
    const currentOptions = newOptions || options;
    const cleanOptions = currentOptions.filter((opt) => (selectedValues?.length ? -1 === selectedValues.findIndex((sel) => sel[valueKey] === opt[valueKey]) : true));

    setOptionsToShow(cleanOptions);
  };

  useEffect(() => {
    if (selectedValues && selectedValues !== selected) {
      setSelected(selectedValues);
      purgeOptions();
    }
  }, [selectedValues]);

  useEffect(() => {
    if (options && options !== optionsToShow) {
      purgeOptions(options);
    }
  }, [options]);

  const onSelect = (value) => {
    if (value) {
      const newOptions = optionsToShow.filter((opt) => opt[valueKey] !== value[valueKey]);
      const newSelected = [...selected, value];

      setOptionsToShow(newOptions);
      setSelected(newSelected);
      onChange(value, newSelected);
    }
  };

  const onRemove = (item) => {
    const newSelected = selected.filter((sel) => sel[valueKey] !== item[valueKey]);
    const newOptions = optionsToShow.filter((opt) => (newSelected?.length ? -1 === newSelected.findIndex((sel) => sel[valueKey] === opt[valueKey]) : true));

    setOptionsToShow(newOptions);
    setSelected(newSelected);

    if (onRemoveValue) {
      onRemoveValue(item, newSelected);
    }

    onChange(item, newSelected);
  };

  const onEnter = (text) => {
    onSearch(text);
    setSearchDone(true);
  };

  return (
    <div className={`wiset-multisearch${className ? ` ${className}` : ''}`}>
      <Selector
        {...rest}
        icon={icon}
        valueKey={valueKey}
        labelKey={labelKey}
        options={optionsToShow}
        disabled={disabled}
        keepValueSelected={false}
        infoText={infoText}
        onEnter={onEnter}
        setOnEnter={false}
        onChange={onSelect}
        emptyText={searchDone ? emptyText : noSearchText}
      />
      <div className="elements">
        {selected &&
          0 < selected.length &&
          selected.map((val, idx) => (
            <div
              key={`${val[valueKey]}-${idx}`}
              className={`fadein element ${elementType}-bg${disabled ? ' disabled-button' : ''}`}
            >
              {elementIcon && <Icon icon={elementIcon} className="element-icon" />}
              <p>{toUppercase ? val[labelKey].toUpperCase() : val[labelKey]}</p>
              {!disabled && (
                <Button iconRight="fas fa-times" inverted onClick={() => onRemove(val)} type={elementType} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

MultiSearch.defaultProps = {
  className: null,
  disabled: false,
  elementIcon: null,
  elementType: BUTTON_TYPES.primary,
  emptyText: 'No elements found.',
  icon: null,
  infoText: 'Press enter to search',
  labelKey: 'name',
  noSearchText: 'No search has been performed yet.',
  onRemoveValue: null,
  options: [],
  selectedValues: [],
  toUppercase: false,
  valueKey: '_id',
};

MultiSearch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  elementIcon: PropTypes.string,
  elementType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  emptyText: PropTypes.string,
  icon: PropTypes.string,
  infoText: PropTypes.string,
  labelKey: PropTypes.string,
  noSearchText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemoveValue: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  toUppercase: PropTypes.bool,
  valueKey: PropTypes.string,
};

export default MultiSearch;
