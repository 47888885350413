import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { BUTTON_TYPES, getTypeClassName } from '../Button/Button';
import Icon from '../Icon/Icon';

import './index.scss';

/**
 * Extends Button component
 */
const Link = ({
  children,
  className,
  disabled,
  external,
  iconLeft,
  iconRight,
  inverted,
  onClick,
  text,
  to,
  type,
  ...rest
}) =>
  (external ? (
    <a
      className={`btn btn-link${type && getTypeClassName(type, inverted)}${className ? ` ${className}` : ''}${
        disabled ? ' disabled' : ''
      }`}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled && 'function' === typeof onClick) {
          onClick(e);
        } else if (disabled) {
          e.preventDefault();
        }
      }}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {iconLeft && <Icon icon={`${iconLeft} left`} className={text ? 'with-margin' : ''} />}
      {text && <span>{text}</span>}
      {iconRight && <Icon icon={`${iconRight} right`} className={text ? 'with-margin' : ''} />}
      {children}
    </a>
  ) : (
    <NavLink
      {...rest}
      className={`btn btn-link${type && getTypeClassName(type, inverted)}${className ? ` ${className}` : ''}${
        disabled ? ' disabled' : ''
      }`}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled && 'function' === typeof onClick) {
          onClick(e);
        } else if (disabled) {
          e.preventDefault();
        }
      }}
      to={to}
    >
      {iconLeft && <Icon icon={`${iconLeft} left`} className={text ? 'with-margin' : ''} />}
      {text && <span>{text}</span>}
      {iconRight && <Icon icon={`${iconRight} right`} className={text ? 'with-margin' : ''} />}
      {children}
    </NavLink>
  ));

Link.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  external: false,
  iconLeft: null,
  iconRight: null,
  inverted: false,
  onClick: () => true,
  text: null,
  type: BUTTON_TYPES.primary,
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};

export default Link;
