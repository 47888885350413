import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Input from '../Input/Input';
import Icon from '../Icon/Icon';
import Button, { BUTTON_TYPES } from '../Button/Button';

const cleanValue = (value) => value
  .toString()
  .trim()
  .replace(/\//g, '')
  .replace(/\s{2,}/g, ' ') // double space to single space
  .replace(/\s/g, ''); // single space to ''

const MultiInput = ({
  className,
  disabled,
  elementType,
  enableRepeat,
  error,
  icon,
  infoText,
  maxLength,
  minLength,
  onChange,
  placeholder,
  values,
  toUppercase,
  ...rest
}) => {
  const [inputError, setInputError] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [selectedValues, setSelectedValues] = useState(values || []);

  useEffect(() => {
    if (values && JSON.stringify(values) !== JSON.stringify(selectedValues)) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    setInputValue(null);
    setInputError(false);
  }, [disabled]);

  const onPressEnter = (val) => {
    if (val && (!minLength || val.length >= minLength)) {
      if (enableRepeat || -1 === selectedValues.indexOf(val)) {
        const newValues = [...selectedValues, val];
        setSelectedValues(newValues);
        setInputValue(null);

        if ('function' === typeof onChange) {
          onChange(newValues);
        }
      } else {
        setInputError(true);
      }
    } else if (val && minLength && val.length < minLength) {
      setInputError(true);
    }
  };

  const onRemoveValue = (index) => {
    const newValues = [...selectedValues];
    newValues.splice(index, 1);

    setSelectedValues(newValues);
    if ('function' === typeof onChange) {
      onChange(newValues);
    }
  };

  const onChangeValue = (val) => {
    const newVal = cleanValue(val);
    setInputValue(newVal);
    setInputError(false);
  };

  return (
    <div className={`wiset-multiinput${className ? ` ${className}` : ''}`}>
      <Input
        {...rest}
        className="fadein"
        disabled={disabled}
        error={error || inputError}
        icon={icon}
        iconRight={false}
        maxLength={maxLength}
        onChange={onChangeValue}
        onEnter={onPressEnter}
        placeholder={placeholder}
        value={inputValue || ''}
        withOnEnterAndSpace
      />
      {!disabled && infoText && <span className="element-info">{infoText}</span>}
      <div className="elements">
        {selectedValues &&
          0 < selectedValues.length &&
          selectedValues.map((val, idx) => (
            <div
              key={`${val}-${idx}`}
              className={`fadein element ${elementType}-bg${disabled ? ' disabled-button' : ''}`}
            >
              {icon && <Icon icon={icon} />}
              <p>{toUppercase ? val.toUpperCase() : val}</p>
              {!disabled && (
                <Button iconRight="fas fa-times" inverted onClick={() => onRemoveValue(idx)} type={elementType} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

MultiInput.defaultProps = {
  className: null,
  disabled: false,
  elementType: BUTTON_TYPES.primary,
  enableRepeat: false,
  error: false,
  icon: null,
  infoText: 'Press enter to add.',
  maxLength: null,
  minLength: null,
  onChange: null,
  placeholder: null,
  toUppercase: false,
  values: [],
};

MultiInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.any,
  elementType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  enableRepeat: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.string,
  infoText: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  toUppercase: PropTypes.bool,
  values: PropTypes.array,
};

export default MultiInput;
