import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GenericModal from '../GenericModal/GenericModal';
import DropdownButton from '../../DropdownButton/DropdownButton';
import TableColumn from '../../Table/TableColumn/TableColumn';
import Button, { BUTTON_TYPES } from '../../Button/Button';
import Table from '../../Table/Table';
import SureModal from '../../SureModal/SureModal';
import GENERIC_TYPES from '../GenericTypes';

import './index.scss';

const GenericTable = ({
  actionsProps,
  addButtonProps,
  color,
  columns,
  customValidator,
  deleteElementFormatter,
  deleteModalProps,
  editModalProps,
  entity,
  extraActions,
  loading,
  loadingFormatter,
  loadingModal,
  loadingModalFormatter,
  modal,
  objects,
  onDelete,
  onSave,
  secondaryColor,
  showActions,
  showAdd,
  showDelete,
  showEdit,
  tableProps,
  title,
  translations,
  withShadow,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getActions = (item) => {
    let actions = [];

    if (showEdit) {
      actions.push({
        icon: 'fas fa-edit',
        text: translations && translations.edit,
        onClick: () => {
          setIsEditing(true);
          setShowModal(true);
          setSelectedItem(item);
        },
      });
    }

    if ('function' === typeof extraActions) {
      actions = actions.concat(extraActions(item));
    }

    if (showDelete && !item.deleted) {
      actions.push({
        icon: 'fas fa-trash',
        text: translations && translations.delete,
        onClick: () => {
          setShowDeleteModal(true);
          setSelectedItem(item);
        },
      });
    }

    return actions;
  };

  const clearEditing = () => {
    setShowModal(false);
    setIsEditing(false);
    setSelectedItem({});
  };

  const getColumns = () => {
    let newCols = [];

    if (!!columns && 0 < columns.length) {
      newCols = columns.map(
        ({
          props: {
            modalType, label, field, tableField, tableFormatter, tableProps: colProps, ...rest
          },
        }, idx) => (
          <TableColumn
            key={field || idx}
            formatter={GENERIC_TYPES.json === modalType ? (val) => <p>{JSON.stringify(val)}</p> : tableFormatter}
            {...rest}
            {...colProps}
            header={label}
            field={tableField || field}
          />
        ),
      );
    }
    if (showActions) {
      newCols.push(
        <TableColumn
          key="__wiset__"
          formatter={(cell, elem) => !elem ||
            (!elem.disableActions && (
              <DropdownButton
                buttonClassName="generic-backoffice-no-borders"
                elements={getActions(elem)}
                type={BUTTON_TYPES.transparent}
                iconLeft={`fas fa-ellipsis-v ${color}-color`}
              />
            ))}
          header={translations && translations.actions}
          {...actionsProps}
          field="__wiset__"
          isKey={false}
        />,
      );
    }

    return newCols;
  };

  const renderAdd = () => {
    const addProps = {
      iconLeft: 'fas fa-plus-square',
      text: translations && translations.create,
      ...addButtonProps,
    };

    return <Button {...addProps} onClick={() => setShowModal(true)} />;
  };

  const renderExtra = () => {
    if (showAdd && tableProps && 'function' === typeof tableProps.headerExtra) {
      return () => (
        <div className="generic-backoffice-extra">
          {renderAdd()}
          {tableProps.headerExtra()}
        </div>
      );
    }
    if (showAdd) {
      return () => renderAdd();
    }
    if (tableProps && 'function' === typeof tableProps.headerExtra) {
      return () => tableProps.headerExtra();
    }
    return null;
  };

  return (
    <div className="generic-backoffice-table">
      <Table
        {...tableProps}
        headerExtra={renderExtra()}
        className={`white-bg${withShadow ? ' backoffice-shadow' : ''}`}
        elements={objects}
        exportButtonProps={
          tableProps && tableProps.exportButtonProps ?
            { ...tableProps.exportButtonProps, iconLeft: 'fas fa-file-excel', text: translations.export } :
            { iconLeft: 'fas fa-file-excel', text: translations.export }
        }
        globalSearchProps={
          tableProps && tableProps.globalSearchProps ?
            { ...tableProps.globalSearchProps, icon: 'fas fa-search', placeholder: translations.search } :
            { icon: 'fas fa-search', placeholder: translations.search }
        }
        firstType={color}
        loading={loading}
        loadingFormatter={loadingFormatter}
        noItemsText={translations && translations.noItems}
        totalText={objects && 1 === objects.length ? entity : title}
        secondType={secondaryColor}
      >
        {getColumns()}
      </Table>
      {showModal && (
        <GenericModal
          editModalProps={editModalProps}
          customValidator={customValidator}
          elements={modal}
          header={`${translations && isEditing ? translations.edit : translations.create} ${entity}`}
          isEditing={isEditing}
          onHide={clearEditing}
          onSave={(item) => 'function' === typeof onSave && onSave(item, clearEditing)}
          selectedItem={selectedItem}
          show={showModal}
          translations={translations}
          loading={loadingModal}
          loadingFormatter={loadingModalFormatter}
        />
      )}
      {showDeleteModal && (
        <SureModal
          header={`${translations && translations.delete} ${entity}`}
          {...deleteModalProps}
          onHide={() => setShowDeleteModal(false)}
          onAccept={() => 'function' === typeof onDelete && onDelete(selectedItem, () => setShowDeleteModal(false))}
          show={showDeleteModal}
          yesText={translations && translations.yes}
          noText={translations && translations.no}
          sureText={translations && translations.sureText}
        >
          {'function' === typeof deleteElementFormatter ? deleteElementFormatter(selectedItem) : null}
        </SureModal>
      )}
    </div>
  );
};

GenericTable.defaultProps = {
  actionsProps: {},
  addButtonProps: {},
  color: BUTTON_TYPES.primary,
  customValidator: null,
  deleteElementFormatter: null,
  deleteModalProps: {},
  editModalProps: {},
  entity: null,
  extraActions: null,
  loading: false,
  loadingFormatter: null,
  loadingModal: false,
  loadingModalFormatter: null,
  modal: [],
  objects: [],
  onDelete: null,
  onSave: null,
  secondaryColor: BUTTON_TYPES.secondaryLighter,
  showActions: true,
  showAdd: false,
  showDelete: true,
  showEdit: true,
  tableProps: {},
  title: null,
  translations: {},
  withShadow: true,
};

GenericTable.propTypes = {
  actionsProps: PropTypes.object,
  addButtonProps: PropTypes.object,
  color: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  columns: PropTypes.array.isRequired,
  customValidator: PropTypes.func,
  deleteElementFormatter: PropTypes.func,
  deleteModalProps: PropTypes.object,
  editModalProps: PropTypes.object,
  entity: PropTypes.string,
  extraActions: PropTypes.func,
  loading: PropTypes.bool,
  loadingFormatter: PropTypes.func,
  loadingModal: PropTypes.bool,
  loadingModalFormatter: PropTypes.func,
  modal: PropTypes.array,
  objects: PropTypes.array,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  secondaryColor: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  showActions: PropTypes.bool,
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  tableProps: PropTypes.object,
  title: PropTypes.string,
  translations: PropTypes.object,
  withShadow: PropTypes.bool,
};

export default GenericTable;
