import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BUTTON_TYPES } from '../Button/Button';
import Option from './option';
import './index.scss';
import Icon from '../Icon/Icon';

export const SELECT_BUTTON_TYPES = {
  default: 'default',
  round: 'round',
};

/**
 * This component is an extension of Button component
 */
const SelectButton = ({
  buttonType,
  className,
  icon,
  iconKey,
  iconLeft,
  iconRight,
  labelKey,
  onChange,
  options,
  placeholder,
  value,
  valueKey,
  buttonClassName,
  disabled,
  inverted,
  type,
  defaultValue,
}) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (defaultValue && !value && !selected && options && options.length) {
      setSelected(options[0][valueKey]);
    }
  }, [options, selected, value, valueKey, defaultValue]);

  const val = null !== value && undefined !== value ? value : selected;

  return (
    <div className={`wiset-select-btn${className ? ` ${className}` : ''}`}>
      {placeholder && (
        <p>
          {icon && <Icon icon={icon} />}
          {placeholder}
        </p>
      )}
      <div className="btns-container">
        {options &&
          0 < options.length &&
          options.map((option, idx) => (
            <div key={idx} className={buttonType === SELECT_BUTTON_TYPES.default ? '' : 'round'}>
              <Option
                key={option[valueKey] || idx}
                buttonType={buttonType}
                buttonClassName={buttonClassName}
                disabled={disabled}
                iconLeft={iconLeft && iconKey ? option[iconKey] : undefined}
                iconRight={iconRight && iconKey ? option[iconKey] : undefined}
                inverted={inverted}
                label={option[labelKey]}
                selected={val === option[valueKey]}
                setSelected={() => {
                  const newVal = option[valueKey];
                  setSelected(newVal);
                  if (onChange) {
                    onChange(newVal);
                  }
                }}
                type={type}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

SelectButton.defaultProps = {
  buttonClassName: null,
  buttonType: SELECT_BUTTON_TYPES.default,
  className: null,
  defaultValue: true,
  disabled: false,
  icon: null,
  iconKey: 'icon',
  iconLeft: false,
  iconRight: false,
  inverted: false,
  labelKey: 'label',
  onChange: () => true,
  type: BUTTON_TYPES.primary,
  placeholder: null,
  value: null,
  valueKey: 'value',
};

SelectButton.propTypes = {
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.values(SELECT_BUTTON_TYPES).map((type) => type)),
  className: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.any,
  icon: PropTypes.string,
  iconKey: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  inverted: PropTypes.bool,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  value: PropTypes.any,
  valueKey: PropTypes.string,
};

export default SelectButton;
