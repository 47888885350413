const createShop = /* GraphQL */ `
  mutation CreateShop($input: CreateShopInput!, $condition: ModelShopConditionInput) {
    createShop(input: $input, condition: $condition) {
      id
      name
      logo
    }
  }
`;

const updateShop = /* GraphQL */ `
  mutation updateShop($shop: ShopInputModel!, $image: Upload) {
    updateShop(shop: $shop, image: $image) {
      _id
      name
      logo
      deleted
    }
  }
`;

const deleteShop = /* GraphQL */ `
  mutation DeleteShop($input: DeleteShopInput!, $condition: ModelShopConditionInput) {
    deleteShop(input: $input, condition: $condition) {
      id
    }
  }
`;

const restoreShop = /* GraphQL */ `
  mutation restoreShop($_id: ID!) {
    restoreShop(_id: $_id) {
      _id
    }
  }
`;

export default {
  createShop,
  deleteShop,
  updateShop,
  restoreShop,
};
