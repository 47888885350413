import React from 'react';

const Loading = () => (
  <div className="loading-container fadein">
    <div className="loader fadein" />
    <div className="loader2 fadein" />
    <div className="loader3 fadein" />
  </div>
);
export default Loading;
