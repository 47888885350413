/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import Button, { BUTTON_TYPES } from '../Button/Button';

const multiply = (first, second) => Number((Number(first) * Number(second)).toFixed(2));
const divide = (first, second) => Number((Number(first) / Number(second)).toFixed(2));
const isFunction = (elem) => 'function' === typeof elem;
const getTime = (time) => {
  if (!Number.isNaN(time)) {
    return `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(-2)}`;
  }
  return '00:00';
};

const Audio = ({
  className,
  enableDownload,
  file,
  fileType,
  onForward,
  onNext,
  onPause,
  onPlay,
  onPrev,
  onRewind,
  onStop,
  showForward,
  showMute,
  showNext,
  showPrev,
  showRewind,
  showStop,
  showVolume,
  small,
  type,
  ...rest
}) => {
  const [backward, setBackward] = useState(null);
  const [current, setCurrent] = useState(null);
  const [end, setEnd] = useState(null);
  const [expandedVolume, setExpandedVolume] = useState(false);
  const [forward, setForward] = useState(null);
  const [mute, setMute] = useState(false);
  const [width, setWidth] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const audio = useRef();
  const volumeBar = useRef();
  const progressBar = useRef();

  const onChangeTime = ({ target }) => {
    setCurrent(getTime(target.currentTime));
    setEnd(getTime(target.duration));
    const wdth = divide(multiply(target.currentTime, 100), target.duration);
    setWidth(wdth);

    if (100 <= wdth) {
      setPlaying(false);
    }
  };

  useEffect(() => {
    setCurrent(getTime(null));
    setEnd(getTime(null));

    audio.current.addEventListener('timeupdate', onChangeTime);

    return () => audio && audio.current && audio.current.removeEventListener('timeupdate', onChangeTime);
  }, []);

  const onForwardClick = () => {
    audio.current.currentTime = audio.current.currentTime + 2;
    setCurrent(getTime(audio.current.currentTime));
  };

  const onBackwardClick = () => {
    audio.current.currentTime = audio.current.currentTime - 2;
    setCurrent(getTime(audio.current.currentTime));
  };

  const onChangeVolume = ({ pageY }) => {
    const { offsetTop, offsetHeight } = volumeBar.current;
    let vol = 1 - divide(pageY - offsetTop, offsetHeight);
    if (1 < vol) {
      vol = 1;
    } else if (0 > vol) {
      vol = 0;
    }
    audio.current.volume = vol;
    audio.current.muted = false;
    setMute(false);
    setVolume(vol);
  };

  const onClickProgressBar = ({ pageX }) => {
    const { offsetLeft, offsetWidth } = progressBar.current;
    const point = multiply(divide(pageX - offsetLeft, offsetWidth), audio.current.duration);
    audio.current.currentTime = point;
    setCurrent(getTime(audio.current.currentTime));
  };

  const volumeComp = (
    <div className="wiset-audio-volume">
      <div
        className={`wiset-audio-volume-bar ${type}-bg${expandedVolume ? ' expanded' : ''}${
          showMute ? ' with-mute' : ''
        }`}
      >
        {showMute && (
          <Button
            className="mute-btn"
            iconLeft={`fas fa-volume${mute ? '-mute' : ''}`}
            onClick={() => {
              audio.current.muted = !mute;
              setMute(!mute);
            }}
            type={type}
          />
        )}
        <div onMouseUp={onChangeVolume} ref={volumeBar}>
          <div style={{ height: mute ? 0 : `${(volume || 0) * 100}%` }} className={`${type}-bg`} />
        </div>
      </div>
      <Button
        iconLeft="fas fa-volume"
        onClick={() => {
          setExpandedVolume(!expandedVolume);
        }}
        type={type}
      />
    </div>
  );

  return (
    <div className={`wiset-audio${small ? ' wiset-audio-small' : ''}${className ? ` ${className}` : ''}`}>
      <audio {...rest} ref={audio} controls={false} controlsList={`${enableDownload ? '' : 'nodownload'}`}>
        <source src={file} type={fileType} />
        <track kind="captions" />
      </audio>
      <div className="wiset-audio-btns">
        {showPrev && !small && (
          <Button
            iconLeft="fas fa-step-backward"
            onClick={() => {
              if (isFunction(onPrev)) {
                onPrev();
              }
            }}
            type={type}
          />
        )}
        {showRewind && !small && (
          <Button
            iconLeft="fas fa-backward"
            onDoubleClick={() => {
              audio.current.currentTime = 0;
              setCurrent(0);
            }}
            onMouseDown={() => {
              if (isFunction(onRewind)) {
                onRewind();
              }
              onBackwardClick();
              setBackward(setInterval(onBackwardClick, 200));
            }}
            onMouseUp={() => {
              clearInterval(backward);
              setBackward();
            }}
            type={type}
          />
        )}
        <Button
          iconLeft={`fas fa-${playing ? 'pause' : 'play'}`}
          onClick={() => {
            if (playing) {
              audio.current.pause();
              if (isFunction(onPause)) {
                onPause();
              }
            } else {
              if (isFunction(onPlay)) {
                onPlay();
              }
              audio.current.play();
            }
            setPlaying(!playing);
          }}
          type={type}
        />
        {showStop && !small && (
          <Button
            iconLeft="fas fa-stop"
            onClick={() => {
              if (isFunction(onStop)) {
                onStop();
              }
              audio.current.currentTime = 0;
              setCurrent(0);
              audio.current.pause();
              setPlaying(false);
            }}
            type={type}
          />
        )}
        {showForward && !small && (
          <Button
            iconLeft="fas fa-forward"
            onMouseDown={() => {
              if (isFunction(onForward)) {
                onForward();
              }
              onForwardClick();
              setForward(setInterval(onForwardClick, 200));
            }}
            onMouseUp={() => {
              clearInterval(forward);
              setForward();
            }}
            type={type}
          />
        )}
        {showNext && !small && (
          <Button
            iconLeft="fas fa-step-forward"
            onClick={() => {
              if (isFunction(onNext)) {
                onNext();
              }
            }}
            type={type}
          />
        )}
        {showVolume && !small && volumeComp}
      </div>
      <div className={`wiset-audio-container ${type}-bg${!showVolume && small ? ' without-volume' : ''}`}>
        <div className={`wiset-audio-progress-bar ${type}-bg`} onMouseUp={onClickProgressBar} ref={progressBar}>
          <div className={`${type}-bg`} style={{ width: `${width}%` }} />
        </div>
        <div className="wiset-audio-measure">
          {current}
          {' '}
          /
          {' '}
          {end}
        </div>
      </div>
      {showVolume && small && volumeComp}
    </div>
  );
};

Audio.defaultProps = {
  autoPlay: false,
  className: null,
  enableDownload: false,
  fileType: 'audio/mp3',
  loop: false,
  muted: false,
  onForward: null,
  onNext: null,
  onPause: null,
  onPlay: null,
  onPrev: null,
  onRewind: null,
  onStop: null,
  showForward: true,
  showMute: true,
  showNext: false,
  showPrev: false,
  showRewind: true,
  showStop: true,
  showVolume: true,
  small: false,
  type: BUTTON_TYPES.primary,
};

Audio.propTypes = {
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
  enableDownload: PropTypes.bool,
  file: PropTypes.any.isRequired,
  fileType: PropTypes.string,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onForward: PropTypes.bool,
  onNext: PropTypes.bool,
  onPrev: PropTypes.bool,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onRewind: PropTypes.bool,
  onStop: PropTypes.bool,
  small: PropTypes.bool,
  showForward: PropTypes.bool,
  showMute: PropTypes.bool,
  showNext: PropTypes.bool,
  showPrev: PropTypes.bool,
  showRewind: PropTypes.bool,
  showStop: PropTypes.bool,
  showVolume: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};

export default Audio;
