import React, { useReducer, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';

import Page from '../../../components/base/Page';
import Loading from '../../../components/base/Loading';
import { Search, Input, Icon, DropdownButton, Button, BUTTON_TYPES, MultiSearch, SureModal } from '../../../components/base-comps';

import { getProduct, searchProducts, updateProduct, deleteProduct } from '../../../actions/product';
import { getAllDefaultCategories } from '../../../actions/defaultCategory';

import { PRODUCT } from '../../../utils/dispatchTypes';
import { translate } from '../../../utils/translate/translator';
import { showSuccessToast, showErrorToast } from '../../../utils/toasts';
import forms from '../../../utils/forms';

import './index.scss';

const initialState = {
  name: '',
  searchName: '',
  images: [],
  url: '',
  creator: null,
  categories: [],
  addedCategories: [],
  removedCategories: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case PRODUCT.SET_NAME:
      return { ...state, name: action.value, searchName: action?.value?.toLowerCase() };
    case PRODUCT.SET_CATEGORIES:
      return { ...state, categories: action.value, addedCategories: action.added, removedCategories: action.removed };
    case PRODUCT.SET_IMAGES:
      return { ...state, images: action.value };
    case PRODUCT.SET_URL:
      return { ...state, url: action.value };
    case PRODUCT.SET_PRODUCT:
      return action.product;
    default:
      return initialState;
  }
};

const renderResult = (opt) => {
  const mainImage = opt.images.length && opt.images.find((img) => img.isMain);

  return (
    <div className="result-item" key={opt.id}>
      {
        mainImage && (
          <img src={mainImage.url} alt={mainImage.url} />
        )
      }
      <p>
        {opt.name}
      </p>
    </div>
  );
};

const Product = () => {
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showSureModal, setShowSureModal] = useState(false);
  const [products, productsToShow] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    getAllDefaultCategories()
      .then(({ items }) => {
        setOptions(items);
      });

    if (id) {
      getProduct(id)
        .then((product) => {
          setLoading(false);
          const cleanProduct = {
            ...product,
            categories: product?.categories?.items?.map((item) => item.category),
            addedCategories: [],
            removedCategories: [],
          };
          dispatch({ type: PRODUCT.SET_PRODUCT, product: cleanProduct });
        })
        .catch(() => {
          setLoading(false);
          // error al cargar el producto, volver a la lista de productos
        });
    }
  }, [id]);

  const onSearch = (searchText) => {
    // get products filtered
    setLoadingSearch(true);
    searchProducts({ searchText })
      .then((dbProducts) => {
        setLoadingSearch(false);
        productsToShow(dbProducts);
      })
      .catch(() => {
        setLoadingSearch(false);
      });
  };

  const onChange = (param, value) => {
    if (errors) {
      setErrors(null);
    }

    dispatch({ type: param, value });
  };

  const onDeleteImage = (index) => {
    const images = [...state.images];

    images.splice(index, 1);

    onChange(PRODUCT.SET_IMAGES, images);
  };

  const hideSureModal = () => setShowSureModal(false);

  const onConfirmDelete = () => {
    deleteProduct(state.id)
      .then(() => {
        setLoadingDelete(false);
        setShowSureModal(false);
        showSuccessToast(translate('product.deleteOk'));
        history.goBack();
      })
      .catch(() => {
        setLoadingDelete(false);
        showErrorToast(translate('product.deleteKo'));
      });
  };

  const onSetMain = (index) => {
    const images = [...state.images];

    images.splice(index, 1);

    onChange(PRODUCT.SET_IMAGES, [{ ...state.images[index], isMain: true }, ...images]);
  };

  const onDrop = useCallback((files) => {
    if (files && files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(PRODUCT.SET_IMAGES, state?.images?.length ? [...state.images, { path: reader.result }] : [{ path: reader.result }]);
      };

      reader.readAsDataURL(files[0]);
    }
  }, [state]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const onSave = () => {
    if (state.name) {
      setLoadingUpload(true);
      if (state.id) {
        updateProduct(state)
          .then(() => {
            setLoadingUpload(false);
            showSuccessToast(translate('product.saveOk'));
            history.goBack();
          })
          .catch(() => {
            setLoadingUpload(false);
            showErrorToast(translate('product.saveKo'));
          });
      } else {
        // TODO create product
      }
    }
  };

  const onCategoriesChange = (value, categories) => {
    let added = [...state.addedCategories];
    let removed = [...state.removedCategories];

    const isInCaregories = state.categories.find((c) => c.id === value.id);
    const isInAdded = state.addedCategories.find((c) => c.id === value.id);
    const isInRemoved = state.removedCategories.find((c) => c.id === value.id);

    if (isInCaregories && !isInAdded) {
      // si está en las categorías y no está en las añadidas entonces es una categoría antigua que hay que eliminar
      removed.push(value);
    } else if (isInCaregories && isInAdded) {
      // si está en las categorías y está en las añadidas entonces es una categoría nueva que hay que quitar del array de añadidas
      added = added.filter((c) => c.id !== value.id);
    } else if (!isInCaregories && isInRemoved) {
      // si no está en las categorías y está en las eliminadas entonces es una categoría nueva que hay que quitar del array de eliminadas
      removed = removed.filter((c) => c.id !== value.id);
    } else if (!isInCaregories) {
      // si no está en las categorías entonces hay que agregarla
      added.push(value);
    }

    if (errors) {
      setErrors(null);
    }

    dispatch({ type: PRODUCT.SET_CATEGORIES, value: categories, removed, added });
  };

  const onDelete = () => setShowSureModal(true);

  return (
    <Page id="backoffice-product" grayBackground withLargeSidebar>
      <h2>{translate('backoffice.product')}</h2>
      {
        loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <div className="page-container">
            <div className="product-card">
              <div className="product-images">
                {
                  state?.images?.length && state.images.map((image, index) => {
                    const actions = [
                      {
                        text: translate('common.delete'),
                        icon: 'fas fa-times',
                        onClick: () => onDeleteImage(index),
                      },
                    ];

                    if (0 !== index) {
                      actions.push({
                        text: translate('common.setMain'),
                        icon: 'fas fa-home',
                        onClick: () => onSetMain(index),
                      });
                    }

                    return (
                      <div className="image-container" key={image.url || image.path}>
                        {
                          0 === index && (
                            <Icon icon="fa fa-home" className="main-image" />
                          )
                        }
                        <div className="image-options">
                          <DropdownButton
                            elements={actions}
                            type={BUTTON_TYPES.primary}
                            iconLeft="fas fa-ellipsis-v white-color"
                          />
                        </div>
                        <img src={image.url || image.path} alt={image.url || image.path} />
                      </div>
                    );
                  })
                }
                {
                  (!state?.images || 5 > state?.images?.length) && (
                    <div {...getRootProps()} className="image-dropzone">
                      <input {...getInputProps()} disabled={loading} />
                      <Icon icon="fas fa-camera" />
                    </div>
                  )
                }
              </div>

              <div className="form-container">
                <Input
                  autocomplete={false}
                  disabled={loading}
                  placeholder={translate('common.name')}
                  onChange={(value) => onChange(PRODUCT.SET_NAME, value)}
                  value={state.name}
                />
                <Input
                  disabled
                  placeholder={translate('common.url')}
                  value={state?.url || ''}
                />
                <Input
                  disabled
                  placeholder={translate('common.creator')}
                  value={state?.creator?.name}
                />
                <Input
                  disabled
                  placeholder={translate('common.createdAt')}
                  value={moment(state?.createdAt).format('DD/MM/YYYY - HH:mm')}
                />
                <MultiSearch
                  options={options}
                  selectedValues={state.categories}
                  valueKey="id"
                  onSearch={() => {}}
                  emptyText={translate('common.noItems')}
                  infoText={translate('common.enterToSearch')}
                  onChange={onCategoriesChange}
                />
              </div>
              <Button
                text={translate('common.save')}
                loading={loadingUpload}
                disabled={loadingUpload}
                onClick={onSave}
                className="productButton"
              />
              <Button
                text={translate('backoffice.removeProduct')}
                disabled={loadingUpload}
                onClick={onDelete}
                type={BUTTON_TYPES.secondaryDarker}
                className="productButton"
              />
            </div>
            <div className="search-container">
              <h3>{translate('common.searchProducts')}</h3>
              <Search
                className="product-search"
                optionKey="id"
                disabled={loading}
                loading={loadingSearch}
                allowEmptySearch={false}
                placeholder={translate('common.search')}
                results={products}
                showSelected={false}
                onSearch={onSearch}
                customResultOption={renderResult}
                loadingFormat={ <Loading />}
                noResultsText={translate('common.noItems')}
              />
            </div>
          </div>
        )
      }
      <SureModal
        {...forms.modals.sure}
        header={translate('product.delete')}
        loading={loadingDelete}
        onAccept={onConfirmDelete}
        onHide={hideSureModal}
        show={showSureModal}
        sureText={translate('common.sureText')}
        >
        <p>
          {translate('product.deleteSure')}
        </p>
        <Input
          disabled
          value={state.id}
          placeholder="Id"
      />
        <Input
          disabled
          value={state.name}
          placeholder={translate('common.name')}
      />

      </SureModal>
    </Page>
  );
};

export default Product;
