const listShops = /* GraphQL */ `
  query ListShops($filter: ModelShopFilterInput, $limit: Int, $nextToken: String) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
      }
      nextToken
    }
  }
`;

const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      logo
    }
  }
`;

export default {
  listShops,
  getShop,
};
