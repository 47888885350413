import { Auth } from 'aws-amplify';
import { USER, COMMON } from '../../utils/dispatchTypes';
import { ROLES, WRONG_PARAMS } from '../../utils/constants';

export const logout = () => (dispatch) => {
  Auth.signOut({ global: true });
  dispatch({ type: COMMON.LOGOUT });
};

export const login = (email, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!email || !password) {
      reject(WRONG_PARAMS);
    } else {
      Auth.signIn(email, password)
        .then((response) => {
          const isSuperadmin = response?.signInUserSession?.idToken?.payload['cognito:groups'].includes(
            ROLES.SUPERADMIN,
          );

          if (isSuperadmin) {
            const user = {
              id: response.attributes.sub,
              name: response.attributes.name,
              email: response.attributes.email,
              picture: response.attributes.picture,
            };

            dispatch({ type: USER.SET_USER, user, userRole: ROLES.SUPERADMIN });
            resolve(response);
          } else {
            dispatch({ type: USER.LOADING_END });
            dispatch(logout());
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const autologin = () => (dispatch) => {
  dispatch({ type: USER.LOADING });

  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((response) => {
        const isSuperadmin = response?.idToken?.payload['cognito:groups'].includes(ROLES.SUPERADMIN);

        if (isSuperadmin) {
          const user = {
            id: response.idToken.sub,
            name: response.idToken.name,
            email: response.idToken.email,
            picture: response.idToken.picture,
          };

          dispatch({ type: USER.SET_USER, user, userRole: ROLES.SUPERADMIN });
          dispatch({ type: USER.LOADING_END });
          resolve(response);
        } else {
          dispatch({ type: USER.LOADING_END });
          dispatch(logout());
          reject();
        }
      })
      .catch((error) => {
        dispatch({ type: USER.LOADING_END });
        dispatch(logout());
        reject(error);
      });
  });
};
