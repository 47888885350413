import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';

import {
  getShopById, createShop, updateShop,
} from '../../../actions/shop';

import Page from '../../../components/base/Page';
import Loading from '../../../components/base/Loading';
import OwnCropper from '../../../components/ownCropper';
import {
  Button, BUTTON_TYPES, Icon, Input,
} from '../../../components/base-comps';

import { translate } from '../../../utils/translate/translator';
import { NEW } from '../../../utils/constants';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../utils/toasts';

import './index.scss';

const Shop = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageToCrop, setimageToCrop] = useState(null);
  const [errors, setErrors] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (id && id !== NEW) {
      getShopById(id)
        .then((shop) => {
          setName(shop?.name);
          setLogo(shop?.logo);
        })
        .catch(() => {
          showErrorToast('common.serverError');
        });
    }
  }, [id]);

  const onDrop = useCallback((files) => {
    if (files && files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setimageToCrop({
          ...files[0],
          previewUrl: reader.result,
        });
      };

      reader.readAsDataURL(files[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const deleteLogo = () => {
    setLogo(null);
    setimageToCrop(null);
  };

  const onCrop = (img) => setLogo(img);

  const checkForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = true;
    }

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
    } else if (id && id !== NEW) {
      setLoading(true);
      updateShop({ name, logo, _id: id })
        .then(() => {
          setLoading(false);
          showSuccessToast(translate('shop.saved'));
          history.goBack();
        })
        .catch(() => {
          showErrorToast(translate('common.serverError'));
          setLoading(false);
        });
    } else {
      setLoading(true);
      createShop({ name, logo })
        .then(() => {
          setLoading(false);
          showSuccessToast(translate('shop.saved'));
          history.goBack();
        })
        .catch(() => {
          showErrorToast(translate('common.serverError'));
          setLoading(false);
        });
    }
  };

  return (
    <Page id="backoffice-shop" grayBackground>
      <div className="shop-card">
        {
          logo ? (
            <div className="image-preview">
              <Button iconLeft="fas fa-trash" disabled={loading} onClick={deleteLogo} type={BUTTON_TYPES.transparent} />
              <img src={logo && logo.url ? logo.url : logo} alt={logo.name} />
            </div>
          ) : (
            <div {...getRootProps()} className="image-dropzone">
              <input {...getInputProps()} disabled={loading} />
              <Icon icon="fas fa-camera" />
            </div>
          )
        }
        <Input
          value={name}
          placeholder={translate('shop.name')}
          onChange={setName}
          error={errors?.name}
          className="shop-name"
          disabled={loading}
        />
        {
          loading ? (
            <Loading />
          ) : (
            <Button
              text={translate('common.save')}
              onClick={checkForm}
              className="save-shop"
              disabled={loading}
            />
          )
        }
      </div>
      <OwnCropper
        showModal={!!imageToCrop && !logo}
        image={imageToCrop}
        onCrop={onCrop}
        width={512}
        height={512}
        aspectRatio={1}
      />
    </Page>
  );
};

export default Shop;
