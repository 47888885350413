import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import { Button } from '../../components/base-comps';

import Page from '../../components/base/Page';
import forms from '../../utils/forms';

import './index.scss';

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Page id="not-found-container" innerPadding grayBackground withNavbar>
      <div className="not-found shadow">
        <img src={logo} alt="Wiset" />
        <div>
          <h1>Lo sentimos</h1>
          <p>No se ha podido encontrar la página que buscas</p>
          <div>
            <Button {...forms.buttons.back} onClick={history.goBack} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default NotFoundPage;
