const createDefaultCategory = /* GraphQL */ `
  mutation CreateDefaultCategory(
    $input: CreateDefaultCategoryInput!
    $condition: ModelDefaultCategoryConditionInput
  ) {
    createDefaultCategory(input: $input, condition: $condition) {
      id
      name
      image
      translation {
        es
        en
      }
    }
  }
`;

const updateDefaultCategory = /* GraphQL */ `
  mutation UpdateDefaultCategory(
    $input: UpdateDefaultCategoryInput!
    $condition: ModelDefaultCategoryConditionInput
  ) {
    updateDefaultCategory(input: $input, condition: $condition) {
      id
      name
      image
      translation {
        es
        en
      }
    }
  }
`;

const deleteDefaultCategory = /* GraphQL */ `
  mutation DeleteDefaultCategory(
    $input: DeleteDefaultCategoryInput!
    $condition: ModelDefaultCategoryConditionInput
  ) {
    deleteDefaultCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export default {
  createDefaultCategory,
  updateDefaultCategory,
  deleteDefaultCategory,
};
