import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';

import { Button, Modal } from '../base-comps';

import 'cropperjs/dist/cropper.css'; // eslint-disable-line

const OwnCropper = ({
  showModal,
  image,
  onCrop,
  width,
  height,
  aspectRatio,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cropperInstance, setCropperInstance] = useState();
  const cropperRef = useRef(null);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const toggleModal = () => setModalOpen(!showModal);

  const cropImage = () => {
    if ('undefined' === typeof cropperInstance) {
      return;
    }

    onCrop({
      url: cropperInstance.getCroppedCanvas({
        width,
        height,
        aspectRatio,
      }).toDataURL('image/jpeg', 0.75),
      name: image.name || image.path,
    });

    toggleModal();
  };

  return (
    <Modal
      show={modalOpen}
      showHeader={false}
      onHide={toggleModal}
      footer={<Button text="Seleccionar" onClick={cropImage} />}
    >
      <Cropper
        aspectRatio={aspectRatio}
        autoCropArea={0}
        cropBoxResizable={false}
        minCropBoxWidth={width}
        minCropBoxHeight={height}
        guides={false}
        dragMode="move"
        preview=".img-preview"
        ref={cropperRef}
        src={image && image.previewUrl}
        onInitialized={(instance) => setCropperInstance(instance)}
        style={{ height: 500, width: '100%' }}
      />
    </Modal>
  );
};

OwnCropper.defaultProps = {
  showModal: false,
  image: null,
  onCrop: () => true,
  width: 1200,
  height: 400,
  aspectRatio: 3 / 1,
};

OwnCropper.propTypes = {
  showModal: PropTypes.bool,
  image: PropTypes.object,
  onCrop: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  aspectRatio: PropTypes.number,
};

export default OwnCropper;
