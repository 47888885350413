export default {
  address: 'address',
  checkbox: 'checkbox',
  datepicker: 'datepicker',
  input: 'input',
  json: 'json',
  minMax: 'minMax',
  selectButton: 'selectButton',
  selector: 'selector',
  dropzone: 'dropzone',
};
