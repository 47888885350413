import dataURLtoBlob from 'blueimp-canvas-to-blob';
import { API } from 'aws-amplify';
import { v4 } from 'uuid';

import shopQueries from './shopQueries';
import shopMutations from './shopMutations';
import { uploadImage, getImageType } from '../../utils/helpers';

export const getAllShops = () =>
  new Promise((resolve, reject) => {
    API.graphql({ query: shopQueries.listShops })
      .then((response) => {
        const { items, nextToken } = response.data.listShops;
        resolve({ items, next: nextToken });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getShopById = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({ query: shopQueries.getShop, variables: { id } })
      .then((response) => {
        const { shop } = response.data;
        resolve(shop);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const createShop = (shop) =>
  new Promise((resolve, reject) => {
    const cleanShop = {
      name: shop.name,
      logo: shop.logo && 'object' === typeof shop.logo ? null : shop.logo,
    };
    const blob = shop.logo && 'object' === typeof shop.logo && shop.logo.url ? dataURLtoBlob(shop.logo.url) : null;
    const type = shop.logo && 'object' === typeof shop.logo && shop.logo.url ? getImageType(shop.logo.url) : null;

    if (blob) {
      const uuid = v4();
      const imageName = `${uuid}.${type}`;

      uploadImage(blob, imageName, type, 512, 512)
        .then((imageURL) => {
          API.graphql({ query: shopMutations.createShop, variables: { input: { ...cleanShop, logo: imageURL } } })
            .then((response) => {
              const { createShop: shopCreated } = response.data;
              resolve(shopCreated);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      API.graphql({ query: shopMutations.createShop, variables: { input: cleanShop } })
        .then((response) => {
          const { createShop: shopCreated } = response.data;
          resolve(shopCreated);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const updateShop = (shop) =>
  new Promise((resolve, reject) => {
    const cleanShop = {
      id: shop.id,
      name: shop.name,
      logo: shop.logo && 'object' === typeof shop.logo ? null : shop.logo,
    };
    const blob = shop.logo && 'object' === typeof shop.logo && shop.logo.url ? dataURLtoBlob(shop.logo.url) : null;
    const type = shop.logo && 'object' === typeof shop.logo && shop.logo.url ? getImageType(shop.logo.url) : null;

    if (blob) {
      const uuid = v4();
      const imageName = `${uuid}.${type}`;
      uploadImage(blob, imageName, type, 512, 512)
        .then((imageURL) => {
          API.graphql({ query: shopMutations.updateShop, variables: { input: { ...cleanShop, logo: imageURL } } })
            .then((response) => {
              const { updateShop: shopUpdated } = response.data;
              resolve(shopUpdated);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    }

    API.graphql({ query: shopMutations.updateShop, variables: { input: cleanShop } })
      .then((response) => {
        const { updateShop: shopUpdated } = response.data;
        resolve(shopUpdated);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteShop = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({ query: shopMutations.deleteShop, variables: { input: { id } } })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const restoreShop = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({ query: shopMutations.restoreShop, variables: { input: { id } } })
      .then((response) => {
        const { restoreShop: shopRestored } = response.data;
        resolve(shopRestored);
      })
      .catch((error) => {
        reject(error);
      });
  });
