import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import GenericFilters from './GenericFilters/GenericFilters';
import GenericTable from './GenericTable/GenericTable';
import { BUTTON_TYPES } from '../Button/Button';
import AlertBox from '../AlertBox/AlertBox';

const GenericBackoffice = ({
  className,
  children,
  objects,
  onSearch,
  previousLoad,
  showFilters,
  translations,
  title,
  ...rest
}) => {
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isLoaded, setIsLoaded] = useState(previousLoad);
  const [modal, setModal] = useState([]);

  useEffect(() => {
    if (children && children.length) {
      setColumns(children.filter(({ props: { hideOnTable } }) => !hideOnTable));
      setFilters(children.filter(({ props: { hideOnFilter } }) => !hideOnFilter));
      setModal(children.filter(({ props: { hideOnModal } }) => !hideOnModal));
    }
  }, [children]);

  return (
    <div className={`generic-backoffice${className ? ` ${className}` : ''}`}>
      {!!title && <h1>{title}</h1>}
      <div className="generic-backoffice-body">
        {showFilters && (
          <GenericFilters
            {...rest}
            filters={filters}
            onSearch={(values) => {
              onSearch(values);
              if (!isLoaded) {
                setIsLoaded(true);
              }
            }}
            translations={translations}
          />
        )}
        {isLoaded ? (
          <GenericTable
            {...rest}
            columns={columns}
            modal={modal}
            objects={objects}
            title={title}
            translations={translations}
          />
        ) : (
          <AlertBox text={translations && translations.noSearch} />
        )}
      </div>
    </div>
  );
};

GenericBackoffice.defaultProps = {
  actionsProps: { width: 10 },
  addButtonProps: {},
  className: null,
  clearButtonProps: { inverted: true, iconLeft: 'fas fa-times', type: BUTTON_TYPES.gray },
  color: BUTTON_TYPES.primary,
  customValidator: null,
  deleteElementFormatter: null,
  deleteModalProps: {},
  editModalProps: {},
  entity: null,
  extraActions: null,
  filterButtonProps: { iconLeft: 'fas fa-search' },
  loading: false,
  loadingFormatter: null,
  loadingModal: false,
  loadingModalFormatter: null,
  objects: [],
  onClear: () => true,
  onDelete: (item, callback) => callback(),
  onSave: (item, callback) => callback(),
  onSearch: () => true,
  previousLoad: false,
  secondaryColor: BUTTON_TYPES.primaryLighter,
  showActions: true,
  showAdd: false,
  showDelete: true,
  showEdit: true,
  showFilters: true,
  tableProps: {},
  title: null,
  translations: {
    accept: 'Accept',
    actions: 'Actions',
    cancel: 'Cancel',
    clear: 'Clear',
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    export: 'Export',
    filter: 'Filter',
    filters: 'Filters',
    no: 'No',
    noItems: 'No items found',
    noSearch: 'No search has been performed yet',
    search: 'Search',
    sureText: 'Are you sure?',
    yes: 'Yes',
  },
  withShadow: true,
};

GenericBackoffice.propTypes = {
  actionsProps: PropTypes.object,
  addButtonProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  clearButtonProps: PropTypes.object,
  color: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  customValidator: PropTypes.func,
  deleteElementFormatter: PropTypes.func,
  deleteModalProps: PropTypes.object,
  editModalProps: PropTypes.object,
  entity: PropTypes.string,
  extraActions: PropTypes.func,
  filterButtonProps: PropTypes.object,
  loading: PropTypes.bool,
  loadingFormatter: PropTypes.func,
  loadingModal: PropTypes.bool,
  loadingModalFormatter: PropTypes.func,
  objects: PropTypes.array,
  onClear: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  previousLoad: PropTypes.bool,
  secondaryColor: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  showActions: PropTypes.bool,
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showFilters: PropTypes.bool,
  tableProps: PropTypes.object,
  title: PropTypes.string,
  translations: PropTypes.object,
  withShadow: PropTypes.bool,
};

export default GenericBackoffice;
