import { API } from 'aws-amplify';

import productQueries from './productQueries';
import productMutations from './productMutations';

import formatter from '../../utils/formatters/validateProductFormatter';
import { uploadImages } from '../../utils/helpers';

export const searchProducts = ({ searchText, next, currentProduct }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productQueries.listProducts,
      variables: {
        filter: {
          and: [
            { id: { ne: currentProduct } },
            {
              or: [
                {
                  public: { eq: true },
                },
                {
                  public: { eq: false },
                },
              ],
            },
            {
              or: [
                {
                  name: { contains: searchText },
                },
                {
                  searchName: { contains: searchText },
                },
                {
                  url: { contains: searchText },
                },
              ],
            },
          ],
        },
        nextToken: next,
      },
    })
      .then((response) => {
        const { items, nextToken } = response.data.listProducts;

        resolve({ items, nextToken });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProduct = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productQueries.getProduct,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getProduct);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getPendingProducts = (next) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productQueries.listProducts,
      variables: { filter: { public: { eq: false } }, nextToken: next },
    })
      .then((response) => {
        const { items, nextToken } = response.data.listProducts;

        const formattedProducts = formatter.formatArray(items);
        resolve({ products: formattedProducts, nextToken });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProducts = (next) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productQueries.listProducts,
      variables: { nextToken: next },
    })
      .then((response) => {
        const { items, nextToken } = response.data.listProducts;
        const formattedProducts = formatter.formatArray(items);

        resolve({ products: formattedProducts, nextToken });
      })
      .catch((error) => {
        console.warn(error);
        reject(error);
      });
  });

const createProductDefaultCategories = (defaultCategoryID, productID) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productMutations.createProductDefaultCategory,
      variables: { input: { defaultCategoryID, productID } },
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

const deleteProductDefaultCategories = (categoryId, productId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productQueries.listProductDefaultCategorys,
      variables: {
        filter: {
          defaultCategoryID: { eq: categoryId },
          productID: { eq: productId },
        },
      },
    })
      .then((res) => {
        const { id } = res.data.listProductDefaultCategorys.items[0];
        API.graphql({
          query: productMutations.removeProductDefaultCategory,
          variables: { input: { id } },
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const validateProduct = (product) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const user = getState().user.loggedUser;

    const cleanProduct = {
      id: product.id,
      name: product.name,
      searchName: product.name.toLowerCase(),
      images: product.images || [],
      public: true,
      url: product.url,
      validatorID: user.id,
    };

    if (product.images.length) {
      uploadImages(product.images)
        .then((response) => {
          cleanProduct.images = response;

          API.graphql({
            query: productMutations.updateProduct,
            variables: { input: cleanProduct },
          })
            .then(({ data }) => {
              const { updateProduct: updatedProduct } = data;

              product.categories.forEach((category) => {
                createProductDefaultCategories(category.id, updatedProduct.id);
              });

              resolve(updatedProduct);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      API.graphql({
        query: productMutations.updateProduct,
        variables: { input: cleanProduct },
      })
        .then(({ data }) => {
          const { updateProduct: updatedProduct } = data;

          product.categories.forEach((category) => {
            createProductDefaultCategories(category.id, updatedProduct.id);
          });

          resolve(updatedProduct);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

export const deleteProduct = (productID) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: productMutations.deleteProduct,
      variables: { input: { id: productID } },
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateProduct = (product) =>
  new Promise((resolve, reject) => {
    const cleanProduct = {
      id: product.id,
      name: product.name,
      searchName: product.name.toLowerCase(),
      images: product.images || [],
    };

    if (product.images.length) {
      uploadImages(product.images)
        .then((response) => {
          cleanProduct.images = response;

          API.graphql({
            query: productMutations.updateProduct,
            variables: { input: cleanProduct },
          })
            .then(({ data }) => {
              const { updateProduct: updatedProduct } = data;

              product.addedCategories.forEach((category) => {
                createProductDefaultCategories(category.id, updatedProduct.id);
              });

              product.removedCategories.forEach((category) => {
                deleteProductDefaultCategories(category.id, updatedProduct.id);
              });

              resolve(updatedProduct);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      API.graphql({
        query: productMutations.updateProduct,
        variables: { input: cleanProduct },
      })
        .then(({ data }) => {
          const { updateProduct: updatedProduct } = data;

          product.addedCategories.forEach((category) => {
            createProductDefaultCategories(category.id, updatedProduct.id);
          });

          product.removedCategories.forEach((category) => {
            deleteProductDefaultCategories(category.id, updatedProduct.id);
          });

          resolve(updatedProduct);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
