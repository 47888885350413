import moment from 'moment';

// (int) The current year
export const THIS_YEAR = Number(new Date().getFullYear());

// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = Number(new Date().getMonth()) + 1;

// Week days names and shortnames
export const WEEK_DAYS = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
};

// Calendar months names and shortnames
export const CALENDAR_MONTHS = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

// Weeks displayed on calendar
export const CALENDAR_WEEKS = 6;

// Pads a string value with leading zeroes(0) until length is reached
// For example: zeroPad(5, 2) => "05"
export const zeroPad = (value, length) => `${value}`.padStart(length, '0');

// (int) Number days in a month for a given year from 28 - 31
export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = 0 === year % 4;

  if (2 === month) {
    return leapYear ? 29 : 28;
  }

  return months30.includes(month) ? 30 : 31;
};

// (int) First day of the month for a given year from 1 - 7
// 1 => Sunday, 7 => Saturday
export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR, daysToAdd) => {
  let res = Number(new Date(`${year}-${zeroPad(month, 2)}-01`).getDay()) + 1 - daysToAdd;
  if (1 > res) {
    res = 7 + res;
  }
  return res;
};

// (bool) Checks if a value is a date - this is just a simple check
export const isDate = (date) => {
  const isDateType = '[object Date]' === Object.prototype.toString.call(date);
  const isValidDate = date && !Number.isNaN(date.valueOf());

  return isDateType && isValidDate;
};

// (bool) Checks if two date values are of the same month and year
export const isSameMonth = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false;

  const basedateMonth = Number(basedate.getMonth()) + 1;
  const basedateYear = basedate.getFullYear();

  const dateMonth = Number(date.getMonth()) + 1;
  const dateYear = date.getFullYear();

  return Number(basedateMonth) === Number(dateMonth) && Number(basedateYear) === Number(dateYear);
};

// (bool) Checks if two date values are the same day
export const isSameDay = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false;

  const basedateDate = basedate.getDate();
  const basedateMonth = Number(basedate.getMonth()) + 1;
  const basedateYear = basedate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = Number(date.getMonth()) + 1;
  const dateYear = date.getFullYear();

  return (
    Number(basedateDate) === Number(dateDate) &&
    Number(basedateMonth) === Number(dateMonth) &&
    Number(basedateYear) === Number(dateYear)
  );
};

// (string) Formats the given date as YYYY-MM-DD
// Months and Days are zero padded
export const getDateISO = (date = new Date()) => {
  if (!isDate(date)) return null;

  return [date.getFullYear(), zeroPad(Number(date.getMonth()) + 1, 2), zeroPad(Number(date.getDate()), 2)].join('-');
};

// ({month, year}) Gets the month and year before the given month and year
// For example: getPreviousMonth(1, 2000) => {month: 12, year: 1999}
// while: getPreviousMonth(12, 2000) => {month: 11, year: 2000}
export const getPreviousMonth = (month, year) => {
  const prevMonth = 1 < month ? month - 1 : 12;
  const prevMonthYear = 1 < month ? year : year - 1;

  return { month: prevMonth, year: prevMonthYear };
};

// ({month, year}) Gets the month and year after the given month and year
// For example: getNextMonth(1, 2000) => {month: 2, year: 2000}
// while: getNextMonth(12, 2000) => {month: 1, year: 2001}
export const getNextMonth = (month, year) => {
  const nextMonth = 12 > month ? month + 1 : 1;
  const nextMonthYear = 12 > month ? year : year + 1;

  return { month: nextMonth, year: nextMonthYear };
};

// Calendar builder for a month in the specified year
// Returns an array of the calendar dates.
// Each calendar date is represented as an array => [YYYY, MM, DD]
export default (month = THIS_MONTH, year = THIS_YEAR, daysToAdd = 0) => {
  // Get number of days in the month and the month's first day

  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDay(month, year, 0 <= daysToAdd && 7 > daysToAdd ? daysToAdd : 0);

  // Get number of days to be displayed from previous and next months
  // These ensure a total of 42 days (6 weeks) displayed on the calendar

  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth = CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);

  // Get the previous and next months and years

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

  // Get number of days in previous month
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  // Builds dates to be displayed from previous month

  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const arrayMonth = zeroPad(prevMonth, 2);
    const day = zeroPad(index + 1 + (prevMonthDays - daysFromPrevMonth), 2);
    const stringDate = `${prevMonthYear}/${arrayMonth}/${day}`;
    return [prevMonthYear, arrayMonth, day, moment(stringDate), stringDate];
  });

  // Builds dates to be displayed from current month

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const arrayMonth = zeroPad(month, 2);
    const day = zeroPad(index + 1, 2);
    const stringDate = `${year}/${arrayMonth}/${day}`;
    return [year, arrayMonth, day, moment(stringDate), stringDate];
  });

  // Builds dates to be displayed from next month

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const arrayMonth = zeroPad(nextMonth, 2);
    const day = zeroPad(index + 1, 2);
    return [nextMonthYear, arrayMonth, day, moment(`${nextMonthYear}/${arrayMonth}/${day}`)];
  });

  // Combines all dates from previous, current and next months
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};

export const initialLocale = {
  firstDayOfWeek: 0,
  dayNamesShort: Object.values(WEEK_DAYS),
  dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthNamesShort: Object.values(CALENDAR_MONTHS),
};

export const getLocale = (locale) => {
  if (locale) {
    const res = {
      ...locale,
      firstDayOfWeek: locale.firstDayOfWeek || 0,
      dayNames: [],
      dayNamesShort: [],
      dayNamesMin: [],
      monthNames: [],
      monthNamesShort: [],
      monthSelector: [],
      monthSelectorShort: [],
    };

    // Days
    for (let i = 0; 7 > i; i = i + 1) {
      let idx = i + res.firstDayOfWeek;
      if (6 < idx) {
        idx = idx - 7;
      }

      res.dayNamesShort.push(
        (locale.dayNamesShort && locale.dayNamesShort.length && locale.dayNamesShort[idx]) ||
          initialLocale.dayNamesShort[idx],
      );
      res.dayNamesMin.push(
        (locale.dayNamesMin && locale.dayNamesMin.length && locale.dayNamesMin[idx]) || initialLocale.dayNamesMin[idx],
      );
    }

    // Months
    for (let i = 0; 12 > i; i = i + 1) {
      const month =
        (locale.monthNames && locale.monthNames.length && locale.monthNames[i]) || initialLocale.monthNames[i];
      const monthShort =
        (locale.monthNamesShort && locale.monthNamesShort.length && locale.monthNamesShort[i]) ||
        initialLocale.monthNamesShort[i];

      res.monthNames.push(month);
      res.monthNamesShort.push(monthShort);
      res.monthSelector.push({ label: `${month}`, value: i + 1 });
      res.monthSelectorShort.push({ label: `${monthShort}`, value: i + 1 });
    }

    return res;
  }
  return initialLocale;
};

export const DATEPICKER_MODES = {
  single: 'single',
  multiple: 'multiple',
  range: 'range',
};

export const DATE_FORMATTER = 'YYYY/MM/DD';
