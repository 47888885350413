import { combineReducers } from 'redux';

import general from './general/General';
import user from './user/User';

const rootReducer = combineReducers({
  general,
  user,
});

export default rootReducer;
