import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, BUTTON_TYPES } from '../base-comps';
import logo from '../../assets/images/logo.png';

import SidebarLink from './sidebarLink';

import { logout } from '../../actions/user';

import { translate } from '../../utils/translate/translator';
import { sortArray } from '../../utils/functions';
import { userIsSuperadmin } from '../../utils/roles';
import {
  BACKOFFICE_SHOPS,
  BACKOFFICE_VALIDATE,
  BACKOFFICE_INDEX_URL,
  BACKOFFICE_DEFAULT_CATEGORIES,
  INDEX_URL,
  BACKOFFICE_PRODUCTS,
} from '../../utils/pagesUrls';

const ELEMENTS = [];

const SUPERADMIN_ELEMS = [
  {
    text: 'Tiendas',
    icon: 'fas fa-store',
    url: BACKOFFICE_SHOPS,
  },
  {
    text: 'Validar productos',
    icon: 'fas fa-check-square',
    url: BACKOFFICE_VALIDATE,
  },
  {
    text: 'Categorías',
    icon: 'fas fa-tag',
    url: BACKOFFICE_DEFAULT_CATEGORIES,
  },
  {
    text: 'Productos',
    icon: 'fas fa-archive',
    url: BACKOFFICE_PRODUCTS,
  },
];

const Sidebar = ({ userRole }) => {
  const [expanded, setExpanded] = useState(false);
  const [elements] = useState(
    sortArray(userIsSuperadmin(userRole) ? [...ELEMENTS, ...SUPERADMIN_ELEMS] : ELEMENTS, 'text'),
  );
  const dispatch = useDispatch();

  return (
    <div className={`main-sidebar ${expanded ? 'expanded' : ''}`}>
      <Button
        className="arrow-button"
        iconLeft="fas fa-arrow-right"
        onClick={() => setExpanded(!expanded)}
        type={BUTTON_TYPES.primary}
      />
      <div className="sidebar-header">
        <Button to={BACKOFFICE_INDEX_URL} className="home">
          <img src={logo} alt={translate('common.brandName')} />
          <span className="fadein">{translate('common.brandName')}</span>
        </Button>
        <Button
          className="expand-button"
          iconLeft="fas fa-bars"
          onClick={() => setExpanded(!expanded)}
          type={BUTTON_TYPES.primary}
        />
      </div>
      <div className="sidebar-content">
        <div>
          {elements.map((elem) => (
            <SidebarLink key={elem.text} {...elem} onClick={() => setExpanded(false)} />
          ))}
        </div>
        <div>
          <SidebarLink
            icon="fas fa-sign-out-alt"
            onClick={() => {
              dispatch(logout());
            }}
            text={translate('navbar.logout')}
            url={INDEX_URL}
          />
        </div>
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  userRole: null,
};

Sidebar.propTypes = {
  userRole: PropTypes.string,
};

export default Sidebar;
