import React from 'react';
import PropTypes from 'prop-types';
import { Button, BUTTON_TYPES } from '../../base-comps';

const Card = ({
  disabled, image, title, url,
}) => (
  <div className="backoffice-card">
    <Button
      disabled={disabled}
      style={image ? { backgroundImage: `url(${image})` } : null}
      to={url}
      type={BUTTON_TYPES.transparent}
    >
      <div className="backoffice-card-title">
        <p>{title}</p>
      </div>
    </Button>
  </div>
);

Card.defaultProps = {
  disabled: false,
  image: null,
};

Card.propTypes = {
  disabled: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Card;
