import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TableHeader from './TableHeader/TableHeader';
import TableCell from './TableCell/TableCell';
import Button, { BUTTON_TYPES } from '../Button/Button';
import Input from '../Input/Input';
import Selector from '../Selector/Selector';
import Checkbox from '../Checkbox/Checkbox';
import AlertBox from '../AlertBox/AlertBox';
import ErrorBox from '../ErrorBox/ErrorBox';
import Paginator from '../Paginator/Paginator';

import './index.scss';

const getFieldValue = (element, field) => {
  let res = element;

  if (field && -1 < field.indexOf('.')) {
    field.split('.').forEach((fld) => (res = res && res[fld]));
  } else {
    res = element[field];
  }

  return res;
};

const formatElements = (elements, columns) => {
  const formatted = [];

  if (elements && elements.length && columns && columns.length) {
    elements.forEach((element) => {
      // Create a __search object with the value of the values for searching
      const elem = { __search: {} };
      columns.forEach(({ field, filterFormatted, formatter }) => {
        elem[field] = 'function' === formatter ? formatter(element[field], element) : getFieldValue(element, field);
        elem.__search[field] = filterFormatted && formatter ? formatter(element[field], element) : getFieldValue(element, field);
      });
      formatted.push({ ...elem, __original: element });
    });
  }

  return formatted;
};

/**
 * Gets the element to compare
 * @param {*} element
 * @param {*} parameter
 */
const getComparator = (element, param) => {
  if (!param) {
    return element;
  }

  const parameter = param && -1 < param.indexOf('.') ? param.split('.') : param;

  if (Array.isArray(parameter)) {
    for (let i = 0, { length } = parameter; i < length; i = i + 1) {
      const elem = parameter[i];
      if ('object' === typeof element && elem in element) {
        element = element[elem];
      } else {
        return null;
      }
    }
    return element;
  }
  if (element && parameter) {
    return element[parameter];
  }
  return null;
};

/**
 * Sorts an array of elements by a param
 * @param {Array} elements
 * @param {String} param
 * @param {Boolean} descending
 */
const sortArray = (elements, param, descending) => {
  let sortedElems = [];
  if (elements && elements.length) {
    sortedElems = [...elements];
    let elemA = null;
    let elemB = null;
    sortedElems.sort((a, b) => {
      elemA = getComparator(a, param);
      elemB = getComparator(b, param);

      if (elemA === elemB) {
        return 0;
      }

      const val = (descending && elemA < elemB) || (!descending && elemA > elemB) ? 1 : -1;
      return val;
    });
  }
  return sortedElems;
};

const getCsvElems = (elements, columns) => {
  let res = '';
  const exportableColumns = [];

  // Add headers
  columns.forEach((column) => {
    const { csvHeader, exportCsv, header } = column;
    if (exportCsv) {
      exportableColumns.push(column);

      res = res ? `${res},${csvHeader || header}` : `${csvHeader || header}`;
    }
  });

  let row = '';
  // Add rows
  elements.forEach((element) => {
    row = '';
    exportableColumns.forEach(({ csvFormatter, field }) => {
      if (csvFormatter && 'function' === typeof csvFormatter) {
        row = row ? `${row},${csvFormatter(element[field], element)}` : csvFormatter(element[field], element);
      } else if (row) {
        row = `${row},${element && field ? element[field] : element}`;
      } else {
        row = element && field ? element[field] : element;
      }
    });

    res = res ? `${res}\n${row}` : `${row}`;
  });

  return res;
};

const generateCsv = (elements, columns, fileName) => {
  const csvContent = `data:text/csv;charset=utf-8, ${getCsvElems(elements, columns)}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName || 'data'}.csv`);
  document.body.appendChild(link);

  link.click();
};

const applyGeneralFilter = (element, generalFilter) => element &&
  element.__search &&
  Object.values(element.__search).some(
    (value) => value &&
      -1 <
        value
        .toString()
        .toLowerCase()
        .indexOf(generalFilter.toLowerCase()),
  );

const filterElements = (elements, filter, generalFilter) => {
  if (filter && Object.values(filter).length) {
    let filtered = true;

    return [...elements].filter((el) => {
      filtered = true;

      Object.keys(filter).forEach((key) => {
        if (
          el.__search &&
          -1 ===
            el.__search[key]
            .toString()
            .toLowerCase()
            .indexOf(filter[key].toLowerCase())
        ) {
          filtered = false;
        }
      });

      if (filtered && generalFilter) {
        filtered = applyGeneralFilter(el, generalFilter);
      }
      return filtered;
    });
  }

  if (generalFilter) {
    return elements.filter((el) => applyGeneralFilter(el, generalFilter));
  }

  return [...elements];
};

const getDisplayedElements = (elements, start, numberOfItems, sort) => {
  if (sort && sort.column && (sort.ascending || false === sort.ascending)) {
    return sortArray(elements, sort.column, !sort.ascending).slice(start, start + numberOfItems);
  }
  return elements.slice(start, start + numberOfItems);
};

const Table = ({
  alertProps,
  children,
  className,
  columnSelectorText,
  csvFileName,
  defaultSort,
  elements,
  exportButtonProps,
  firstType,
  globalSearchProps,
  headerExtra,
  loading,
  loadingFormatter,
  minWidth,
  noItemsFormatter,
  noItemsText,
  numberOfItems,
  onSelectRow,
  paginatorProps,
  rowsPerPageOptions,
  secondType,
  selectableCols,
  selectableRows,
  showExport,
  showGlobalSearch,
  showPageSize,
  showPaginator,
  showTotal,
  totalFormatter,
  totalText,
  wrapCells,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredElems, setFilteredElems] = useState([]);
  const [formattedElems, setFormattedElems] = useState([]);
  const [filter, setFilter] = useState({});
  const [generalFilter, setGeneralFilter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(numberOfItems);
  const [keyColumn, setKeyColumn] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [rowsOptions, setRowsOptions] = useState([]);
  const [selectedAllRows, setSelectedAllRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectableColumns, setSelectableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [sort, setSort] = useState(defaultSort);
  const [totalFiltered, setTotalFiltered] = useState(null);

  const isFiltered = (filter && 0 < Object.keys(filter).length) || generalFilter;
  const hasColumns = selectedColumns && 0 < selectedColumns.length;
  const hasElements = filteredElems && 0 < filteredElems.length;
  const total = isFiltered ? totalFiltered : formattedElems && formattedElems.length;
  const showFooter = showTotal || showPaginator;

  const setNewElements = (start) => {
    const filteredEls = filterElements(formattedElems, filter, generalFilter);
    setTotalFiltered(isFiltered && filteredEls ? filteredEls.length : null);
    setFilteredElems(
      getDisplayedElements(
        filteredEls,
        start,
        showPaginator ? itemsPerPage : elements && elements.length,
        sort || defaultSort,
        filter,
      ),
    );
    if (filteredEls.length <= itemsPerPage) {
      setCurrentPage(1);
    }
  };

  const selectRow = (rowId, element) => {
    const newSelected = { ...selectedRows };

    if (newSelected[rowId]) {
      // Already selected, remove from object
      delete newSelected[rowId];

      if (Object.keys(newSelected).length) {
        setSelectedAllRows(null);
      } else {
        setSelectedAllRows(false);
      }
    } else {
      // Not selected, add to object
      newSelected[rowId] = element;

      if (Object.keys(newSelected).length === filteredElems.length) {
        setSelectedAllRows(true);
      } else {
        setSelectedAllRows(null);
      }
    }
    setSelectedRows(newSelected);
  };

  const selectAllRows = () => {
    if (true !== selectedAllRows) {
      const allRows = {};

      if (filteredElems && filteredElems.length && keyColumn) {
        filteredElems.forEach((elem) => (allRows[elem[keyColumn]] = elem.__original));
      }

      setSelectedAllRows(true);
      setSelectedRows(allRows);
    } else {
      setSelectedAllRows(false);
      setSelectedRows({});
    }
  };

  const updateSelectedColumns = (cols) => {
    if (cols && cols.length) {
      setSelectedColumns(cols);
    } else {
      setSelectedColumns([selectableColumns[0]]);
    }
  };

  useEffect(() => {
    if (selectedColumns && selectedColumns.length) {
      setFormattedElems(
        formatElements(
          elements,
          children.map(({ props }) => props),
        ),
      );
    }
  }, [elements, selectableColumns]);

  useEffect(() => {
    if (children && children.length) {
      const selectedCols = [];
      const newSelectableCols = [];
      let keyCol = null;

      children.forEach(({ props: child }) => {
        const { isKey, field, hidden } = child;
        if (isKey) {
          keyCol = field;
        }

        selectedCols.push(child);

        if (!hidden) {
          newSelectableCols.push(child);
        }
      });
      setKeyColumn(keyCol);
      setSelectedColumns(selectedCols);
      setSelectableColumns(newSelectableCols);
    }
  }, [children.length]);

  useEffect(() => {
    if (mounted) {
      setNewElements((currentPage - 1) * itemsPerPage);

      if (false !== selectedAllRows) {
        setSelectedAllRows(false);
        setSelectedRows({});
      }
    } else if (!mounted) {
      setMounted(true);
    }
  }, [currentPage, sort, filter, generalFilter, formattedElems]);

  useEffect(() => {
    if (mounted && showPaginator && formattedElems && (!filteredElems || filteredElems.length !== itemsPerPage)) {
      if (1 === currentPage) {
        setNewElements(0);
      }
      setCurrentPage(1);
      setSort(null);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    if (mounted && 'function' === typeof onSelectRow) {
      onSelectRow(selectedRows && Object.values(selectedRows));
    }
  }, [selectedRows]);

  useEffect(() => {
    const rpp = [];
    if (rowsPerPageOptions && rowsPerPageOptions.length) {
      rowsPerPageOptions.forEach((rows) => 0 < rows && rpp.push({ label: rows.toString(), value: rows }));
    }

    setRowsOptions(rpp);
  }, [rowsPerPageOptions]);

  return (
    <div className={`wiset-table-container ${firstType}-border${className ? ` ${className}` : ''}`}>
      {(showExport || showGlobalSearch || selectableCols || headerExtra) && (
        <div className={`wiset-table-header ${firstType}-bg`}>
          {showGlobalSearch && (
            <Input {...globalSearchProps} className="wiset-table-search" onChange={setGeneralFilter} />
          )}
          {showExport && (
            <Button
              {...exportButtonProps}
              onClick={() => generateCsv(filterElements(formattedElems, filter, generalFilter), selectedColumns, csvFileName)}
            />
          )}
          {selectableCols && hasColumns && (
            <Selector
              multiselect
              options={selectableColumns}
              value={selectedColumns}
              onChange={(cols) => cols !== selectedColumns && updateSelectedColumns(cols)}
              placeholder={columnSelectorText}
              labelKey="header"
              valueKey="field"
            />
          )}
          {'function' === typeof headerExtra && <div className="wiset-table-header-extra">{headerExtra()}</div>}
        </div>
      )}
      <div className="wiset-table-overflow">
        <table
          className={`wiset-table${showFooter ? '' : ' wiset-table-rounded'}`}
          style={minWidth ? { minWidth } : null}
        >
          {/* HEAD */}
          <thead className={`${firstType}-bg`}>
            <tr>
              {selectableRows && (
                <th style={{ width: '40px' }} className="wiset-table-checkbox">
                  <div>
                    <Checkbox
                      value={selectedAllRows}
                      onClick={selectAllRows}
                      enableHalfChecked
                      backgroundButtonColor={secondType}
                    />
                  </div>
                </th>
              )}
              {hasColumns &&
                selectedColumns.map(
                  ({
                    field, header, hidden, width, ...rest
                  }) => !hidden && (
                  <TableHeader
                    {...rest}
                    key={field}
                    width={width || 100 / selectedColumns.length}
                    activeColor={secondType}
                    currentFilter={filter && filter[field]}
                    currentSort={!!sort && field === sort.column ? sort.ascending : null}
                    onChangeFilter={(val) => setFilter({ ...filter, [field]: val })}
                    onChangeSort={(ascending) => setSort({ column: field, ascending })}
                    text={header}
                  />
                  ),
                )}
            </tr>
          </thead>
          {/* BODY */}
          <tbody>
            {hasColumns &&
              hasElements &&
              keyColumn &&
              !loading &&
              filteredElems.map((element, idx) => (
                <tr
                  key={(keyColumn && element[keyColumn]) || idx}
                  className={`${selectableRows ? 'wiset-table-selectable-row ' : ''}${
                    selectedRows[element[keyColumn]] ? 'wiset-table-selected-row' : ''
                  }`}
                  onClick={() => (selectableRows ? selectRow(element[keyColumn], element.__original) : true)}
                >
                  {selectableRows && (
                    <td style={{ width: '40px' }} className="wiset-table-checkbox">
                      <div>
                        <Checkbox
                          value={selectedRows[element[keyColumn]] || false}
                          onClick={() => selectRow(element[keyColumn], element.__original)}
                        />
                      </div>
                    </td>
                  )}
                  {selectedColumns.map(
                    ({
                      formatter, field, hidden, width, ...rest
                    }) => !hidden && (
                    <TableCell
                      {...rest}
                      key={field}
                      width={width || 100 / selectedColumns.length}
                      formatter={
                            formatter &&
                            'function' === typeof formatter &&
                            formatter(element[field], element.__original)
                          }
                      text={element[field]}
                      wrapped={wrapCells}
                    />
                    ),
                  )}
                </tr>
              ))}
            {mounted && keyColumn && !hasElements && !loading && (
              <tr>
                <td className="alert-cell" colSpan={selectableColumns.length + (selectableRows ? 1 : 0)}>
                  {'function' === typeof noItemsFormatter ? (
                    noItemsFormatter()
                  ) : (
                    <AlertBox {...alertProps} text={noItemsText} />
                  )}
                </td>
              </tr>
            )}
            {loading && (
              <tr>
                <td className="loading-cell" colSpan={selectableColumns.length + (selectableRows ? 1 : 0)}>
                  {'function' === typeof loadingFormatter ? (
                    loadingFormatter()
                  ) : (
                    <div className="wiset-table-loading">Loading...</div>
                  )}
                </td>
              </tr>
            )}
            {mounted && hasColumns && !keyColumn && !loading && (
              <tr>
                <td className="alert-cell" colSpan={selectedColumns.length + (selectableRows ? 1 : 0)}>
                  <ErrorBox text="NO KEY COLUMN PROVIDED" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showFooter && (
        <div className="wiset-table-footer">
          {showTotal && (
            <div className={`wiset-table-footer-total ${secondType}-bg`}>
              {totalFormatter && 'function' === typeof totalFormatter ? (
                totalFormatter(total)
              ) : (
                <p>
                  {total}
                  {' '}
                  {totalText}
                </p>
              )}
            </div>
          )}
          {!!showPaginator && (
            <div className={`wiset-table-footer-paginator ${firstType}-bg`}>
              {itemsPerPage < total && (
                <Paginator
                  {...paginatorProps}
                  maxPages={Math.ceil((total || 1) / itemsPerPage)}
                  onChange={setCurrentPage}
                  currentPage={currentPage}
                />
              )}
              {showPageSize && (
                <Selector
                  onChange={(val) => val && val.value && setItemsPerPage(val.value)}
                  options={rowsOptions}
                  searchable={false}
                  value={{ label: `${itemsPerPage}`, value: itemsPerPage }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  alertProps: {},
  children: null,
  className: null,
  columnSelectorText: 'Columns',
  csvFileName: 'data',
  defaultSort: null,
  elements: null,
  exportButtonProps: {
    iconLeft: 'fas fa-file-excel',
    text: 'Export CSV',
    type: BUTTON_TYPES.secondary,
  },
  firstType: BUTTON_TYPES.primary,
  globalSearchProps: {
    icon: 'fas fa-search',
    placeholder: 'Search',
  },
  headerExtra: null,
  loading: false,
  loadingFormatter: null,
  minWidth: 800,
  noItemsFormatter: null,
  noItemsText: 'No items found',
  numberOfItems: 20,
  onSelectRow: null,
  paginatorProps: {
    pagesToShow: 3,
    type: BUTTON_TYPES.secondary,
  },
  rowsPerPageOptions: [5, 10, 20, 50],
  secondType: BUTTON_TYPES.primaryLight,
  selectableCols: false,
  selectableRows: false,
  showExport: false,
  showGlobalSearch: false,
  showPageSize: true,
  showPaginator: true,
  showTotal: true,
  totalFormatter: null,
  totalText: 'items',
  wrapCells: true,
};

Table.propTypes = {
  alertProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  columnSelectorText: PropTypes.string,
  csvFileName: PropTypes.string,
  defaultSort: PropTypes.shape({
    ascending: PropTypes.bool,
    column: PropTypes.string,
  }),
  elements: PropTypes.array,
  exportButtonProps: PropTypes.object,
  firstType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  globalSearchProps: PropTypes.object,
  headerExtra: PropTypes.func,
  loading: PropTypes.bool,
  loadingFormatter: PropTypes.func,
  minWidth: PropTypes.number,
  noItemsFormatter: PropTypes.func,
  noItemsText: PropTypes.string,
  numberOfItems: PropTypes.number,
  onSelectRow: PropTypes.func,
  paginatorProps: PropTypes.object,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  secondType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  selectableCols: PropTypes.bool,
  selectableRows: PropTypes.bool,
  showExport: PropTypes.bool,
  showGlobalSearch: PropTypes.bool,
  showPageSize: PropTypes.bool,
  showPaginator: PropTypes.bool,
  showTotal: PropTypes.bool,
  totalFormatter: PropTypes.func,
  totalText: PropTypes.string,
  wrapCells: PropTypes.bool,
};

export default Table;
