import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from './components/base-comps';

import Main from './containers/general';
import store from './store';

const App = () => (
  <Provider store={store}>
    <ToastProvider>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ToastProvider>
  </Provider>
);

export default App;
