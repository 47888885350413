const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      images {
        url
        isMain
      }
      name
      url
    }
  }
`;

const createProductDefaultCategory = /* GraphQL */ `
  mutation CreateProductDefaultCategory(
    $input: CreateProductDefaultCategoryInput!
    $condition: ModelProductDefaultCategoryConditionInput
  ) {
    createProductDefaultCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

const removeProductDefaultCategory = /* GraphQL */ `
  mutation DeleteProductDefaultCategory(
    $input: DeleteProductDefaultCategoryInput!
    $condition: ModelProductDefaultCategoryConditionInput
  ) {
    deleteProductDefaultCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export default {
  deleteProduct,
  updateProduct,
  createProductDefaultCategory,
  removeProductDefaultCategory,
};
