import { API } from 'aws-amplify';
import { v4 } from 'uuid';
import dataURLtoBlob from 'blueimp-canvas-to-blob';

import defaultCategoryQueries from './defaultCategoryQueries';
import defaultCategoryMutations from './defaultCategoryMutations';

import { uploadImage, getImageType } from '../../utils/helpers';

export const getAllDefaultCategories = () =>
  new Promise((resolve, reject) => {
    API.graphql({ query: defaultCategoryQueries.listDefaultCategorys })
      .then((response) => {
        const { items } = response.data.listDefaultCategorys;
        resolve({ items });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteDefaultCategory = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: defaultCategoryMutations.deleteDefaultCategory,
      variables: { input: { id } },
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getDefaultCategoryById = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({ query: defaultCategoryQueries.getDefaultCategory, variables: { id } })
      .then((response) => {
        resolve(response.data.getDefaultCategory);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createDefaultCategory = (category) =>
  new Promise((resolve, reject) => {
    const cleanDefaultCategory = {
      name: category.name,
      translation: category.translation,
      image: category.image && 'object' === typeof category.image ? null : category.image,
    };
    const blob =
      category.image && 'object' === typeof category.image && category.image.url
        ? dataURLtoBlob(category.image.url)
        : null;
    const type =
      category.image && 'object' === typeof category.image && category.image.url
        ? getImageType(category.image.url)
        : null;

    // upload image, then create the caregory
    if (blob) {
      const uuid = v4();
      const imageName = `${uuid}.${type}`;

      uploadImage(blob, imageName, type, 720, 1280)
        .then((imageURL) => {
          API.graphql({
            query: defaultCategoryMutations.createDefaultCategory,
            variables: { input: { ...cleanDefaultCategory, image: imageURL } },
          })
            .then((response) => {
              const { createdDefaultCategory: defaultCategoryCreated } = response.data;
              resolve(defaultCategoryCreated);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      API.graphql({
        query: defaultCategoryMutations.createDefaultCategory,
        variables: { input: category },
      })
        .then((response) => {
          const { createdDefaultCategory: defaultCategoryCreated } = response.data;
          resolve(defaultCategoryCreated);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

export const updateDefaultCategory = (category) =>
  new Promise((resolve, reject) => {
    const cleanDefaultCategory = {
      id: category.id,
      name: category.name,
      translation: category.translation,
      image: category.image && 'object' === typeof category.image ? null : category.image,
    };
    const blob =
      category.image && 'object' === typeof category.image && category.image.url
        ? dataURLtoBlob(category.image.url)
        : null;
    const type =
      category.image && 'object' === typeof category.image && category.image.url
        ? getImageType(category.image.url)
        : null;

    // upload image, then create the caregory
    if (blob) {
      const uuid = v4();
      const imageName = `${uuid}.${type}`;

      uploadImage(blob, imageName, type, 720, 1280)
        .then((imageURL) => {
          API.graphql({
            query: defaultCategoryMutations.updateDefaultCategory,
            variables: { input: { ...cleanDefaultCategory, image: imageURL } },
          })
            .then((response) => {
              const { updateDefaultCategory: defaultCategoryUpdated } = response.data;
              resolve(defaultCategoryUpdated);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      API.graphql({
        query: defaultCategoryMutations.updateDefaultCategory,
        variables: { input: category },
      })
        .then((response) => {
          const { updateDefaultCategory: defaultCategoryUpdated } = response.data;
          resolve(defaultCategoryUpdated);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
