import React from 'react';
import PropTypes from 'prop-types';

import Button, { BUTTON_TYPES } from '../Button/Button';
import Modal from '../Modal/Modal';

import './index.scss';

const SureModal = ({
  acceptIcon,
  children,
  className,
  denyIcon,
  header,
  loading,
  loadingFormat,
  noText,
  onAccept,
  onHide,
  showNoButton,
  showYesButton,
  sureText,
  yesText,
  ...rest
}) => (
  <Modal
    className={`sure-modal${className ? ` ${className}` : ''}`}
    footer={(
      <>
        {loading && loadingFormat}
        {!loading && (
          <>
            {showYesButton && <Button disabled={loading} iconLeft={acceptIcon} onClick={onAccept} text={yesText} />}
            {showNoButton && (
              <Button
                disabled={loading}
                iconLeft={denyIcon}
                inverted
                type={BUTTON_TYPES.gray}
                onClick={onHide}
                text={noText}
              />
            )}
          </>
        )}
      </>
    )}
    header={<p className="center-text">{header}</p>}
    onHide={onHide}
    {...rest}
  >
    {children}
    <p className="center-text sure-text">{sureText}</p>
  </Modal>
);

SureModal.defaultProps = {
  acceptIcon: 'fas fa-check',
  children: null,
  className: null,
  denyIcon: 'fas fa-times',
  header: '',
  loading: false,
  loadingFormat: null,
  noText: 'No',
  onAccept: () => true,
  onHide: () => true,
  showNoButton: true,
  showYesButton: true,
  sureText: 'Are you sure?',
  yesText: 'Yes',
};

SureModal.propTypes = {
  acceptIcon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  denyIcon: PropTypes.string,
  header: PropTypes.string,
  loading: PropTypes.bool,
  loadingFormat: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noText: PropTypes.string,
  onAccept: PropTypes.func,
  onHide: PropTypes.func,
  showNoButton: PropTypes.bool,
  showYesButton: PropTypes.bool,
  sureText: PropTypes.string,
  yesText: PropTypes.string,
};

export default SureModal;
