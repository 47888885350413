import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const TableCell = ({
  cellClassName, formatter, text, width, wrapped,
}) => (
  <td
    style={width && 0 < width ? { width: `${width}%` } : null}
    className={`${cellClassName}${wrapped ? '' : ' wiset-table-cell-unwrap'}`}
  >
    {formatter || <p>{null !== text && undefined !== text && text.toString && text.toString()}</p>}
  </td>
);

TableCell.defaultProps = {
  cellClassName: null,
  formatter: null,
  text: null,
  width: null,
  wrapped: true,
};

TableCell.propTypes = {
  cellClassName: PropTypes.string,
  formatter: PropTypes.any,
  text: PropTypes.any,
  width: PropTypes.number,
  wrapped: PropTypes.bool,
};

export default TableCell;
