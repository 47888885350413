import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../Button/Button';

import './index.scss';

/**
 * This component is an extension of Button component
 */
export default class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      positionLeft: null,
      positionTop: null,
    };
    // Connections to check if clicks outside the component
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.elementsRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ active: false });
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleScroll = () => this.setState({ active: false });

  onClick = (e) => {
    const { active } = this.state;
    const { bottom, left, width } = e.target.getClientRects()[0];
    const { width: extraWidth } = this.elementsRef.current.getClientRects()[0];

    this.setState({
      active: !active,
      positionTop: bottom + 5,
      positionLeft: left + width / 2 - extraWidth / 2,
    });
  };

  renderElements() {
    const { elements, hideOnClick, onClickParam } = this.props;

    if (elements && 0 < elements.length) {
      return elements.map((elem, idx) => {
        if (elem) {
          const {
            text, icon, inverted, onClick, url, buttonType, disabled,
          } = elem;

          return (
            <Button
              disabled={disabled}
              iconLeft={icon}
              inverted={!inverted}
              key={idx}
              onClick={
                hideOnClick ?
                  () => {
                    if (onClick && 'function' === typeof onClick) {
                      onClick(onClickParam);
                    }
                    this.setState({ active: false });
                  } :
                  onClick
              }
              text={text}
              to={url}
              type={buttonType || BUTTON_TYPES.primary}
            />
          );
        }
        return null;
      });
    }
    return null;
  }

  render() {
    const {
      buttonClassName, children, className, disabled, iconLeft, iconRight, inverted, text, type,
    } = this.props;
    const { active, positionLeft, positionTop } = this.state;

    const positionStyles = {
      position: 'fixed',
      left: positionLeft,
      top: positionTop,
    };

    return (
      <div className="wiset-dropdown" ref={this.setWrapperRef}>
        <Button
          className={buttonClassName}
          disabled={disabled}
          iconLeft={iconLeft}
          iconRight={iconRight}
          inverted={inverted}
          onClick={this.onClick}
          text={text}
          type={type}
        >
          {children}
        </Button>
        <div
          className={`dd-extra fadein${active ? ' active' : ''} ${className ? ` ${className}` : ''}`}
          ref={this.elementsRef}
          style={positionStyles}
        >
          {this.renderElements()}
        </div>
      </div>
    );
  }
}

DropdownButton.defaultProps = {
  buttonClassName: null,
  children: null,
  className: null,
  disabled: false,
  elements: [],
  hideOnClick: true,
  iconLeft: null,
  iconRight: null,
  inverted: false,
  onClickParam: null,
  text: null,
  type: BUTTON_TYPES.primary,
};

DropdownButton.propTypes = {
  buttonClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      url: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ),
  hideOnClick: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inverted: PropTypes.bool,
  onClickParam: PropTypes.any,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
};
