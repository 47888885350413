/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button, { BUTTON_TYPES } from '../Button/Button';
import Icon from '../Icon/Icon';
import ErrorBox from '../ErrorBox/ErrorBox';
import Audio from '../Audio/Audio';
import './index.scss';

const removeFileFromArray = (files, idx) => {
  const newFiles = [...files];
  newFiles.splice(idx, 1);
  return newFiles;
};

const getValidTypes = (accept) => {
  let valid = null;

  if ('*' === accept) {
    valid = true;
  } else if (-1 < accept.indexOf(',')) {
    // multiple file extension
    const extensions = accept.split(',');
    valid = [];

    extensions.forEach((ext) => valid.push(getValidTypes(ext)));
  } else if (-1 < accept.indexOf('/')) {
    // image/*, etc.
    const type = accept.split('/');

    if ('*' === type[1]) {
      [valid] = type;
    } else {
      valid = type[1].replace('.', '');
    }
  } else if (-1 < accept.indexOf('.')) {
    valid = accept.replace('.', '');
  }

  return valid;
};

const isValidType = (validTypes, type) => {
  if (true === validTypes) {
    return true;
  }
  if (!type) {
    return false;
  }
  const [filetype, extension] = type.split('/');
  if (Array.isArray(validTypes)) {
    return validTypes.some((valid) => valid === filetype || valid === extension);
  }
  return validTypes === filetype || validTypes === extension;
};

const Dropzone = ({
  accept,
  addMoreText,
  className,
  disabled,
  icon,
  maxFiles,
  maxFileSize,
  multi,
  onFilesChange,
  sizeErrorText,
  showAddMore,
  showPreview,
  text,
  typeErrorText,
  value,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editing, setEditing] = useState(false);
  const [hover, setHover] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [validTypes, setValidTypes] = useState(null);

  useEffect(() => {
    if (mounted && 'function' === typeof onFilesChange) {
      let files = null;

      if (multi) {
        files = selectedFiles;
      } else if (selectedFiles && selectedFiles.length) {
        [files] = selectedFiles;
      }

      onFilesChange(files);
    } else if (!mounted) {
      setMounted(true);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (!validTypes) {
      setValidTypes(getValidTypes(accept));
    }
  }, [accept]);

  const fileInputRef = createRef();

  const fileListToArray = (list) => {
    const array = [...selectedFiles];
    let completed = !!maxFiles && maxFiles <= array.length;
    let idx = null;

    for (let i = 0; i < list.length && !completed; i = i + 1) {
      const file = list.item(i);
      idx = selectedFiles.findIndex(({ name }) => name === file.name);

      if (!isValidType(validTypes, file.type)) {
        setFileTypeError(true);
      } else if (maxFileSize && maxFileSize < file.size) {
        setMaxSizeError(true);
      } else if (-1 === idx) {
        file.objectURL = URL.createObjectURL(file);
        array.push(file);
      }

      if (maxFiles && maxFiles <= array.length) {
        completed = true;
      }
    }
    return array;
  };

  const clearErrors = () => {
    setMaxSizeError(false);
    setFileTypeError(false);
  };

  const openFileDialog = () => {
    if (disabled || (!multi && selectedFiles && selectedFiles.length)) {
      return;
    }

    fileInputRef.current.click();
  };

  const setFiles = (files) => {
    clearErrors();

    const array = fileListToArray(files);
    if (array && array.length) {
      setSelectedFiles(array);
    }
  };

  const onFilesAdded = (event) => {
    if (disabled) {
      return;
    }

    const { files } = event.target;
    setFiles(files);
  };

  const onDrop = (event) => {
    event.preventDefault();

    if (disabled || (selectedFiles && selectedFiles.length)) {
      return;
    }

    const { files } = event.dataTransfer;
    setFiles(files);
    setHover(false);
  };

  const renderPreviewMulti = (file, idx) => {
    const { name, objectURL, type } = file;
    if (showPreview && -1 < type.indexOf('audio')) {
      return (
        <div key={name} className="audio-multi">
          <Audio file={objectURL} fileType={type} showVolume={false} small />
          <p>{name}</p>
          <Button
            className="clear-multi"
            iconLeft="fas fa-trash-alt"
            onClick={() => setSelectedFiles(removeFileFromArray(selectedFiles, idx))}
            type={BUTTON_TYPES.transparent}
          />
        </div>
      );
    }

    return (
      <div key={name}>
        {!!showPreview && <img src={objectURL} alt={name || idx} />}
        <p>{name}</p>
        <Button
          className="clear-multi"
          iconLeft="fas fa-trash-alt"
          onClick={() => setSelectedFiles(removeFileFromArray(selectedFiles, idx))}
          type={BUTTON_TYPES.transparent}
        />
      </div>
    );
  };

  const showSinglePreview = () => {
    const [{ objectURL, type }] = selectedFiles;

    if (-1 < type.indexOf('audio')) {
      return <Audio file={objectURL} fileType={type} showVolume={false} small />;
    }

    return null;
  };

  return (
    <div className={`wiset-dropzone${disabled ? ' disabled' : ''}${className ? ` ${className}` : ''}`}>
      {value && !value.objectURL && !editing && !multi && showPreview && (
        <div className="wiset-dropzone-preview with-value" style={{ backgroundImage: `url(${value})` }}>
          {!disabled && (
            <Button
              className="cancel-edit-btn gray-color"
              iconLeft="fas fa-edit"
              onClick={() => setEditing(true)}
              type={BUTTON_TYPES.transparent}
            />
          )}
        </div>
      )}
      {(!value || value.objectURL || editing) && selectedFiles && 0 < selectedFiles.length && (
        <div
          className={`wiset-dropzone-preview${multi ? ' multi' : ''}`}
          style={!multi && showPreview ? { backgroundImage: `url(${selectedFiles[0].objectURL})` } : {}}
        >
          {!multi && !disabled && (
            <Button
              className="clear-images"
              iconLeft="fas fa-trash-alt"
              onClick={() => setSelectedFiles([])}
              type={BUTTON_TYPES.transparent}
            />
          )}
          {!multi && !showPreview && <p>{selectedFiles[0].name}</p>}
          {!multi && showPreview && !!selectedFiles[0] && showSinglePreview()}
          {multi && (
            <div className="wiset-multi-dropzone">
              {selectedFiles.map(renderPreviewMulti)}
              {showAddMore && (!maxFiles || maxFiles > selectedFiles.length) && (
                <Button className="wiset-add-more" iconLeft={icon} onClick={openFileDialog} text={addMoreText} />
              )}
            </div>
          )}
        </div>
      )}
      {value && !value.objectURL && editing && (!selectedFiles || 0 >= selectedFiles.length) && (
        <Button
          className="cancel-edit-btn error-color"
          iconLeft="fas fa-times"
          onClick={() => setEditing(false)}
          type={BUTTON_TYPES.transparent}
        />
      )}
      {(!value || editing) && (!selectedFiles || 0 >= selectedFiles.length) && (
        <div
          className={`wiset-dropzone-drop${!disabled && hover ? ' primary-bg primary-lighter-border white-color' : ''}`}
          onClick={openFileDialog}
          onDragOver={(e) => {
            e.preventDefault();
            if (!disabled) {
              setHover(true);
            }
          }}
          onFocus={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseOver={() => setHover(true)}
          onDragLeave={() => setHover(false)}
          onDrop={onDrop}
        >
          {!!icon && <Icon icon={icon} />}
          {!!text && <span>{text}</span>}
        </div>
      )}
      <input
        ref={fileInputRef}
        accept={accept}
        className="file-input"
        disabled={disabled}
        type="file"
        multiple={multi}
        onChange={onFilesAdded}
      />
      {(maxSizeError || fileTypeError) && (
        <div className="wiset-dropzone-error" onClick={clearErrors}>
          <ErrorBox text={fileTypeError ? typeErrorText : sizeErrorText} />
        </div>
      )}
    </div>
  );
};

Dropzone.defaultProps = {
  addMoreText: 'Add more',
  accept: '*',
  className: null,
  disabled: false,
  icon: 'fas fa-plus-square',
  maxFiles: null,
  maxFileSize: null,
  multi: false,
  onFilesChange: null,
  sizeErrorText: 'File too large',
  showAddMore: true,
  showPreview: false,
  text: 'Drop file here',
  typeErrorText: 'Wrong file type',
  value: null,
};

Dropzone.propTypes = {
  addMoreText: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  multi: PropTypes.bool,
  onFilesChange: PropTypes.func,
  showAddMore: PropTypes.bool,
  showPreview: PropTypes.bool,
  sizeErrorText: PropTypes.string,
  text: PropTypes.string,
  typeErrorText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Dropzone;
