export const COMMON = {
  LOADING: 'COMMON_LOADING',
  LOADING_END: 'COMMON_LOADING_END',
  SET_ERROR: 'COMMON_SET_ERROR',
  LOGOUT: 'COMMON_LOGOUT',
};

export const USER = {
  LOADING: 'USER_LOADING',
  LOADING_END: 'USER_LOADING_END',
  SET_USER: 'USER_SET_USER',
};

export const PRODUCT = {
  SET_NAME: 'PRODUCT_SET_NAME',
  SET_CATEGORIES: 'PRODUCT_SET_CATEGORIES',
  SET_IMAGES: 'PRODUCT_SET_IMAGES',
  SET_URL: 'PRODUCT_SET_URL',
  SET_PRODUCT: 'PRODUCT_SET_PRODUCT',
};

export const DEFAULT_CATEGORY = {
  SET_NAME: 'DEFAULT_CATEGORY_SET_NAME',
  SET_IMAGE: 'DEFAULT_CATEGORY_SET_IMAGE',
  SET_DEFAULT_CATEGORY: 'DEFAULT_CATEGORY_SET_DEFAULT_CATEGORY',
  SET_TRANSLATION_ES: 'DEFAULT_CATEGORY_SET_TRANSLATION_ES',
  SET_TRANSLATION_EN: 'DEFAULT_CATEGORY_SET_TRANSLATION_EN',
};
