import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../Button/Button';
import './index.scss';

const MonthSelector = ({
  className,
  date,
  dateFormat,
  locale,
  maxDate,
  minDate,
  onMonthClick,
  selectOtherYears,
  style,
}) => {
  const [currentMoment, setCurrentMoment] = useState(moment());
  const [currentYear, setCurrentYear] = useState(null);
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);

  const today = moment();

  const updateCalendar = (newDate) => {
    let newMonth = null;
    let newYear = null;

    if (newDate && newDate.isValid()) {
      newMonth = newDate.get('month') + 1;
      newYear = newDate.get('year');
    } else {
      newMonth = today.get('month') + 1;
      newYear = today.get('year');
    }
    setCurrentYear(newYear);
    setCurrentMoment(moment(`${newYear}/${newMonth}/01`));
  };

  useEffect(() => {
    const oldDate = currentMoment?.format ? currentMoment.format(dateFormat) : dateFormat;
    const newDate = date?.format ? date.format() : date;
    if (oldDate !== newDate) {
      updateCalendar(date);
    }
  }, [date]);

  useEffect(() => {
    let newMax = null;
    let newMin = null;
    if ('object' === typeof maxDate && maxDate?.isValid()) {
      newMax = maxDate;
    } else if (maxDate) {
      const momentMax = maxDate && moment(maxDate);
      if (momentMax.isValid()) {
        newMax = momentMax;
      }
    }

    if ('object' === typeof minDate && minDate?.isValid()) {
      newMin = minDate;
    } else if (minDate) {
      const momentMin = minDate && moment(minDate);
      if (momentMin.isValid()) {
        newMin = momentMin;
      }
    }

    if (newMax?.isBefore(currentMoment)) {
      updateCalendar(newMax);
    } else if (newMin?.isAfter(currentMoment)) {
      updateCalendar(newMin);
    }

    setMax(newMax);
    setMin(newMin);
  }, [maxDate, minDate]);

  // Is before min date or after max date
  const getIsDisabled = (momentDate) => min?.isAfter(momentDate, 'month') || max?.isBefore(momentDate, 'month');

  const prevYear = () => {
    setCurrentMoment(currentMoment.clone().subtract(1, 'year'));
    setCurrentYear(currentYear - 1);
  };

  const nextYear = () => {
    setCurrentMoment(currentMoment.clone().add(1, 'year'));
    setCurrentYear(currentYear + 1);
  };

  return (
    <div className={`wiset-month-selector${className ? ` ${className}` : ''}`}>
      <div style={style}>
        <div className="wiset-month-btns primary-bg white-color">
          {selectOtherYears && (
            <Button
              disabled={min && min.isSameOrAfter(currentMoment, 'month')}
              iconLeft="fas fa-chevron-left"
              onClick={prevYear}
            />
          )}
          <span className="year-selector">{currentYear}</span>
          {selectOtherYears && (
            <Button
              disabled={max?.isSameOrBefore(currentMoment, 'month')}
              iconLeft="fas fa-chevron-right"
              onClick={nextYear}
            />
          )}
        </div>
        <div className="months-container">
          {locale.monthNamesShort.map((month) => {
            const momentMonth = moment(`${currentYear}/${month}/01`);
            const stringMonth = momentMonth.format(dateFormat);
            const disabled = getIsDisabled(momentMonth);

            return (
              <Button
                key={month}
                className={`month${
                  date && stringMonth === date.format(dateFormat) ? ' selected primary-bg white-color' : ''
                }`}
                disabled={disabled}
                disableType
                onClick={() => onMonthClick(momentMonth, stringMonth)}
                inverted
                text={month}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

MonthSelector.defaultProps = {
  className: null,
  date: null,
  dateFormat: 'YYYY/MM/DD',
  locale: undefined,
  maxDate: null,
  minDate: null,
  selectOtherYears: true,
  style: undefined,
};

MonthSelector.propTypes = {
  className: PropTypes.string,
  date: PropTypes.any,
  dateFormat: PropTypes.string,
  locale: PropTypes.object,
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onMonthClick: PropTypes.func.isRequired,
  selectOtherYears: PropTypes.bool,
  style: PropTypes.object,
};

export default MonthSelector;
