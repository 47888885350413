/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Calendar from './Calendar/Calendar';
import MonthSelector from './MonthSelector/MonthSelector';
import Input from '../Input/Input';
import './index.scss';
import {
  initialLocale, DATEPICKER_MODES, getLocale, DATE_FORMATTER,
} from './helpers';

export const SELECTION_MODE = DATEPICKER_MODES;

const Datepicker = ({
  className,
  clearable,
  dateFormat,
  disabled,
  error,
  errorText,
  icon,
  infoText,
  locale,
  maxDateCount,
  monthSelector,
  onChange,
  placeholder,
  readOnlyInput,
  selectionMode,
  showSeconds,
  showTime,
  timeOnly,
  value,
  ...rest
}) => {
  const [calendarWidth, setCalendarWidth] = useState(null);
  const [currentLocale, setCurrentLocale] = useState(initialLocale);
  const [hourValue, setHourValue] = useState(null);
  const [monthValue, setMonthValue] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [multipleValue, setMultipleValue] = useState({ momentValue: [], stringValue: [] });
  const [rangeValue, setRangeValue] = useState({});
  const [stateValue, setStateValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const outsideRef = useRef();
  const hourFormat = `HH:mm${showSeconds ? ':ss' : ''}`;

  const divRef = useCallback((node) => {
    if (node && node.getBoundingClientRect) {
      setCalendarWidth(node.getBoundingClientRect().width);
    }
  });

  const handleClick = (event) => {
    if (
      (outsideRef.current && !outsideRef.current.contains(event.target)) ||
      -1 !== event.target.className.indexOf('wiset-calendar')
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    if (locale) {
      setCurrentLocale(getLocale(locale));
    }
  }, [locale]);

  useEffect(() => {
    if (monthSelector && value) {
      const mom = value?.format ? value : moment(value, DATE_FORMATTER);

      if (mom.format(DATE_FORMATTER) !== monthValue?.stringValue) {
        setMonthValue({ momentValue: mom, stringValue: mom.format(DATE_FORMATTER) });
      }
    } else if (timeOnly && value) {
      const hr = value?.format ? value : moment(value, hourFormat);

      if (hr.isValid() && hr.format(hourFormat) !== hourValue) {
        setHourValue(hr.format(hourFormat));
      }
    } else if (selectionMode === SELECTION_MODE.multiple && value?.length) {
      const newMultiple = { momentValue: [], stringValue: [] };
      let hasDiff = false;
      value.forEach((val, idx) => {
        const str = val?.format ? val.format(DATE_FORMATTER) : val;
        if (!hasDiff && multipleValue?.stringValue?.[idx] !== str) {
          hasDiff = true;
        }

        newMultiple.momentValue.push(val.format ? val : moment(val, DATE_FORMATTER));
        newMultiple.stringValue.push(str);
      });

      if (hasDiff) {
        setMultipleValue(newMultiple);
      }
    } else if (selectionMode === SELECTION_MODE.range && value?.length && 3 > value.length) {
      const [start, end] = value;
      const newRange = { ...rangeValue };

      if (start?.format && start.format(DATE_FORMATTER) !== newRange.startValue?.string) {
        newRange.startValue = { moment: start, string: start.format(DATE_FORMATTER) };
      }
      if (end?.format && end.format(DATE_FORMATTER) !== newRange.endValue?.string) {
        newRange.endValue = { moment: end, string: end.format(DATE_FORMATTER) };
      }

      if (
        newRange.startValue?.string !== rangeValue.startValue?.string ||
        newRange.endValue?.string !== rangeValue.endValue?.string
      ) {
        setRangeValue(newRange);
      }
    } else if (!timeOnly && !monthSelector && selectionMode === SELECTION_MODE.single && value) {
      const val = value.format ? value : moment(value, DATE_FORMATTER);

      if (!stateValue?.stringValue || val.format(DATE_FORMATTER) !== stateValue.stringValue) {
        setStateValue({ momentValue: val, stringValue: val.format(DATE_FORMATTER) });
      }
      if (showTime) {
        setHourValue(val.format(hourFormat));
      }
    } else if (mounted && !value) {
      setHourValue(null);
      setMonthValue(null);
      setMultipleValue({ momentValue: [], stringValue: [] });
      setRangeValue(null);
      setStateValue(null);
    }
  }, [value]);

  useEffect(() => {
    // On change for single mode
    if (mounted && selectionMode === SELECTION_MODE.single && 'function' === typeof onChange) {
      const formatter = timeOnly || showTime ? `${DATE_FORMATTER} ${hourFormat}` : DATE_FORMATTER;
      let momentRes = timeOnly || !stateValue?.stringValue ?
        moment(hourValue, hourFormat) :
        moment(`${stateValue.stringValue} ${hourValue || '00:00'}`, formatter);

      if (!timeOnly && !showTime) {
        momentRes = stateValue?.momentValue;
      }

      if (!momentRes || (momentRes.isValid && momentRes.isValid())) {
        onChange(momentRes, momentRes?.format(formatter));
      }
    } else if (!mounted) {
      setMounted(true);
    }
  }, [stateValue, hourValue]);

  useEffect(() => {
    // On change for range mode
    if (mounted && selectionMode === SELECTION_MODE.range && 'function' === typeof onChange) {
      const momentVal = [];
      const stringVal = [];

      if (rangeValue?.startValue?.moment) {
        momentVal.push(rangeValue.startValue.moment);
        stringVal.push(rangeValue.startValue.string);
      }
      if (rangeValue?.endValue?.moment) {
        momentVal.push(rangeValue.endValue.moment);
        stringVal.push(rangeValue.endValue.string);
      }

      onChange(momentVal, stringVal);
    }
  }, [rangeValue]);

  useEffect(() => {
    // On change for multiple mode
    if (mounted && selectionMode === SELECTION_MODE.multiple && 'function' === typeof onChange) {
      onChange(multipleValue.momentValue, multipleValue.stringValue);
    }
  }, [multipleValue]);

  const onDayClick = (momentValue, stringValue) => {
    if (monthSelector) {
      setMonthValue({ momentValue, stringValue });
    } else if (selectionMode === SELECTION_MODE.range) {
      let newRange = { ...rangeValue };
      if (
        momentValue &&
        rangeValue &&
        rangeValue.startValue &&
        !rangeValue.endValue &&
        rangeValue.startValue.moment.isBefore(momentValue)
      ) {
        newRange = {
          ...rangeValue,
          endValue: { moment: momentValue, string: stringValue },
        };
      } else if (momentValue) {
        newRange = {
          startValue: { moment: momentValue, string: stringValue },
        };
      } else {
        newRange = {};
      }
      setRangeValue(newRange);
    } else if (selectionMode === SELECTION_MODE.multiple) {
      let newVal = { ...multipleValue };
      const idx = newVal.stringValue.indexOf(stringValue);

      if (momentValue && -1 === idx && (!maxDateCount || newVal.momentValue.length < maxDateCount)) {
        newVal.momentValue.push(momentValue);
        newVal.stringValue.push(stringValue);
      } else if (momentValue && -1 < idx) {
        newVal.momentValue.splice(idx, 1);
        newVal.stringValue.splice(idx, 1);
      } else if (!momentValue) {
        newVal = { momentValue: [], stringValue: [] };
      }
      setMultipleValue(newVal);
    } else {
      setStateValue({ momentValue, stringValue });
      setVisible(false);
    }
  };

  const onHourChange = (val) => {
    if (mounted && val !== hourValue) {
      setHourValue(val);

      if (!timeOnly && !stateValue?.stringValue) {
        const now = moment();
        setStateValue({ momentValue: now, stringValue: now.format(DATE_FORMATTER) });
      }
    }
  };

  const getValue = () => {
    if (timeOnly) {
      return hourValue;
    }
    if (showTime) {
      return stateValue?.momentValue ?
        moment(`${stateValue?.stringValue}${hourValue ? ` ${hourValue}` : ''}`).format(dateFormat) :
        '';
    }
    if (monthSelector) {
      return monthValue?.momentValue?.format(dateFormat);
    }
    if (selectionMode === SELECTION_MODE.range) {
      return `${rangeValue?.startValue?.moment?.format(dateFormat) || ''}${
        rangeValue?.endValue?.moment?.format(dateFormat) ? ` - ${rangeValue.endValue?.moment?.format(dateFormat)}` : ''
      }`;
    }
    if (selectionMode === SELECTION_MODE.multiple) {
      const { length } = multipleValue.stringValue;
      return length ?
        multipleValue.momentValue.map((val, idx) =>
          (0 !== idx ? ` ${val.format(dateFormat)}` : val.format(dateFormat))) :
        null;
    }
    return stateValue?.momentValue?.format(dateFormat);
  };

  const getCalendarDate = () => {
    if (monthSelector) {
      return monthValue?.momentValue;
    }
    if (selectionMode === SELECTION_MODE.range) {
      return rangeValue || {};
    }
    if (selectionMode === SELECTION_MODE.multiple) {
      return multipleValue;
    }
    return stateValue?.momentValue;
  };

  const onChangeInput = (val) => {
    const momentValue = moment(val, DATE_FORMATTER);
    if (val && val.length === DATE_FORMATTER.length && momentValue.isValid()) {
      onDayClick(momentValue, val);
    } else if (!val) {
      onDayClick();
    }
  };

  const style = {
    minWidth: calendarWidth,
    maxWidth: calendarWidth + 100,
    marginTop: `${outsideRef && outsideRef.current && outsideRef.current.offsetHeight - 1}px`,
  };

  const date = getCalendarDate();

  return (
    <div
      className={`wiset-datepicker${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}${
        error ? ' error' : ''
      }${icon ? ' with-icon' : ''}`}
      ref={outsideRef}
    >
      <div ref={divRef} onClick={() => !disabled && setVisible(true)}>
        {mounted && !monthSelector && (
          <Calendar
            {...rest}
            style={style}
            className={`${visible ? 'visible' : ''}`}
            date={date}
            hour={hourValue}
            locale={currentLocale}
            onDayClick={onDayClick}
            onHourChange={onHourChange}
            selectionMode={selectionMode}
            showSeconds={showSeconds}
            showTime={showTime}
            timeOnly={timeOnly}
          />
        )}
        {mounted && monthSelector && selectionMode === SELECTION_MODE.single && (
          <MonthSelector
            {...rest}
            style={style}
            className={`${visible ? 'visible' : ''}`}
            date={date}
            locale={currentLocale}
            onMonthClick={onDayClick}
          />
        )}
        <div className="wiset-datepicker-input">
          <Input
            clearable={clearable}
            disabled={disabled}
            error={error}
            errorText={errorText}
            icon={icon}
            infoText={infoText}
            isFocused={visible}
            onChange={onChangeInput}
            onFocus={() => !disabled && setVisible(true)}
            placeholder={placeholder}
            readOnly={readOnlyInput}
            value={getValue()}
          />
        </div>
      </div>
    </div>
  );
};

Datepicker.defaultProps = {
  className: null,
  clearable: false,
  dateFormat: 'DD/MM/YYYY',
  disabled: false,
  disabledDates: [], // Array with disabled dates.
  disabledDays: [], // Array with disabled weekday numbers.
  error: false,
  errorText: null,
  icon: 'fas fa-calendar-alt',
  infoText: null,
  locale: initialLocale,
  maxDate: null,
  maxDateCount: null,
  minDate: null,
  monthNavigator: false,
  monthSelector: false,
  onChange: () => true,
  placeholder: null,
  readOnlyInput: true,
  selectOtherMonths: true,
  selectOtherYears: true,
  selectionMode: SELECTION_MODE.single,
  showSeconds: false,
  showTime: false,
  stepMinute: 30,
  stepSecond: 1,
  timeOnly: false,
  value: null,
  yearNavigator: false,
  yearRange: null, // F.E.: "2019:2029"
};

Datepicker.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.any,
  dateFormat: PropTypes.string,
  disabled: PropTypes.any,
  disabledDates: PropTypes.array,
  disabledDays: PropTypes.array,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  icon: PropTypes.string,
  infoText: PropTypes.string,
  locale: PropTypes.object,
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDateCount: PropTypes.number,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  monthNavigator: PropTypes.bool,
  monthSelector: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnlyInput: PropTypes.bool,
  selectOtherMonths: PropTypes.bool,
  selectOtherYears: PropTypes.bool,
  selectionMode: PropTypes.oneOf(Object.values(SELECTION_MODE)),
  showSeconds: PropTypes.bool,
  showTime: PropTypes.bool,
  stepMinute: PropTypes.number,
  stepSecond: PropTypes.number,
  timeOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  yearNavigator: PropTypes.bool,
  yearRange: PropTypes.string,
};

export default Datepicker;
