import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import reducers from './reducers/index';

const middlewares = [thunkMiddleware];

const devTools =
  'production' === process.env.NODE_ENV ?
    applyMiddleware(...middlewares) :
    composeWithDevTools(applyMiddleware(...middlewares));

const store = createStore(reducers, devTools);

export default store;
