/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

import Input from '../Input/Input';
import Button, { BUTTON_TYPES } from '../Button/Button';

import './index.scss';

const useEvent = (condition, eventListener, cb) => {
  const handleClick = (event) => {
    // 38 is up key and 40 up down
    if (condition && cb && 'function' === typeof cb) {
      cb(event);
    }
  };

  useEffect(() => {
    document.addEventListener(eventListener, handleClick);

    return () => {
      document.removeEventListener(eventListener, handleClick);
    };
  });
};

const Selector = ({
  arrowsNavigation,
  className,
  clearable,
  clearOnEscape,
  disabled,
  formatOption,
  keepValueSelected,
  labelKey,
  multiselect,
  onChange,
  options,
  searchable,
  value,
  setOnEnter,
  valueKey,
  emptyText,
  ...rest
}) => {
  const [inputMultiselect, setInputMultiselect] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [filtered, setFiltered] = useState(options || []);
  const [focusedOption, setFocusedOption] = useState(-1);
  const [mounted, setMounted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [visible, setVisible] = useState(false);
  const [optionsWidth, setOptionsWidth] = useState(null);

  const clear = () => {
    setVisible(false);
    setSelectedOption(null);
    setSelectedOptions([]);
    setInputValue('');
    setInputMultiselect('');
    setFocusedOption(-1);
    if (onChange && 'function' === typeof onChange) {
      onChange();
    }
  };

  const updateMultiselectValues = (values) => {
    const newSelected = {};

    const keys = [];
    let newInput = '';
    let key = null;
    let label = null;
    if (values && values.length) {
      values.forEach((val) => {
        key = (val && valueKey && val[valueKey]) || val;
        label = (val && labelKey && val[labelKey]) || val;
        newSelected[key] = val;
        newInput = newInput ? `${newInput}, ${label}` : label;
        keys.push(key);
      });
    }

    setSelectedOptions(newSelected);
    setInputMultiselect(newInput);

    if ('function' === typeof onChange) {
      onChange(Object.values(newSelected), Object.keys(newSelected));
    }
  };

  const onSelect = (val) => {
    if (multiselect) {
      const newSelected = { ...selectedOptions };
      const key = valueKey && val ? val[valueKey] : val;

      if (newSelected[key]) {
        delete newSelected[key];
      } else {
        newSelected[key] = val;
      }
      updateMultiselectValues(Object.values(newSelected));
    } else {
      setSelectedOption(val);
    }
  };

  const divRef = useCallback((node) => {
    if (node && node.getBoundingClientRect) {
      setOptionsWidth(node.getBoundingClientRect().width);
    }
  });

  const outsideRef = useRef();

  useEvent(visible, 'click', (event) => {
    if (
      (event.target.children &&
        !event.target.children.length &&
        -1 < event.target.parentElement.className.indexOf('btn')) ||
      (outsideRef.current && !outsideRef.current.contains(event.target))
    ) {
      setFocusedOption(-1);
      setVisible(false);
    }
  });

  useEvent(visible, 'keydown', (event) => {
    // 38 is up key and 40 up down
    if (!disabled && arrowsNavigation && ('ArrowUp' === event.key || 38 === event.keyCode) && 0 < focusedOption) {
      event.preventDefault();
      setFocusedOption(focusedOption - 1);
      setVisible(true);
    } else if (
      !disabled &&
      arrowsNavigation &&
      ('ArrowDown' === event.key || 40 === event.keyCode) &&
      filtered &&
      filtered.length &&
      focusedOption < filtered.length - 1
    ) {
      event.preventDefault();
      setFocusedOption(focusedOption + 1);
      setVisible(true);
    } else if (setOnEnter && 'Enter' === event.key && -1 < focusedOption) {
      onSelect(filtered[focusedOption]);
    } else if (clearable && clearOnEscape && 'Escape' === event.key) {
      clear();
    }
  });

  useEffect(() => {
    // On change input value or options
    let filteredOptions = options ? [...options] : [];

    if (mounted && !multiselect && selectedOption && selectedOption !== value) {
      setSelectedOption(null);

      if (onChange && 'function' === typeof onChange) {
        onChange(null);
      }
    }
    if (inputValue) {
      let label = null;
      filteredOptions = options.filter((option) => {
        label = labelKey && option[labelKey] ? option[labelKey] : option;
        return (
          label &&
          label.toString().toLowerCase &&
          -1 <
            label
            .toString()
            .toLowerCase()
            .indexOf(inputValue.toString().toLowerCase())
        );
      });
    }

    setFiltered(filteredOptions);
  }, [inputValue, options]);

  useEffect(() => {
    // On change selected option
    if (mounted && !multiselect && selectedOption !== value) {
      setVisible(false);
      if (onChange && 'function' === typeof onChange && selectedOption !== value && (selectedOption || value)) {
        onChange(selectedOption, valueKey && selectedOption[valueKey]);
      }

      if (!keepValueSelected) {
        clear();
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    // On change multiselect
    if (mounted && multiselect && !keepValueSelected) {
      clear();
    }
  }, [selectedOptions]);

  useEffect(() => {
    // On change value received
    if (mounted && !multiselect && value !== selectedOption) {
      let newVal = value;
      if (valueKey && (!value || !value[valueKey])) {
        newVal = options.find((opt) => opt && opt[valueKey] === value);
      }
      setSelectedOption(newVal || null);
    }
    if (mounted && !value && selectedOptions) {
      setSelectedOptions({});
      setInputMultiselect('');
    }
  }, [value]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  const selValue = (multiselect && inputMultiselect) ||
    (value && labelKey && inputValue === value[labelKey] && inputValue) ||
    (!labelKey && value) ||
    (!labelKey && selectedOption) ||
    (selectedOption && labelKey && selectedOption[labelKey]) ||
    inputValue;
  const hasResults = filtered && 0 < filtered.length;
  const customFormat = formatOption && 'function' === typeof formatOption;

  return (
    <div
      className={`wiset-selector${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`}
      ref={divRef}
      onClick={() => !disabled && setVisible(true)}
    >
      <div ref={outsideRef}>
        <div className="wiset-selector-input">
          <Input
            {...rest}
            className={clearable ? 'with-clear' : ''}
            disabled={!searchable || disabled || multiselect}
            isFocused={visible}
            onChange={(val) => setInputValue(val)}
            onFocus={() => !disabled && setVisible(true)}
            value={selValue}
          />
          <div className="wiset-selector-btns">
            {clearable && (
              <Button
                className={`clear-btn${selValue ? ' visible' : ''}`}
                iconLeft="fas fa-times"
                onClick={clear}
                type={BUTTON_TYPES.transparent}
              />
            )}
            {!disabled && (
              <Button
                className={`wiset-selector-arrow${visible ? ' invert' : ''}`}
                iconLeft="fas fa-caret-down"
                onClick={() => setVisible(!visible)}
                type={BUTTON_TYPES.transparent}
              />
            )}
          </div>
        </div>
        <div
          className={`wiset-selector-options fadein${visible ? ' visible shadow' : ''}`}
          style={{ width: optionsWidth }}
        >
          {hasResults &&
            filtered.map((option, idx) => (
              <Button
                key={(valueKey && option[valueKey]) || idx}
                className={`wiset-selector-option${idx === focusedOption ? ' focused' : ''}${
                  multiselect && selectedOptions[valueKey ? option[valueKey] : option] ?
                    ' primary-lighter-bg' :
                    ' white-bg'
                }`}
                inverted
                text={customFormat ? null : (labelKey && option[labelKey]) || option.toString()}
                onMouseOver={() => setFocusedOption(parseFloat(idx))}
                onClick={() => onSelect(option)}
                type={BUTTON_TYPES.transparent}
              >
                {customFormat && formatOption(option, idx)}
              </Button>
            ))}
          {!hasResults && <div className="empty-options-text">{emptyText}</div>}
        </div>
      </div>
    </div>
  );
};

Selector.defaultProps = {
  arrowsNavigation: true,
  className: null,
  clearable: false,
  clearOnEscape: true,
  disabled: false,
  formatOption: null,
  keepValueSelected: true,
  labelKey: 'label',
  multiselect: false,
  onChange: () => true,
  options: [],
  searchable: true,
  value: null,
  setOnEnter: true,
  valueKey: 'value',
  emptyText: 'No results found.',
};

Selector.propTypes = {
  arrowsNavigation: PropTypes.bool,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  clearOnEscape: PropTypes.bool,
  disabled: PropTypes.bool,
  formatOption: PropTypes.func,
  keepValueSelected: PropTypes.bool,
  labelKey: PropTypes.string,
  multiselect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  searchable: PropTypes.bool,
  value: PropTypes.any,
  setOnEnter: PropTypes.bool,
  valueKey: PropTypes.string,
  emptyText: PropTypes.string,
};

export default Selector;
