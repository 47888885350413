import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Video = ({
  notSupportedText, poster, type, url,
}) => (
  <video className="video-component" controls poster={poster} preload="metadata">
    <source src={url} type={`video/${type}`} />
    {notSupportedText}
    <track src="" kind="captions" label="english_captions" />
  </video>
);

Video.defaultProps = {
  notSupportedText: 'Video not supported.',
  poster: null,
  type: 'mp4',
};
Video.propTypes = {
  notSupportedText: PropTypes.string,
  poster: PropTypes.string,
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Video;
