import { ROLES } from './constants';

const { ADMIN, SUPERADMIN, USER } = ROLES;

export const userIsSuperadmin = (userRole) => userRole === SUPERADMIN;
export const userIsAdmin = (userRole) => userRole === ADMIN;
export const userIsAdminOrMore = (userRole) => userIsAdmin(userRole) || userIsSuperadmin(userRole);

// User
export const userIsUser = (userRole) => userRole === USER;
export const userIsUserOrMore = (userRole) => userIsUser(userRole) || userIsAdminOrMore(userRole);
