import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, icon }) => <i className={`${icon}${className ? ` ${className}` : ''}`} aria-hidden="true" />;

Icon.defaultProps = {
  className: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default Icon;
