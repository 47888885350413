import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './index.scss';

const InfoBox = ({
  children, icon, showIcon, text,
}) => (
  <div className="info-container fadein">
    <div className="info">
      {showIcon && <Icon icon={icon} />}
      <div>
        <span>{text}</span>
        {children}
      </div>
    </div>
  </div>
);

InfoBox.defaultProps = {
  children: null,
  icon: 'fas fa-info',
  showIcon: true,
  text: '',
};

InfoBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
};

export default InfoBox;
