import { COMMON } from '../../utils/dispatchTypes';

const initialState = {
  error: null,
  loading: true,
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case COMMON.LOADING:
      return { ...state, loading: true };
    case COMMON.LOADING_END:
      return { ...state, loading: false };
    case COMMON.SET_ERROR:
      return { ...state, error: action.error };
    case COMMON.LOGOUT:
      return { ...initialState, loading: false };
    default:
      return { ...state };
  }
};

export default general;
