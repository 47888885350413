import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import App from './App';

import './styles/font.scss';
import './styles/animations.scss';
import './styles/index.scss';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_POOL,
    userPoolWebClientId: process.env.REACT_APP_WEB_POOL,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET,
      region: process.env.REACT_APP_REGION,
    }
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AUTH
});

// Disable reactDevTools in production
if ('production' === process.env.NODE_ENV) {
  // Ensure the React Developer Tools global hook exists
  if ('object' === typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    let prop = null;
    let propType = null;
    const keys = Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__);

    // Replace all global hook properties with a no-op function or a null value
    for (let i = 0; i < keys.length; i = i + 1) {
      prop = keys[i];
      if (prop) {
        propType = typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop];

        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = 'function' === propType ? () => {} : null;
      }
    }
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
