import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../Button/Button';
import './index.scss';

const AmountSelector = ({
  buttonType, className, disabled, max, min, onChange, value,
}) => {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    if (Number.isInteger(value)) {
      setQuantity(value);
    }
  }, [value]);

  return (
    <div className={`wiset-amount-selector${className ? ` ${className}` : ''}`}>
      <Button
        iconLeft="fas fa-minus"
        disabled={disabled || (Number.isInteger(min) && min >= quantity)}
        onClick={() => {
          const newAmount = quantity - 1;
          setQuantity(newAmount);
          if (onChange) {
            onChange(newAmount);
          }
        }}
        type={buttonType}
      />
      <p className={disabled ? 'gray-border' : `${buttonType}-border`}>{quantity}</p>
      <Button
        iconLeft="fas fa-plus"
        disabled={disabled || (Number.isInteger(max) && max <= quantity)}
        onClick={() => {
          const newAmount = quantity + 1;
          setQuantity(newAmount);
          if (onChange) {
            onChange(newAmount);
          }
        }}
        type={buttonType}
      />
    </div>
  );
};

AmountSelector.defaultProps = {
  buttonType: BUTTON_TYPES.primary,
  className: null,
  disabled: false,
  max: null,
  min: null,
  onChange: () => true,
  value: 0,
};

AmountSelector.propTypes = {
  buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES).map((type) => type)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default AmountSelector;
